import axios from 'axios';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {baseURL} from "../base";

axios.defaults.baseURL = 'http://api.rentbox.com.ua';

export type SecurityCodeType = {
  login: string;
  password?: string
};

export type InputCodeType = {
  code: number | string;
  phone: number | string;
};

type HeadersValidateSecurityCodeAActionPayloadType = {
    authorization: string;
    expires: string | number;
    pragma: string;
};

type ResponseValidateSecurityCodeActionPayloadType = {
    data: string | number;
    status: number;
    statusText: string;
    headers: HeadersValidateSecurityCodeAActionPayloadType;
    config: any;
    request?: any;
};

const token = {
    set(authToken: string) {
        axios.defaults.headers.common.Authorization = `Bearer ${authToken}`;
    },
    unset() {
        axios.defaults.headers.common.Authorization = '';
    },
};

export type NewUserSecurityCodeType = {
    name: string;
    phone: string;
    email?: string;
    password?: string;
    privacyPolicyAccepted: boolean;
};

const register = createAsyncThunk(
    'auth/register',
    async (credentials: NewUserSecurityCodeType) => {
        const response = await axios.post(
            `${baseURL}/authentication/registration`,
            credentials,
        );
        return response;
    },
);

const logInSecurityCode = createAsyncThunk(
    'auth/verification',
    async (inputs: InputCodeType) => {

        const response = (await axios.put(
            `${baseURL}/security-user/phone/verification`,
            inputs,
        )) as ResponseValidateSecurityCodeActionPayloadType;
        token.set(response.headers.authorization);

        return response.headers.authorization;
    },
);

const forgotSecurityCode = createAsyncThunk(
    'forgot/code',
    async (phone: string) => {
        const {data} = await axios.post(`${baseURL}/security-user/phone/code?phone=${encodeURIComponent(phone)}`);
        return data;
    }
)

const forgotSecurityCodeEmail = createAsyncThunk(
    'forgot/code',
    async (email: string) => {
        const {data} = await axios.post(`${baseURL}/security-user/email/code?email=${encodeURIComponent(email)}`);
        return data;
    }
)

const updatePassword = createAsyncThunk(
    'auth/password',
    async (credentials: UpdatePasswordType) => {
        const {data} = await axios.put(`${baseURL}/security-user/password`, credentials);
        return data;
    }
)

const logIn = createAsyncThunk(
    'auth/login',
    async (credentials: SecurityCodeType) => {

        const response = await axios.post(
            `${baseURL}/authentication/login`,
            credentials,
        );

        return response as ResponseLoginSuccessActionPayloadType;
    },
);

const logInEmail = createAsyncThunk(
    'auth/logInEmail',
    async (credentials: EmailTypeLogin) => {
        try {
            const response = await axios.post(
                `${baseURL}/authentication/email/login`,
                credentials,
            );
            return response as ResponseLoginSuccessActionPayloadType;
        } catch (error) {
            console.log('error', error);
        }
    },
);

export type EmailType = {
    name: string;
    password: string;
    email: string
};

export type EmailTypeLogin = {
    password: string;
    login: string
};

const registerEmail = createAsyncThunk(
    'auth/registerEmail',
    async (credentials: EmailType) => {
        try {
            const {data} = await axios.post(
                `${baseURL}/authentication/email/registration`,
                credentials,
            );
            return data;
        } catch (error) {
            console.log('error', error);
        }
    },
);

export type HeadersResponseLoginSuccessActionPayloadType = {
    authorization: string | number,
    expires: string | number
    pragma: string
};

export type ResponseLoginSuccessActionPayloadType = {
    data: string | number
    status: number
    statusText: string
    headers: HeadersResponseLoginSuccessActionPayloadType
    config: any
    request?: any
};

export type EmailTypeVerification = {
    code: string;
    email: string
};

export type UpdatePasswordType = {
    email: string;
    password: string;
    securityCode: string;
}

const validateSecurityCodeEmail = createAsyncThunk(
    'auth/verificationEmail',
    async (inputs: EmailTypeVerification) => {

        const response = (await axios.put(
            `${baseURL}/authentication/email/verification`,
            inputs,
        )) as ResponseValidateSecurityCodeActionPayloadType;
        token.set(response.headers.authorization);

        return response.headers.authorization;
    },
);

const operations = {
    logIn,
    register,
    logInSecurityCode,
    registerEmail,
    logInEmail,
    validateSecurityCodeEmail,
    updatePassword,
    forgotSecurityCode,
    forgotSecurityCodeEmail
};
export default operations;