import {useEffect, useState} from 'react';
import WindowSize from '../enums/WindowSize';

const useWindowSize = (): {windowSize: {width: number; height: number}; isScreenResolutionMobile: boolean} => {

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const isScreenResolutionMobile = windowSize.width < WindowSize.MD;

    return {windowSize, isScreenResolutionMobile};
};

export default useWindowSize;