import React from 'react';
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

import picture from "../../assets/icons/notFound/picture.svg";

import smthWentWrongStyles from './SmthWentWrong.module.scss';

const SmthWentWrong = () => {

    const history = useHistory();

    const {t} = useTranslation();

    const toMain = () => {
        history.push('/');
    }

    return (
        <div className={smthWentWrongStyles.main}>
            <div className={smthWentWrongStyles.photo_block}>
                <img src={picture} alt="picture" className={smthWentWrongStyles.picture}/>
            </div>
            <div className={smthWentWrongStyles.text_block}>
                <h1 className={smthWentWrongStyles.text}>
                    {t('wrong.description')}
                </h1>
                <button className={smthWentWrongStyles.redirect} onClick={toMain}>
                    {t('wrong.btn')}
                </button>
            </div>
        </div>
    );
}

export default SmthWentWrong;