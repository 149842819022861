import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import Modal from '../../../common/Modal';
import EmailPhoneChangerModal from '../EmailPhoneChangerModal/EmailPhoneChangerModal';
import CodeForChanger from '../CodeForChanger/CodeForChanger';
import userSlice from '../../../features/user/userSlice';
import {ResponseRefreshUserSuccessActionPayloadType} from "../../../models/generalTypes";
import userSelectors from '../../../features/user/userSelectors';

import close from '../../../assets/icons/close.svg';

import emailPhoneChangerStyles from './EmailPhoneChanger.module.scss';
import modalStyles from '../../../styles/modalStyles.module.scss';

type ChangerProps = {
    label: string;
    isConfirmed?: boolean;
};
const EmailPhoneChanger: React.FC<ChangerProps> = ({label, isConfirmed = false}) => {

    const dispatch = useDispatch();

    const ref = React.useRef<HTMLDivElement>(null);

    const [isModalOpen, toggleModal] = useState(false);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);

        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isMenuOpen]);

    const enterCodeModal = useSelector(userSelectors.enterCodeModal);

    const {t} = useTranslation();

    const user = useSelector(
        userSelectors.getUserProfile,
    ) as ResponseRefreshUserSuccessActionPayloadType;

    return (
        <div className={emailPhoneChangerStyles.edit}>
            <div
                role="button"
                tabIndex={1}
                onKeyDown={() => toggleModal(!isModalOpen)}
                onClick={() => {
                    toggleModal(!isModalOpen);
                }}>
                <div className={emailPhoneChangerStyles.flex_edit}>
                    <p className={emailPhoneChangerStyles.edit_field}>{t('userProfile.edit')}</p>
                    {isConfirmed &&
                        <p className={emailPhoneChangerStyles.edit_field}>{t('userProfile.confirm')}</p>}
                </div>
            </div>

            <Modal
                width="auto"
                height="auto"
                isOpen={isModalOpen}
                toggle={toggleModal}
            >
                <div
                    role="button"
                    onKeyDown={() => toggleModal(!isModalOpen)}
                    onClick={() => {
                        dispatch(userSlice.actions.toggleCodeModal(false));
                        toggleModal(!isModalOpen);
                    }}
                    className={modalStyles.close}
                >
                    <p>
                        <img src={close} alt="close"/>
                    </p>
                </div>

                <h4 className={modalStyles.header}>{isConfirmed ? t('userProfile.confirm') : t('userProfile.edit')}</h4>

                <div className={modalStyles.line}></div>

                {enterCodeModal ? (
                    <CodeForChanger
                        label={label}
                        toggleModal={toggleModal}
                        isModalOpen={true}
                    />
                ) : (
                    <EmailPhoneChangerModal
                        label={label}
                        toggleModal={toggleModal}
                        isModalOpen={isModalOpen}
                    />
                )}
            </Modal>
        </div>
    );
};

export default EmailPhoneChanger;
