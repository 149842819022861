import React from 'react';
import Modal from '../../common/Modal';
import useWindowSize from '../../hooks/useWindowSize';
import WindowSize from '../../enums/WindowSize';

import closeButton from '../Acceptances/images/closeButton.svg';

import privacyPolicyPageStyles from '../../pages/PrivacyPolicy/PrivacyPolicyPage.module.scss';
import privacyPolicyModalStyles from './PrivacyPolicyModal.module.scss';
import modalStyles from '../../styles/modalStyles.module.scss';

interface Props {
    isModalOpen: boolean;
    toggleModal: (arg0: boolean) => any | void;
}

const PrivacyPolicyModal: React.FC<Props> = ({isModalOpen, toggleModal}) => {

    const {isScreenResolutionMobile} = useWindowSize();

    return (
        <Modal
            width={isScreenResolutionMobile ? '100%' : '700px'}
            height={isScreenResolutionMobile ? '100%' : '650px'}
            isOpen={isModalOpen}
            toggle={toggleModal}
        >
            <h6 className={modalStyles.header}>Умови користування сервісами RENTBOX</h6>
            <div
                role="button"
                tabIndex={1}
                onKeyDown={() => toggleModal(!isModalOpen)}
                onClick={() => {
                    toggleModal(!isModalOpen);
                }}
                className={modalStyles.close}
            >
                <p>
                    <img src={closeButton} alt="close" />
                </p>
            </div>
            <div className={modalStyles.line}></div>
            <div className={privacyPolicyModalStyles.main}>
                <div className="mb-2">
                    Просимо вас уважно ознайомитись з цими Умовами користування сервісами RENTBOX (у тому числі Сайтом
                    RENTBOX), оскільки вони містять важливу інформацію про ваші права та обов’язки.
                </div>
                <ol>
                    <li> ЗАГАЛЬНІ ПОЛОЖЕННЯ
                        <ol>
                            <li>
                                ТОВ «RENTBOX» (далі – Компанія та/або RENTBOX) надає Вам послуги (надалі за текстом -
                                Послуги) з некомерційного використання Сервісів RENTBOX, а саме Сайту rentbox.com.ua
                                (надалі
                                за текстом - Сервіси RENTBOX) у відповідності з цими Умовами користування Сервісами
                                RENTBOX
                                (надалі – Умови). Комерційне використання Сервісів RENTBOX регулюється іншими
                                договорами,
                                укладеними безпосередньо із замовниками таких послуг.
                            </li>
                            <li>
                                Використання Вами Сервісів RENTBOX, рівною мірою як і використання опублікованої у них
                                інформації, означає Вашу згоду з цими Умовами. Ви не маєте права користуватись Сервісами
                                RENTBOX, якщо ви не прийняли ці Умови.
                            </li>
                            <li>
                                Ви не маєте права користуватись Сервісами RENTBOX, якщо це заборонено законодавством
                                країни,
                                резидентом якої ви є, або на території якої ви користуєтесь Сервісами RENTBOX.
                            </li>
                            <li>
                                Компанія може періодично змінювати дані Умови без попереднього попередження. Актуальна
                                версія Умов завжди доступна за адресою <a href="https://rentbox.com.ua/uk/terms"
                                                                          className={privacyPolicyPageStyles.href}>https://rentbox.com.ua/uk/terms</a>.
                                Якщо ви продовжуєте
                                користуватись Послугами після зміни Умов, це трактується як Ваша згода з новою редакцією
                                Умов.
                            </li>
                        </ol>
                    </li>
                    <li> ІНФОРМАЦІЯ, РОЗМІЩЕНА У СЕРВІСАХ RENTBOX
                        <ol>
                            <li>
                                Матеріали, які містяться у Сервісах RENTBOX, призначені для особистого некомерційного
                                використання. Всі матеріали, які містяться у Сервісах RENTBOX, включаючи, але не
                                обмежуючись: новини, фотографії, зображення, ілюстрації, інші матеріали (надалі -
                                «матеріали» та/або «контент») захищені авторським правом і перебувають у володінні або
                                під
                                контролем Компанії або третьої особи, яка є постачальником (надавачем) контенту.
                                Користуючись Сервісами RENTBOX, Ви зобов’язуєтесь дотримуватись всіх додаткових
                                повідомлень
                                про авторські права, інформацію або обмеження, які можуть бути розміщені у Сервісах
                                RENTBOX.
                            </li>
                            <li>
                                Зміст Сервісів RENTBOX захищений авторським правом відповідно до законодавства України.
                                Ви
                                не маєте права змінювати, публікувати, передавати, брати участь в передачі або продажу,
                                відтворювати (за винятком випадків, передбачених у пункті 2.4 цих Умов), створювати нові
                                твори, поширювати, виконувати, або будь-яким іншим чином використовувати зміст Сервісів
                                RENTBOX (включаючи програмне забезпечення) повністю або частково.
                            </li>
                            <li>
                                Комерційні (фірмові) найменування, торговельні марки тощо Компанії або третіх осіб
                                захищені
                                авторськими правами. Будь-яке положення, зазначене в цих Умовах, не може розглядатися як
                                надання опосередковано, за замовчуванням або іншим шляхом будь-якої ліцензії або права
                                на
                                використання торговельної марки, патенту, прав на дизайн або авторських прав на твори
                                Компанії або третіх осіб.
                            </li>
                            <li>
                                Ви можете скопіювати вміст Сервісів RENTBOX, інші компоненти і елементи, які
                                відображаються
                                у Сервісах RENTBOX, тільки для особистого використання, за умови дотримання всіх
                                авторських
                                прав та інших повідомлень, що містяться в ньому. Копіювання або зберігання будь-якого
                                контенту для цілей, відмінних від особистого використання, суворо забороняється без
                                попередньої письмової згоди на це Компанії.
                            </li>
                            <li>
                                Під використанням інформації мається на увазі будь-яке відтворення, публікування,
                                розповсюдження, переробка, переклад, включення її частин в інші твори та інші способи,
                                передбачені Законом України «Про авторське право і суміжні права».
                            </li>
                            <li>
                                Компанія надає письмовий дозвіл на використання своїх матеріалів третім особам на свій
                                розсуд на підставі звернення, направленого в електронній формі на e-mail <a
                                href="mailto:rentbox.ua@gmail.com"
                                className={privacyPolicyPageStyles.href}>rentbox.ua@gmail.com</a>. Компанія
                                залишає за собою право в процесі розгляду звернення вимагати
                                від третіх осіб надання будь-якої додаткової інформації, яка необхідна для прийняття
                                рішення
                                про видачу такого дозволу. Використання матеріалів Компанії можливо тільки після
                                отримання
                                відповідного письмового дозволу.
                            </li>
                        </ol>
                    </li>
                    <li> РЕЄСТРАЦІЯ ТА ЗВ’ЯЗОК ІЗ КОРИСТУВАЧЕМ СЕРВІСІВ RENTBOX
                        <ol>
                            <li>
                                Якщо Ви заповнюєте реєстраційну форму або будь-яку іншу форму у Сервісах RENTBOX з метою
                                отримання або надання певної інформації, ми отримуємо ваші дані, які були вами вказані у
                                відповідній формі.
                            </li>
                            <li>
                                Ви надаєте, а Компанія залишає за собою право відправляти на Ваш мобільний номер, та/або
                                у
                                Telegram-акаунт, та/або у Viber-акаунт, та/або на контактний e-mail інформацію стосовно
                                змін
                                або доповнень інформації у Сервісах RENTBOX інформаційного характеру, маркетингового (у
                                тому
                                числі рекламного) характеру, сервісні повідомлення та уточнення щодо залишених відгуків
                                (зворотнього звʼязку). Залишаючи свої контактні (персональні) дані, Ви погоджуєтесь на
                                отримання вищезазначених повідомлень.
                            </li>
                            <li>
                                Здійснюючи дзвінок за номерами, вказаними у Сервісах RENTBOX, Ви надаєте згоду на запис
                                вашої телефонної розмови з метою забезпечення рівня сервісу, підвищення якості
                                обслуговування, швидкості надання інформації та аналізу запиту. Запис телефонної розмови
                                може бути використаний, як Компанією, так і бути переданий забудовнику (представнику)
                                відповідного Житлового комплексу, зі сторінки якого Ви здійснюєте дзвінок.
                            </li>
                            <li>
                                Компанія може зв’язатися з Вами за допомогою електронної адреси/мобільного номеру/у
                                Viber/у
                                Telegram щодо Вашої участі в опитуванні користувачів, і ставити запитання з метою
                                поліпшення
                                поточних або перспективних матеріалів Сервісів RENTBOX. Ця інформація буде використана
                                для
                                поліпшення роботи Сервісів RENTBOX і для кращого розуміння потреб та поведінки
                                користувачів.
                                Будь-яка інформація, яку ми отримуємо в таких опитуваннях, не буде передана третім
                                особам,
                                за винятком узагальнених знеособлених даних.
                            </li>
                            <li>
                                Ви можете надіслати запит на видалення усіх та/або частини ваших персональних даних (у
                                тому
                                числі електронної пошти, мобільного номеру) у будь-який момент, звернувшись за
                                електронною
                                адресою: <a href="mailto:rentbox.ua@gmail.com"
                                            className={privacyPolicyPageStyles.href}>rentbox.ua@gmail.com</a>. Ваша
                                заявка буде опрацьована протягом 30 (тридцяти) робочих
                                днів з моменту її отримання, а ваші дані будуть видалені. Однак ми можемо зберігати
                                деякі з
                                ваших персональних даних стільки часу, скільки це розумно необхідно для наших законних
                                бізнес-інтересів або інших цілей, що відповідають вимогам законодавства, включаючи
                                виявлення
                                та запобігання шахрайству, а також для виконання наших юридичних зобов’язань, включаючи
                                податкову та юридичну звітність, а також проходження аудиторських перевірок.
                            </li>
                        </ol>
                    </li>
                    <li> ПРИПИНЕННЯ ДОСТУПУ ДО СЕРВІСІВ RENTBOX
                        <ol>
                            Компанія може, на свій розсуд, видалити або призупинити доступ до всіх та/або частини
                            Сервісів
                            RENTBOX або їх частин з будь-якої причини, включаючи, порушення або невиконання цих Умов.
                        </ol>
                    </li>
                    <li> ГАРАНТІЇ ТА ЗВІЛЬНЕННЯ ВІД ГАРАНТІЙ
                        <ol>
                            Користуючись Сервісами RENTBOX, Ви розумієте та приймаєте, що:
                            <li>
                                Інформація у Сервісах RENTBOX надається "як є" без гарантії достовірності, ви
                                використовуєте
                                Сервіси RENTBOX на свій страх і ризик. Компанія не несе жодної відповідальності за
                                наслідки
                                використання Вами інформації, що розміщена у Сервісах RENTBOX.
                            </li>
                            <li>
                                Компанія не гарантує:
                                <ul>
                                    <li>
                                        що інформація у Сервісах RENTBOX відповідатиме меті Вашого відвідування;
                                    </li>
                                    <li>
                                        безперебійну роботу Сервісів RENTBOX;
                                    </li>
                                    <li>
                                        роботу Сервісів RENTBOX без помилок.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                За будь-який вміст Сервісів RENTBOX (текстовий, графічний, звуковий чи відео), що
                                розміщений
                                для загального користування, несе відповідальність особа, що створила даний вміст.
                            </li>
                            <li>
                                Сервіси RENTBOX можуть містити посилання на інші інтернет-сайти, що належать іншим
                                фізичним
                                та/або юридичним особам. Ви визнаєте та погоджуєтесь із тим, що Компанія не несе жодної
                                відповідальності за доступність та вміст інтернет-сайтів третіх осіб, а також будь-які
                                наслідки, пов’язані з використанням цього вмісту.
                            </li>
                            <li>
                                Компанія не несе відповідальності за точність інформації, що надається у Сервісах
                                RENTBOX,
                                та залишає за собою право змінювати без сповіщення будь-які матеріали, що розміщені у
                                Сервісах RENTBOX.
                            </li>
                            <li>
                                Компанія періодично вносить зміни в характер надання послуг. Ви погоджуєтесь із тим, що
                                послуги можуть змінюватись без сповіщення Вас про майбутні зміни.
                            </li>
                            <li>
                                Компанія може в будь-який момент тимчасово або остаточно припинити надання послуг без
                                попереднього сповіщення.
                            </li>
                            <li>
                                Ви зобов'язуєтесь не чинити жодних дій, що спрямовані на:
                                <ul>
                                    <li>
                                        створення перешкод для роботи обладнання Компанії;
                                    </li>
                                    <li>
                                        створення перешкод у користуванні послуг іншими користувачами Сервісами RENTBOX;
                                    </li>
                                    <li>
                                        нанесення шкоди обладнанню Компанії, а також даним, що зберігаються на
                                        обладнанні
                                        Компанії.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Ви зобов’язуєтесь не вказувати персональну інформацію у жодних реєстраційних формах у
                                Сервісах RENTBOX, що не відповідає дійсності (у тому числі мобільні номери, на
                                використання
                                яких у Вас немає відповідних прав).
                            </li>
                        </ol>
                    </li>
                    <li> КОНФІДЕНЦІЙНІСТЬ
                        <ol>
                            <li>
                                Політика конфіденційності Компанії завжди доступна за наступною адресою: <a
                                href="https://rentbox.com.ua/uk/terms#privacy"
                                className={privacyPolicyPageStyles.href}>https://rentbox.com.ua/uk/terms#privacy</a>.
                                Політика конфіденційності Компанії описує, на яких
                                умовах Сервіси RENTBOX зберігають та використовують отриману від Вас інформацію.
                            </li>
                            <li>
                                Погоджуючись із цими Умовами, Ви також погоджуєтесь і з політикою конфіденційності
                                Компанії.
                            </li>
                        </ol>
                    </li>
                    <li> ПРИКІНЦЕВІ ПОЛОЖЕННЯ
                        <ol>
                            <li>
                                Ніщо в цим Умовах не може розцінюватись як встановлення між Компанією та Вами агентських
                                відносин, відносин спільної діяльності, відносин особистого найму, або будь-яких інших
                                відносин, що прямо не передбачені в цих Умовах.
                            </li>
                            <li>
                                Ви погоджуєтесь, що Компанія не несе відповідальності перед Вами та/або жодними іншими
                                третіми особами за порушення Ваших зобов’язань відповідно до цих Умов. Ви несете
                                виключну
                                відповідальність за будь-які наслідки таких порушень. Якщо у Вас виникли питання
                                стосовно
                                цих Умов, Ви можете зв’язатися з нами за адресою: <a href="mailto:rentbox.ua@gmail.com"
                                                                                     className={privacyPolicyPageStyles.href}>rentbox.ua@gmail.com</a>.
                            </li>
                        </ol>
                    </li>
                </ol>

                <h3>ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ</h3>
                <div className="mb-2">
                    Сервіси RENTBOX є каталогом-довідником об’єктів нерухомості на території України, що розміщується у
                    мережі Інтернет, інформація до якого потрапляє від користувачів або з відкритих джерел (надалі
                    «Сервіси
                    RENTBOX» та/або «RENTBOX»).
                </div>
                <div className="mb-2">
                    RENTBOX відповідально та уважно ставиться до конфіденційності інформації, яка отримана від
                    користувачів
                    Сервісів RENTBOX.
                </div>
                <ol>
                    <li> ЯКІ ДАНІ ЗБИРАЄ RENTBOX?
                        <ol> У процесі користування Сервісами RENTBOX, RENTBOX збирає про вас наступну інформацію:
                            <li>
                                Інформація, що надана Вами під час реєстрації та за допомогою відповідних форм у
                                Сервісах
                                RENTBOX. Як правило, це персональна інформація, така як ваші Ім’я та мобільний номер
                                телефону тощо. Сервіси RENTBOX зберігають таку інформацію лише після підтвердження Вами
                                вводу шляхом натискання відповідної кнопки.
                                Така інформація використовується виключно для реалізації сервісів RENTBOX (наприклад,
                                організації вашого запису до відділу продажу відповідного житлового комплексу або
                                розсилки
                                сповіщень про новини житлових комплексів на Ваш мобільний пристрій) і лише з Вашої
                                згоди.
                                RENTBOX завжди слідкує за тим, щоб Ви мали доступну можливість відмовитись від цих
                                сервісів,
                                а також редагувати або видаляти будь-яку персональну інформацію, що була надана в
                                процесі
                                використання цих сервісів.
                            </li>
                            <li>
                                Інформація, яку ми збираємо у Додатку, у тому числі: інформація про пристрій (ID
                                пристрою),
                                діагностичні дані та звіти про збої, токен для доступу до API, інформація про регіон та
                                місто, вибрана валюта, пошукова історія у Додатку тощо.
                                Інформація, яку ми збираємо під час користування вами Додатком, використовується
                                виключно
                                для реалізації сервісів RENTBOX, технічного забезпечення функціонування Додатку та
                                статистичних досліджень, що дозволяє покращувати та підтримувати служби RENTBOX. RENTBOX
                                використовує надану Вами інформацію лише для досягнення цілей, що зазначені вище.
                                Використання цієї інформації з будь-якою іншою метою можливо лише з Вашої згоди.
                            </li>
                            <li>
                                Файли "cookie" - це файли, що містять невелику кількість даних, які Комп’ютер або
                                смартфон
                                передає до Вашого веб-браузера, і вони зберігаються на жорсткому диску Вашого комп’ютера
                                або
                                у памʼяті смартфону.
                                У більшості випадків ці файли використовуються RENTBOX для того, щоб ідентифікувати Вас
                                під
                                час наступних входів на RENTBOX, тим самим спрощуючи інтерфейс та навігацію сайтом як у
                                мобільній, так і у десктопній версії.
                                В інших випадках RENTBOX зберігає в cookie анонімну службову інформацію, що жодним чином
                                не
                                стосується Вашої особи.
                                Ви можете в будь-який момент заборонити використання cookie у Вашому браузері, проте Вам
                                слід врахувати, що це може призвести до некоректної роботи деяких Сервісів RENTBOX.
                            </li>
                            <li>
                                Журнали. Під час кожного звернення користувача до Сервісів RENTBOX, наші сервери
                                зберігають
                                інформацію про дане звернення до своїх файлів-журналів. Такі журнали можуть містити таку
                                інформацію як: IP-адреса Вашого комп’ютера, текст, а також час та дату запиту, тип
                                браузера
                                тощо. Інформація з даних журналів використовується виключно для статистичних досліджень,
                                що
                                дозволяють покращувати та підтримувати служби RENTBOX.
                            </li>
                            <li>
                                Ми ведемо записи будь-якої кореспонденції, запитів чи скарг, які ви надсилаєте нам за
                                допомогою Додатку/Сайту або контактної електронної пошти, а також наші відповіді.
                                Залежно
                                від того, як ви з нами зв’язуєтесь, ми можемо збирати вашу адресу електронної пошти та
                                будь-яку додаткову інформацію, яку ви нам надаєте. Наявність повного запису
                                кореспонденції
                                дозволяє RENTBOX надавати найкращі можливості підтримки користувачів.
                            </li>
                        </ol>
                    </li>
                    <li> ПОСИЛАННЯ
                        <ol>
                            У Сервісах RENTBOX можуть розміщуватись посилання на веб-сайти в мережі Інтернет, які можуть
                            збирати
                            власну інформацію, зберігати власні файли cookie тощо. Ми не контролюємо ці сайти, а також
                            політику
                            конфіденційності цих сайтів.
                        </ol>
                    </li>
                    <li> ЗБЕРІГАННЯ ТА ПЕРЕДАЧА ДАНИХ
                        <ol>
                            Надана нам інформація буде передана, оброблена та буде зберігатись в Україні та Німеччині.
                            Дані,
                            що
                            зберігаються та обробляються RENTBOX, іноді можуть переміщатися між цими зонами (безпечним
                            способом).
                        </ol>
                    </li>
                    <li> ВИДАЛЕННЯ ДАНИХ
                        <ol>
                            Ви можете надіслати запит на видалення ваших персональних даних (у тому числі електронної
                            пошти,
                            мобільного номеру, Telegram-акаунту) у будь-який момент, звернувшись за електронною
                            адресою: <a href="mailto:rentbox.ua@gmail.com"
                                        className={privacyPolicyPageStyles.href}>rentbox.ua@gmail.com</a>. Ваша заявка
                            буде опрацьована протягом 30 (тридцяти) робочих днів з моменту
                            її
                            отримання, а ваші дані будуть видалені. Однак ми можемо зберігати деякі з ваших персональних
                            даних
                            стільки часу, скільки це розумно необхідно для наших законних бізнес-інтересів або інших
                            цілей,
                            що
                            відповідають вимогам законодавства, включаючи виявлення та запобігання шахрайству, а також
                            для
                            виконання наших юридичних зобов’язань, включаючи податкову, юридичну звітність та
                            проходження
                            аудиторських перевірок.
                        </ol>
                    </li>
                    <li> ПРИКІНЦЕВІ ПОЛОЖЕННЯ
                        <ol>
                            <li>
                                Компанія вживає всіх відповідних до сучасних технічних стандартів заходів, спрямованих
                                на
                                захист персональних даних своїх користувачів. Всередині компанії доступ до персональних
                                даних користувачів надається лише тим співробітникам, для яких ця інформація є
                                необхідною
                                для покращення наших служб. RENTBOX гарантує, що всі такі співробітники обмежені
                                зобов’язаннями з дотримання конфіденційності, і будуть піддані стягненню у випадку
                                порушення
                                даних зобов’язань.
                            </li>
                            <li>
                                Беручи до уваги стрімкий розвиток Інтернету, а також нашого бажання постійно розширювати
                                спектр наших сервісів, зберігаючи при цьому високий рівень конфіденційності, в дану
                                Політику
                                можуть вноситись зміни. У такому випадку RENTBOX опублікує оновлену версію Політики
                                конфіденційності на цій же сторінці <a href="https://rentbox.com.ua/uk/terms#privacy"
                                                                       className={privacyPolicyPageStyles.href}>https://rentbox.com.ua/uk/terms#privacy</a>.
                            </li>
                            <li>
                                RENTBOX постійно слідкує за дотриманням правил, що описані у цій Політиці. Якщо у Вас
                                виникли питання стосовно цієї Політики або деталей її реалізації, Ви можете зв’язатися з
                                нами за допомогою електронної адреси: <a href="mailto:rentbox.ua@gmail.com"
                                                                         className={privacyPolicyPageStyles.href}>rentbox.ua@gmail.com</a>.
                            </li>
                        </ol>
                    </li>
                </ol>
            </div>
        </Modal>
    );
};

export default PrivacyPolicyModal;