import React, {useEffect, useState} from 'react';
import FilterSmartSearchForm from '../../components/FilterOffersPage/FilterSmartSearchForm/FilterSmartSearchForm';
import FilterApplied from '../../components/FilterOffersPage/FilterApplied/FilterApplied';
import SubscriptionsForm from '../../components/SubscriptionsForm/SubscriptionsForm';
import {useDispatch, useSelector} from 'react-redux';
import filterSlice from '../../features/filter/filterSlice';
import {Type} from '../SearchPage/SearchPage';

import subscriptionPageStyles from './SubscriptionPage.module.scss';
import s from '../SearchPage/SearchPage.module.scss';
import {useTranslation} from 'react-i18next';
import userSelectors from '../../features/user/userSelectors';
import {
    FilterType,
    ResponseRefreshUserSuccessActionPayloadType,
    SubscriptionsFilterType,
} from '../../models/generalTypes';
import filterSelectors from '../../features/filter/filterSelectors';
import {useHistory} from 'react-router-dom';
import qs from 'qs';
import subscriptionOperation from '../../features/subscription/subscriptionOperation';

const SubscriptionPage = () => {

    const dispatch = useDispatch();

    const {t} = useTranslation();

    const user = useSelector(
        userSelectors.getUserProfile,
    ) as ResponseRefreshUserSuccessActionPayloadType;

    const history = useHistory();

    const type = useSelector(filterSelectors.getType);

    const filter = useSelector(filterSelectors.getSubscriptionFilter) as SubscriptionsFilterType;

    const [filterFromQuery, setFilterFromQuery] = useState({} as FilterType);

    useEffect(() => {
        const parsed = qs.parse(history.location.search.substring(1), {
            delimiter: '&',
        });

        const actualFilter = {
            ...parsed,
        } as SubscriptionsFilterType;

        const filtersFromQuery: SubscriptionsFilterType | any = {
            city: actualFilter.city,
            price: actualFilter.price,
        };

        for (const key in filtersFromQuery) {
            // eslint-disable-next-line no-prototype-builtins
            if (filtersFromQuery.hasOwnProperty(key)) {
                if (filtersFromQuery[key] === undefined) delete filtersFromQuery[key];
            }
        }

        if (Object.keys(filtersFromQuery).length !== 0) {
            // dispatch(geolocationSlice.actions.setHoverNull());
            setFilterFromQuery(filtersFromQuery);
            dispatch(filterSlice.actions.setSubscriptionsFilter(filtersFromQuery));
        }
    }, []);

    useEffect(() => {
        const query = `city=${filter.city}&price=${
            filter.price === '' ||
            filter.price === undefined ||
            filter.price === null
                ? '0'
                : filter.price
        }`;

        const parsedQuery = qs.parse(query, {delimiter: '&'});

        type FilterTypeParsed = {
            city?: string;
            price?: string;
        };

        const updValues: FilterTypeParsed = Object.fromEntries(
            Object.entries(parsedQuery).filter(
                n =>
                    n[0] !== '' &&
                    n[1] !== 'undefined' &&
                    n[1] !== 'null' &&
                    n[1] !== 'false' &&
                    n[1] !== undefined &&
                    n[1] !== '' &&
                    n[1] !== null &&
                    n[1] !== '0' &&
                    n[1] !== '0-0' &&
                    n[1] !== '0-0 ',
            ),
        );

        const finalValues = {
            ...updValues,
        };

        const toFilter = qs.stringify(finalValues, {
            delimiter: '&',
            arrayFormat: 'indices',
        });

        console.log('tofilter', toFilter);

        history.push({
            pathname: 'subscriptions',
            search: toFilter,
        });
    }, [filter]);

    useEffect(() => {
        dispatch(subscriptionOperation.getSubscriptions({filter, size: 10, page: 0}));
    }, [filter]);

    useEffect(() => {
        if (type === Type.Looking) {
            dispatch(subscriptionOperation.getSubscriptions({filter, size: 10, page: 0}));
        }
    }, []);

    const setRentType = async () => {
        await dispatch(filterSlice.actions.setType(Type.Rent));
        history.push('/offers');
    };

    useEffect(() => {
        dispatch(filterSlice.actions.setType(Type.Looking));
    }, []);

    return (
        <div>
            <div className={subscriptionPageStyles.flex_container}>
                <div className={s.choice}>
                    <button
                        onClick={setRentType}
                        className={`${s.choice_btn} ${type === Type.Rent && s.choice_btn__underline}`}
                    >
                        {t('searchPage.choice.looking')}
                    </button>
                    {user.hasOffers &&
                      <button
                        className={`${s.choice_btn} ${type === Type.Looking && s.choice_btn__underline}`}
                      >
                          {t('searchPage.choice.rent')}
                      </button>}
                </div>
                <FilterSmartSearchForm />
                <FilterApplied />
                <SubscriptionsForm />
            </div>
        </div>
    );
};

export default SubscriptionPage;