import React from 'react';
import {useTranslation} from 'react-i18next';
import useWindowSize from "../../hooks/useWindowSize";
import WindowSize from "../../enums/WindowSize";
import {useHistory} from "react-router-dom";
import Select from 'react-select';

import ukrainian from '../../assets/icons/localize/ukrainian.svg';
import english from '../../assets/icons/localize/english.svg';

import localizeBtnStyles from './LocalizeButtons.module.scss';

const LocalizeButtons: React.FC = () => {

    const history = useHistory();

    const {i18n} = useTranslation();

    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language);
    };

    const handleChange = (e: any) => {
        changeLanguage(e.target.value);
    };

    const {isScreenResolutionMobile} = useWindowSize();

    const options = [
        {value: 'ua', label: 'Українська', imageUrl: ukrainian},
        {value: 'eng', label: 'English', imageUrl: english},
    ];

    const customStyles = {
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundImage: `url(${state.data.imageUrl})`,
            backgroundSize: '20px 20px',
            backgroundRepeat: 'no-repeat',
            paddingLeft: '30px',
            border: 'none',
            boxShadow: 'none',
        }),
        control: (baseStyles: any, state: any) => ({
            ...baseStyles,
            border: 'none',
            boxShadow: 'none',
            '&:hover': {
                backgroundColor: '#e0e0e0',
                border: 'none'
            },
            '&:active': {
                border: 'none',
                outline: 'none'
            },
            '&:focus': {
                border: 'none',
                outline: 'none'
            }
        }),
    };

    return (
        <div onChange={handleChange}>
            <select
                className={
                    isScreenResolutionMobile || history.location.pathname !== '/' ? localizeBtnStyles.localization__black : localizeBtnStyles.white
                }
            >
                <option value="ua" className={localizeBtnStyles.option_ua}>
                    Українська
                </option>
                {/*<option value="eng" className={localizeBtnStyles.option_eng}>English</option>*/}
            </select>
        </div>
        // <Select
        //     onChange={handleChange}
        //     options={options}
        //     defaultValue={options[0]}
        //     isSearchable={false}
        //     styles={customStyles}
        // />
    );
};

export default LocalizeButtons;
