import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Slider from 'react-slick';
import {useDispatch, useSelector} from 'react-redux';
import {OfferElementsType, OffersAcceptancesCountType} from '../../models/generalTypes';
import userOperations from '../../features/user/userOperations';
import userSelectors from '../../features/user/userSelectors';
import AcceptancesModal from '../Acceptances/AcceptancesModal/AcceptancesModal';
import LoaderButtonService from '../../common/Button/loaderButtonService/LoaderButtonService';
import acceptanceSelectors from '../../features/acceptance/acceptanceSelectors';
import userSlice from '../../features/user/userSlice';
import useWindowSize from '../../hooks/useWindowSize';
import useDateFormatting from '../../hooks/useDateFormatting';
import BasicModal from '../BasicModal/BasicModal';

import LoaderColor from '../../common/Button/enum/LoaderColor';
import WindowSize from '../../enums/WindowSize';
import PhotoSize from '../Acceptances/enums/PhotoSize';
import DateFormat from '../../hooks/enums/DateFormat';

import price from '../Acceptances/images/price.svg';
import address from '../Acceptances/images/address.svg';
import bell from '../../assets/icons/bell-offers.svg';
import bellMobile from '../../assets/icons/myOffers/mobile/bell.svg';
import updateMobile from '../../assets/icons/myOffers/mobile/update.svg';
import update from '../Acceptances/images/update.svg';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import myOffersStyles from './MyOffersAndAcceptancesForm.module.scss';
import closeBtnSmall from '../../assets/icons/myOffers/closeBtn.svg';

export enum Inactive {
    Active = 'ACTIVE',
    Not = 'NOT_ACTIVE',
    Deleted = 'DELETED'
}

const MyOffersAndAcceptancesForm = () => {

    const {t} = useTranslation();

    const settings = {
        className: 'center',
        centerPadding: '60px',
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
    };

    const userOffers = useSelector(
        userSelectors.getUserOffers.selectAll,
    ) as Array<OfferElementsType>;

    const [offers, setOffers] = useState(userOffers);

    useEffect(() => {
        setOffers(userOffers);
    }, [userOffers]);

    const totalUserOffers = useSelector(userSelectors.getTotalUserOffers);

    const totalPages = useSelector(userSelectors.getTotalPagesUserOffers);

    const userOffer = useSelector(userSelectors.getUserOffer);

    const [page, setPage] = useState(1);

    useEffect(() => {
        return () => {
            dispatch(userSlice.actions.clearUserOffers(userOffers));
            dispatch(userSlice.actions.clearTotalUserOffers(totalUserOffers));
            localStorage.setItem(key, String(false));
        };
    }, []);

    const history = useHistory();

    const dispatch = useDispatch();

    const formattedDate = useDateFormatting();

    const [isModalOpen, toggleModal] = useState(false);

    const [offerId, setOfferId] = useState();

    const getOfferId = useCallback(
        id => {
            setOfferId(id);
        },
        [offerId],
    );

    const getOffersAcceptancesCount: Array<OffersAcceptancesCountType> =
        useSelector(acceptanceSelectors.getOffersAcceptancesCount.selectAll);

    const getAcceptancesCount = useCallback(
        (id: any) => {
            if (getOffersAcceptancesCount.length !== 0 &&
                getOffersAcceptancesCount.find(count => id === count.offerId) !== undefined) {
                return getOffersAcceptancesCount.find(count => id === count.offerId)?.acceptancesCount;
            } else return null;
        },
        [getOffersAcceptancesCount],
    );

    const addressOffer = useMemo(
        () => userOffers?.filter(offer => offer.id === offerId).map(offer => offer.geolocation.address)[0]
        , [userOffers, offerId],
    );

    const showBtn = () => {
        return userOffers.length !== 0 &&
            totalPages > 1 &&
            userOffers.length < totalUserOffers;
    };

    const {isScreenResolutionMobile} = useWindowSize();

    const key = 'filter_checked';

    const filterChecked = JSON.parse(localStorage.getItem(key) || '{}');

    const [checked, setChecked] = useState(filterChecked);

    useEffect(() => {
        if (checked) {
            dispatch(userSlice.actions.clearUserOffers(userOffers));
            dispatch(userOperations.getUserOffers({page: 0, size: 10, hasAcc: true}));
        } else dispatch(userOperations.getUserOffers({page: 0, size: 10}));
    }, [checked, userOffer]);

    const [isDeleteModal, toggleDeleteModal] = useState(false);

    const deleteOffer = async () => {
        if (offerId !== undefined) {
            await dispatch(
                userOperations.setOfferIdState({
                    offerId: offerId,
                    state: 'DELETED',
                }),
            );
            toggleDeleteModal(!isDeleteModal);
        }
    };

    return (
        <div>
            <div className={myOffersStyles.flex_count}>
                <h5 className={myOffersStyles.offers_count}>{t('acceptance.Count')}: {totalUserOffers}</h5>
                <div className={myOffersStyles.flex_count_mobile_row}>
                    <p className={myOffersStyles.filter_name}>{t('myOffersPage.filter')}</p>
                    <input
                        type="checkbox"
                        id="checkbox"
                        checked={checked}
                        className={myOffersStyles.filter_ckeckbox}
                        onChange={() => {
                            setChecked(!checked);
                        }}
                    />
                </div>
            </div>
            {offerId !== undefined &&
              <BasicModal isModalOpen={isDeleteModal}
                          toggleModal={toggleDeleteModal}
                          header={t('offerDeleteModal.header')}
                          question={t('offerDeleteModal.question')}
                          leftButton={t('offerDeleteModal.btn.cancel')}
                          rightButton={t('offerDeleteModal.btn.remove')}
                          onClick={deleteOffer}
              />}
            {offerId !== undefined &&
              <AcceptancesModal
                address={addressOffer}
                isModalOpen={isModalOpen}
                toggleModal={toggleModal}
                offerId={offerId}
                key={offerId}
              />}
            <div className={myOffersStyles.main}>
                {offers.length !== 0 && offers &&
                    offers
                        .filter(offer => offer.state !== Inactive.Deleted)
                        .map(offer => (
                            <li className={myOffersStyles.item} key={offer?.id}>
                                <div className={myOffersStyles.flex_container}>
                                    {isScreenResolutionMobile && offer.photos.length !== 0 &&
                                      <div
                                        role="button"
                                        onClick={() => {
                                            toggleDeleteModal(!isDeleteModal);
                                            getOfferId(offer.id);
                                        }}
                                      >
                                        <img src={closeBtnSmall} alt="close"
                                             className={myOffersStyles.delete_offer} />
                                      </div>}
                                    {offer.photos.length !== 0 ? (
                                        <Slider {...settings} className={myOffersStyles.slider}>
                                            {offer.photos
                                                .filter(photo => photo.photoSize === PhotoSize.Large)
                                                .map(photo => (
                                                    <>
                                                        <div className={myOffersStyles.slider_item} key={photo.id}>
                                                            <img
                                                                className={myOffersStyles.slider_img}
                                                                alt="hata"
                                                                src={photo.photoLocation}
                                                            />
                                                        </div>
                                                    </>
                                                ))}
                                        </Slider>
                                    ) : (
                                        <Slider {...settings} className={myOffersStyles.slider}>
                                            <>
                                                <div className={myOffersStyles.slider_item}>
                                                    <img
                                                        className={myOffersStyles.slider_img}
                                                        alt="haddta"
                                                        width="200"
                                                        height="140"
                                                        src="https://images.all-free-download.com/images/graphicwebp/bird_houses_208582.webp"
                                                    />
                                                    {isScreenResolutionMobile &&
                                                      <div
                                                        role="button"
                                                        onClick={() => {
                                                            toggleDeleteModal(!isDeleteModal);
                                                            getOfferId(offer.id);

                                                        }}
                                                      >
                                                        <img src={closeBtnSmall} alt="close"
                                                             className={myOffersStyles.delete_offer} />
                                                      </div>}
                                                </div>
                                            </>
                                        </Slider>
                                    )}

                                    {!isScreenResolutionMobile ?
                                        <div className={myOffersStyles.flex__item} key={offer.id}>
                                            <div className={myOffersStyles.row}>
                                                <div className={myOffersStyles.col}>
                                                    <div className={myOffersStyles.price}>
                                                        <img src={price} alt="price" />
                                                        <p className={myOffersStyles.price_cur}>
                                                            {offer.price} {offer.currency}/{t('myOffersPage.currency')}
                                                        </p>
                                                    </div>

                                                    <div className={myOffersStyles.price}>
                                                        <img src={address} alt="address" />
                                                        <p className={myOffersStyles.adress}>{offer.geolocation.address}</p>
                                                    </div>
                                                </div>
                                                <div className={myOffersStyles.flex_row}>
                                                    <div
                                                        onClick={() => {
                                                            if (getOffersAcceptancesCount.find(
                                                                count => offer.id === count.offerId,
                                                            ) !== undefined) {
                                                                toggleModal(!isModalOpen);
                                                                getOfferId(offer.id);
                                                            }
                                                        }}
                                                    >
                                                        <div
                                                            className={myOffersStyles.acceptance_count}
                                                        >
                                                            {getAcceptancesCount(offer.id) !== null &&
                                                              <div className={myOffersStyles.totalAcs}>
                                                                  {getAcceptancesCount(offer.id)}
                                                              </div>
                                                            }
                                                        </div>
                                                        <img src={bell} alt="bell"
                                                             className={`${myOffersStyles.mt} ${myOffersStyles.pointer}`} />
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            history.push(`/update/${offer.id}`);
                                                        }}
                                                    >
                                                        <img src={update} alt="update"
                                                             className={`${myOffersStyles.mt} ${myOffersStyles.pointer}`} />
                                                    </div>
                                                    <div className={myOffersStyles.pointer}>
                                                        <div
                                                            role="button"
                                                            onClick={() => {
                                                                toggleDeleteModal(!isDeleteModal);
                                                                getOfferId(offer.id);
                                                            }}
                                                        >
                                                            <img src={closeBtnSmall} alt="close"
                                                                 className={myOffersStyles.delete_offer} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={myOffersStyles.row_bottom}>
                                                <div className={myOffersStyles.posted}>
                                                    {formattedDate(offer.createdOn, DateFormat.NormalAndTime)}
                                                </div>
                                                <div className={myOffersStyles.row_details}>
                                                    <Link className={myOffersStyles.link_details}
                                                          to={`/offers/${offer.id}`}>
                                                        <span className={myOffersStyles.details}>{t('myOffersPage.details')}</span>
                                                    </Link>
                                                    {offer.state === Inactive.Active ? (
                                                        <LoaderButtonService
                                                            query={userOperations.setOfferIdState({
                                                                offerId: offer.id,
                                                                state: Inactive.Not,
                                                            })}
                                                            buttonContent={t('acceptance.Stop')}
                                                            buttonClassName={'my_offer_base_btn'}
                                                            color={LoaderColor.White}
                                                        />
                                                    ) : (
                                                        <LoaderButtonService
                                                            query={userOperations.setOfferIdState({
                                                                offerId: offer.id,
                                                                state: Inactive.Active,
                                                            })}
                                                            buttonContent={t('acceptance.Activate')}
                                                            buttonClassName={'my_offer_confirmed_btn'}
                                                            color={LoaderColor.Green}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <div className={myOffersStyles.col}>
                                                <div className={myOffersStyles.price}>
                                                    <img src={price} alt="price" />
                                                    <p className={myOffersStyles.price_cur}>
                                                        {offer.price} {offer.currency}/{t('myOffersPage.currency')}
                                                    </p>
                                                </div>

                                                <div className={myOffersStyles.price}>
                                                    <img src={address} alt="address" />
                                                    <p className={myOffersStyles.adress}>{offer.geolocation.address}</p>
                                                </div>

                                                <div className={myOffersStyles.posted}>
                                                    {formattedDate(offer.createdOn, DateFormat.NormalAndTime)}
                                                </div>
                                            </div>

                                            <div className={`${myOffersStyles.row_bottom} mt-4`}>
                                                {offer.state === Inactive.Active ? (
                                                    <LoaderButtonService
                                                        query={userOperations.setOfferIdState({
                                                            offerId: offer.id,
                                                            state: Inactive.Not,
                                                        })}
                                                        buttonContent={t('acceptance.Stop')}
                                                        buttonClassName={'my_offer_base_btn'}
                                                        color={LoaderColor.White}
                                                    />
                                                ) : (
                                                    <LoaderButtonService
                                                        query={userOperations.setOfferIdState({
                                                            offerId: offer.id,
                                                            state: Inactive.Active,
                                                        })}
                                                        buttonContent={t('acceptance.Activate')}
                                                        buttonClassName={'my_offer_confirmed_btn'}
                                                        color={LoaderColor.Green}
                                                    />
                                                )}
                                                <div
                                                    onClick={() => {
                                                        if (getOffersAcceptancesCount.find(
                                                            count => offer.id === count.offerId,
                                                        ) !== undefined) {
                                                            toggleModal(!isModalOpen);
                                                            getOfferId(offer.id);
                                                        }
                                                    }}
                                                >
                                                    <div
                                                        className={myOffersStyles.acceptance_count}
                                                    >
                                                        {getAcceptancesCount(offer.id) !== null &&
                                                          <div className={myOffersStyles.totalAcs}>
                                                            <span>{getAcceptancesCount(offer.id)}</span>
                                                          </div>
                                                        }
                                                    </div>
                                                    <img src={bellMobile} alt="bell" className={myOffersStyles.mt} />
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        history.push(`/update/${offer.id}`);
                                                    }}
                                                >
                                                    <img src={updateMobile} alt="update"
                                                         className={myOffersStyles.mt} />
                                                </div>
                                            </div>
                                        </div>}
                                </div>
                            </li>
                        ))}
                {showBtn() && <LoaderButtonService query={userOperations.getUserOffers({page: page, size: 10})}
                                                   buttonContent={t('myOffersPage.more')} buttonClassName={'btn_more'}
                                                   page={page} setPage={setPage}
                                                   color={LoaderColor.Green} />}
            </div>
        </div>
    );
};

export default MyOffersAndAcceptancesForm;