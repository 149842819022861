import React from 'react';
// import ReactDOM from 'react-dom';
import Root from './components/Root';

import './i18n';
import './styles/base.scss';


import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');



const root = createRoot(container!);

root.render(<Root/>);

// ReactDOM.render(<Root />, document.getElementById('root'));
