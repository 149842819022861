import axios from 'axios';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {baseURL} from '../base';

axios.defaults.baseURL = 'http://api.rentbox.com.ua';

const getAllOffers = createAsyncThunk('offers/allOffers', async () => {
    const response = (await axios.get(`${baseURL}/offers?page=0&size=10`)) as any;
    return response.data.elements;
});

const getLatestOffers = createAsyncThunk('offers/latestOffers', async () => {
    const response = (await axios.get(
        `${baseURL}/offers?page=0&size=3&sort=createdOn,desc`,
    )) as any;

    return response.data.elements;
});

const getOfferWithId = createAsyncThunk(
    'offers/offerWithId',
    async (id: string | any) => {
        const response = await axios.get(`${baseURL}/offers/${id}`);
        return response.data;
    },
);

const createOffer = createAsyncThunk(
    'offers/createOffer',
    async (credentials: any) => {
        const response = await axios.post(`${baseURL}/offers`, credentials);
        return response.data;
    },
);

type ResponseSetPhotoSectionTypePayloadType = {
    images: Array<any>;
};

const uploadOfferPhotos = createAsyncThunk(
    'offers/uploadOfferPhotos',
    async (credentials: {id: any, photos: Array<any>}) => {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: '*/*',
                'Cache-Control': 'no-cache',
            },
        };

        const formData = new FormData();

        credentials.photos.map((photo: any) => photo.map((item: any) => formData.append('photos', item)));

        const response = await axios.post(
            `${baseURL}/offers/${credentials.id}/photos`,
            formData,
            config,
        );

        return response.data as ResponseSetPhotoSectionTypePayloadType;
    },
);

const updateOfferWithId = createAsyncThunk(
    'offers/updateOfferWithId',
    async (credentials: {offerId: string; values: any}) => {
        const response = await axios.put(
            `${baseURL}/offers/${credentials.offerId}`,
            credentials.values,
        );
        return response.data;
    },
);

const getFacilities = createAsyncThunk(
    'offers/getFacilities',
    async () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        };
        const response = await axios.get(`${baseURL}/facilities `, config);
        return response.data;
    },
);

const getOfferCreator = createAsyncThunk(
    'acceptance/offerCreator',
    async (id: string | number) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            };
            const {data} = await axios.get(
                `${baseURL}/private-data/offer-creator?offerId=${id}`,
                config,
            );
            return data;
        } catch (error) {
            console.log('error', error);
        }
    },
);

const deleteOfferPhotos = createAsyncThunk(
    'offerPhotos/delete',
    async (cred: {offerId: string | number, photoGroupId: string | number}) => {
        try {
            const {data} = await axios.delete(`${baseURL}/offers/${cred.offerId}/photos/${cred.photoGroupId}`);
            return data;
        } catch (error) {
            console.log('error', error);
        }
    },
);

const operations = {
    getLatestOffers,
    getFacilities,
    deleteOfferPhotos,
    updateOfferWithId,
    getAllOffers,
    getOfferWithId,
    createOffer,
    uploadOfferPhotos,
    getOfferCreator,
};
export default operations;