import React from 'react';
import {Type} from '../../pages/SearchPage/SearchPage';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import userSelectors from '../../features/user/userSelectors';
import {ResponseRefreshUserSuccessActionPayloadType} from '../../models/generalTypes';
import filterSelectors from '../../features/filter/filterSelectors';
import filterSlice from '../../features/filter/filterSlice';
import {useHistory} from 'react-router-dom';

import lookingRentStyles from './LookingRentTabs.module.scss';

const LookingRentTabs = () => {

    const {t} = useTranslation();

    const user = useSelector(
        userSelectors.getUserProfile,
    ) as ResponseRefreshUserSuccessActionPayloadType;

    const type = useSelector(filterSelectors.getType);

    const dispatch = useDispatch();

    const history = useHistory();

    const setLookingType = async () => {
        await dispatch(filterSlice.actions.setType(Type.Looking));
        history.push('/subscriptions');
    };

    const setRentType = async () => {
        await dispatch(filterSlice.actions.setType(Type.Rent));
        history.push('/offers');
    };

    const isLookingType = type === Type.Looking;

    return (
        <>
            <div className={lookingRentStyles.choice}>
                {isLookingType ?
                    <>
                        <button
                            onClick={setRentType}
                            className={lookingRentStyles.choice_btn}
                        >
                            {t('searchPage.choice.looking')}
                        </button>
                        {user && user.hasOffers &&
                          <button
                            className={`${lookingRentStyles.choice_btn} ${lookingRentStyles.choice_btn__underline}`}
                          >
                              {t('searchPage.choice.rent')}
                          </button>}
                    </>
                    :
                    <>
                        <button
                            className={`${lookingRentStyles.choice_btn} ${lookingRentStyles.choice_btn__underline}`}
                        >
                            {t('searchPage.choice.looking')}
                        </button>
                        {user && user.hasOffers &&
                          <button
                            onClick={setLookingType}
                            className={lookingRentStyles.choice_btn}
                          >
                              {t('searchPage.choice.rent')}
                          </button>}
                    </>
                }
            </div>
        </>
    );
};

export default LookingRentTabs;