import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import authOperations from './authOperations';
import {toast} from "react-toastify";
import i18n from "i18next";

interface InitialState {
    isCodeModalOpen: boolean;
    isEmailCodeModalOpen: boolean;
    isUpdatePassword: boolean;
    isConfirmedModalOpen: boolean;
    headerByMobileVersion: string;
    isAuthModalOpen: boolean;
    isLoggedIn: boolean;
    isNotCorrectPassword: boolean;
    isForgotPassword: boolean;
    authToken: any | string;
    error: any;
    phone: string;
    name: string;
    email: string;
    password: string;
    code: string | number;
}

const initialState: InitialState = {
    isCodeModalOpen: false,
    isUpdatePassword: false,
    isEmailCodeModalOpen: false,
    isConfirmedModalOpen: false,
    headerByMobileVersion: '',
    isAuthModalOpen: false,
    isLoggedIn: false,
    isNotCorrectPassword: false,
    isForgotPassword: false,
    authToken: null,
    error: null,
    phone: '',
    name: '',
    email: '',
    password: '',
    code: ''
};

interface Phone {
    phone: string;
}

interface Password {
    password: string;
}

interface Email {
    email: string;
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        toggleAuthModal: {
            reducer: (state, action: PayloadAction) => {
                state.isAuthModalOpen = !state.isAuthModalOpen;
            },
            prepare: (isAuthModalOpen: any) => {
                return {payload: isAuthModalOpen};
            }
        },
        setIsConfirmedModal: {
            reducer: (state, action: PayloadAction) => {
                state.isConfirmedModalOpen = false;
            },
            prepare: (isConfirmedModal: any) => {
                return {payload: isConfirmedModal};
            }
        },
        setHeaderByMobileVersion: {
            reducer: (state, action: any) => {
                state.headerByMobileVersion = action.payload.headerByMobileVersion;
            },
            prepare: (headerByMobileVersion: string) => {
                return {payload: {headerByMobileVersion}};
            },
        },
        setAuthTokenNull: {
            reducer: (state, action: any) => {
                state.isLoggedIn = false;
                state.authToken = null;
            },
            prepare: (authToken: string) => {
                return {payload: {authToken}};
            },
        },
        isNotCorrectPasswordFalse: {
            reducer: (state, action: PayloadAction) => {
                state.isNotCorrectPassword = false;
            },
            prepare: (isNotCorrectPassword: any) => {
                return {payload: isNotCorrectPassword};
            },
        },
        setIsForgotPassword: {
            reducer: (state, action: PayloadAction) => {
                state.isForgotPassword = !state.isForgotPassword;
            },
            prepare: (isForgotPassword: any) => {
                return {payload: isForgotPassword};
            },
        },
        setPhone: {
            reducer: (state, action: PayloadAction<Phone>) => {
                state.phone = action.payload.phone;
            },
            prepare: (phone: string) => {
                return {payload: {phone}};
            },
        },
        setPassword: {
            reducer: (state, action: PayloadAction<Password>) => {
                state.password = action.payload.password;
            },
            prepare: (password: string) => {
                return {payload: {password}};
            },
        },
        setEmail: {
            reducer: (state, action: PayloadAction<Email>) => {
                state.email = action.payload.email;
            },
            prepare: (email: string) => {
                return {payload: {email}};
            },
        },
        setName: {
            reducer: (state, action: PayloadAction<string>) => {
                state.name = action.payload;
            },
            prepare: (name: string) => {
                return {payload: name};
            },
        },
        toggleCodeModal: {
            reducer: (state, action: PayloadAction<boolean>) => {
                state.isCodeModalOpen = action.payload;
            },
            prepare: (boolean: boolean) => {
                return {payload: boolean};
            },
        },
    },
    extraReducers: builder => {
        builder.addCase(authOperations.register.fulfilled, (state, action) => {
            if (action.payload?.headers.authorization !== null) {
                state.authToken = action.payload?.headers.authorization;
                state.isLoggedIn = true;
                state.isConfirmedModalOpen = true;
                toast.success(i18n.t('notification.register'));
            }
        });
        builder.addCase(authOperations.logIn.rejected, (state, action) => {
            state.authToken = null;
            state.isLoggedIn = false;
            state.isNotCorrectPassword = true;
        });
        builder.addCase(authOperations.logIn.fulfilled, (state, action) => {
            if (action.payload?.headers.authorization !== null) {
                state.authToken = action.payload?.headers.authorization;
                state.isNotCorrectPassword = false;
                state.isLoggedIn = true;
                toast.success(i18n.t('notification.login'))
            }
        });
        builder.addCase(authOperations.updatePassword.fulfilled, state => {
            toast.success(i18n.t('notification.update'));
        });
        builder.addCase(
            authOperations.logInSecurityCode.fulfilled,
            (state, {payload}) => {
                state.authToken = payload;
                if (state.authToken !== null) {
                    state.isLoggedIn = true;
                }
            },
        );
        builder.addCase(
            authOperations.forgotSecurityCodeEmail.fulfilled, (state, {payload}) => {
                state.isUpdatePassword = true;
                state.code = payload;
                state.isEmailCodeModalOpen = true;
                state.isCodeModalOpen = true;
            }
        );
    },
});

export default authSlice;
