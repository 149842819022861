import {AppStateType} from '../../redux/store';
import {offerCreatorAdapter} from "./offersSlice";

const getAllOffers = (state: AppStateType) => state.offers.allOffers;

const getLatestOffers = (state: AppStateType) => state.offers.latestOffers;

const getOfferWithId = (state: AppStateType) => state.offers.offerWithId;

const isFormSubmitted = (state: AppStateType) => state.offers.formSubmitted;

const uploadedPhotosToUserOffer = (state: AppStateType) =>
    state.offers.uploadedPhotosToUserOffer;

export const getFacilities = (state: AppStateType) =>
    state.offers.newOffer.facilities;

export const updatedCommunalIncuding = (state: AppStateType) =>
    state.offers.newOffer.updatedCommunalIncluding;

const getId = (state: AppStateType) => state.offers.id;

export const updatedAnimalPermission = (state: AppStateType) =>
    state.offers.newOffer.updatedAnimalPermission;

export const createdOfferFacility = (state: AppStateType) =>
    state.offers.newOffer.facilities;

export const getCityWhenCreate = (state: AppStateType) =>
    state.offers.newOffer.city;

export const getOfferCreator = offerCreatorAdapter.getSelectors<AppStateType>(
    (state) => state.offers.offerCreator
);

export const photosWhenCreateOffer = (state: AppStateType) =>
    state.offers.photosWhenCreateOffer;

export const photosWhenOfferUpdate = (state: AppStateType) =>
    state.offers.photosWhenOfferUpdate;

export const photosFilesWhenCreateOffer = (state: AppStateType) =>
    state.offers.photosFilesWhenCreateOffer;

export const photosFilesWhenUpdateOffer = (state: AppStateType) =>
    state.offers.photosFilesWhenOfferUpdate;

export const isOfferUpdated = (state: AppStateType) =>
    state.offers.isOfferUpdated;

const offersSelectors = {
    isOfferUpdated,
    photosWhenCreateOffer,
    photosWhenOfferUpdate,
    photosFilesWhenUpdateOffer,
    isFormSubmitted,
    photosFilesWhenCreateOffer,
    getLatestOffers,
    getFacilities,
    getId,
    getAllOffers,
    getOfferWithId,
    uploadedPhotosToUserOffer,
    updatedCommunalIncuding,
    updatedAnimalPermission,
    getCityWhenCreate,
    createdOfferFacility,
    getOfferCreator
};

export default offersSelectors;
