import {createEntityAdapter, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    FilterType, OfferElementsType, SubscriptionsFilterType,
} from '../../models/generalTypes';
import filterOperations from './filterOperations';

export const filteredOffersAdapter = createEntityAdapter<OfferElementsType>();

interface InitialState {
    filterFacilities: any;
    filter: FilterType;
    subscriptionsFilter: SubscriptionsFilterType;
    type: string;
    isLoading: boolean;
    filteredOffers: any;
    smartSearchedOffers: any;
    totalElements: any;
    totalPages: any;
    filterAppliedWidth: any;
    isChangeType: boolean;
}

const initialState: InitialState = {
    filteredOffers: filteredOffersAdapter.getInitialState(),
    isLoading: true,
    filterFacilities: [],
    totalElements: null,
    totalPages: null,
    filterAppliedWidth: null,
    isChangeType: false,
    type: '',
    subscriptionsFilter: {
        city: 'Kyiv',
        price: '',
    },
    filter: {
        animalPermission: undefined,

        currency: 'UAH',

        country: undefined,

        city: 'Kyiv',

        rooms: [],

        state: undefined,

        areaFrom: '',
        areaTo: '',

        priceFrom: '',
        priceTo: '',

        floorFrom: '',
        floorTo: '',

        buildingFloorFrom: '',
        buildingFloorTo: '',

        constructionYearFrom: '',
        constructionYearTo: '',

        facilities: [],
    },
    smartSearchedOffers: [],
};

export type CityType = {
    code: string;
    name: string | undefined;
};

const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setFilterAppliedWidth: (state, {payload}) => {
            state.filterAppliedWidth = payload;
        },
        clearFilteredOffers: (state, {payload}) => {
            filteredOffersAdapter.removeAll(state.filteredOffers);
        },
        setFilter: {
            reducer: (state, action: PayloadAction<FilterType>) => {
                state.filter = {...state.filter, ...action.payload};
            },
            prepare: (filter: FilterType) => {
                return {payload: filter};
            },
        },
        setSubscriptionsFilter: {
            reducer: (state, action: PayloadAction<SubscriptionsFilterType>) => {
                state.subscriptionsFilter = {...state.subscriptionsFilter, ...action.payload};
            },
            prepare: (subscriptionsFilter: SubscriptionsFilterType) => {
                return {payload: subscriptionsFilter};
            },
        },
        setFilterFacility: {
            reducer: (state, action: any) => {
                if (!state.filterFacilities.includes(action.payload)) {
                    state.filterFacilities.push(action.payload);
                }
            },
            prepare: (facility: string) => {
                return {payload: facility};
            },
        },
        removeFilterFacility: {
            reducer: (state, action: any) => {
                state.filterFacilities = state.filterFacilities.filter(
                    (facility: any) => facility !== action.payload,
                );
            },
            prepare: (facility: string) => {
                return {payload: facility};
            },
        },
        removeFilter: {
            reducer: (state, action: PayloadAction<FilterType>) => {
                state.filter = {
                    ...initialState.filter,
                    type: state.filter.type,
                };
            },
            prepare: (filter: FilterType) => {
                return {payload: filter};
            },
        },
        setDefaultFilter: {
            reducer: (state, action: PayloadAction<FilterType>) => {
                state.filter = {...initialState.filter};
            },
            prepare: (filter: FilterType) => {
                return {payload: filter};
            },
        },
        setCity: {
            reducer: (state, action: PayloadAction<CityType>) => {
                state.filter.city = action.payload.name;
            },
            prepare: (city: CityType) => {
                return {payload: city};
            },
        },
        setRoom: {
            reducer: (state, action: PayloadAction<{room: string}>) => {
                state.filter.rooms.includes(action.payload.room as string) === false &&
                state.filter.rooms.push(action.payload.room as string);
            },
            prepare: (room: string) => {
                return {payload: {room}};
            },
        },
        removeRoom: {
            reducer: (state, action: PayloadAction<{room: string}>) => {
                state.filter.rooms = state.filter.rooms.filter(
                    room => room !== action.payload.room,
                );
            },
            prepare: (room: string) => {
                return {payload: {room}};
            },
        },
        removeRooms: {
            reducer: (state, action: PayloadAction<{rooms: string}>) => {
                state.filter.rooms = [];
            },
            prepare: (rooms: string) => {
                return {payload: {rooms}};
            },
        },
        setPriceFrom: {
            reducer: (state, action: PayloadAction<{price: string}>) => {
                state.filter.priceFrom = action.payload.price;
            },
            prepare: (price: string) => {
                return {payload: {price}};
            },
        },
        setPrice: {
            reducer: (state, action: PayloadAction<{price: string}>) => {
                state.subscriptionsFilter.price = action.payload.price;
            },
            prepare: (price: string) => {
                return {payload: {price}};
            },
        },
        removePrice: {
            reducer: (state, action: PayloadAction<{price: string}>) => {
                if (typeof initialState.filter.priceTo === 'string') {
                    action.payload.price = initialState.subscriptionsFilter.price;
                }
                state.subscriptionsFilter.price = action.payload.price;
            },
            prepare: (price: string) => {
                return {payload: {price}};
            },
        },
        removePriceFrom: {
            reducer: (state, action: PayloadAction<{price: string}>) => {
                if (typeof initialState.filter.priceFrom === 'string') {
                    action.payload.price = initialState.filter.priceFrom;
                }
                state.filter.priceFrom = action.payload.price;
            },
            prepare: (price: string) => {
                return {payload: {price}};
            },
        },
        setType: {
            reducer: (state, action: PayloadAction<{type: string}>) => {
                state.type = action.payload.type;
                state.isChangeType = true;
            },
            prepare: (type: string) => {
                return {payload: {type}};
            },
        },
        setDefaultChangeType: {
            reducer: (state, action: any) => {
                state.isChangeType = false;
            },
            prepare: (type: boolean) => {
                return {payload: {type}};
            },
        },
        setPriceTo: {
            reducer: (state, action: PayloadAction<{price: string}>) => {
                state.filter.priceTo = action.payload.price;
            },
            prepare: (price: string) => {
                return {payload: {price}};
            },
        },
        removePriceTo: {
            reducer: (state, action: PayloadAction<{price: string}>) => {
                if (typeof initialState.filter.priceTo === 'string') {
                    action.payload.price = initialState.filter.priceTo;
                }
                state.filter.priceTo = action.payload.price;
            },
            prepare: (price: string) => {
                return {payload: {price}};
            },
        },
        setCurrency: {
            reducer: (state, action: PayloadAction<{currency: string}>) => {
                state.filter.currency = action.payload.currency;
            },
            prepare: (currency: string) => {
                return {payload: {currency}};
            },
        },
        removeAreaFrom: {
            reducer: (state, action: PayloadAction<{area: string}>) => {
                if (typeof initialState.filter.areaFrom === 'string') {
                    action.payload.area = initialState.filter.areaFrom;
                }
                state.filter.areaFrom = action.payload.area;
            },
            prepare: (area: string) => {
                return {payload: {area}};
            },
        },
        removeAreaTo: {
            reducer: (state, action: PayloadAction<{area: string}>) => {
                if (typeof initialState.filter.areaTo === 'string') {
                    action.payload.area = initialState.filter.areaTo;
                }
                state.filter.areaTo = action.payload.area;
            },
            prepare: (area: string) => {
                return {payload: {area}};
            },
        },
        removeFloorFrom: {
            reducer: (state, action: PayloadAction<{floor: string}>) => {
                if (typeof initialState.filter.floorFrom === 'string') {
                    action.payload.floor = initialState.filter.floorFrom;
                }
                state.filter.floorFrom = action.payload.floor;
            },
            prepare: (floor: string) => {
                return {payload: {floor}};
            },
        },
        removeFloorTo: {
            reducer: (state, action: PayloadAction<{floor: string}>) => {
                if (typeof initialState.filter.floorTo === 'string') {
                    action.payload.floor = initialState.filter.floorTo;
                }
                state.filter.floorTo = action.payload.floor;
            },
            prepare: (floor: string) => {
                return {payload: {floor}};
            },
        },
        removeBuildingFloorFrom: {
            reducer: (state, action: PayloadAction<{buildingFloor: string}>) => {
                if (typeof initialState.filter.buildingFloorFrom === 'string') {
                    action.payload.buildingFloor = initialState.filter.buildingFloorFrom;
                }
                state.filter.buildingFloorFrom = action.payload.buildingFloor;
            },
            prepare: (buildingFloor: string) => {
                return {payload: {buildingFloor}};
            },
        },
        removeBuildingFloorTo: {
            reducer: (state, action: PayloadAction<{buildingFloor: string}>) => {
                if (typeof initialState.filter.buildingFloorTo === 'string') {
                    action.payload.buildingFloor = initialState.filter.buildingFloorTo;
                }
                state.filter.buildingFloorTo = action.payload.buildingFloor;
            },
            prepare: (buildingFloor: string) => {
                return {payload: {buildingFloor}};
            },
        },
        removeConstructionYearFrom: {
            reducer: (state, action: PayloadAction<{constructionYear: string}>) => {
                if (typeof initialState.filter.constructionYearFrom === 'string') {
                    action.payload.constructionYear = initialState.filter.constructionYearFrom;
                }
                state.filter.constructionYearFrom = action.payload.constructionYear;
            },
            prepare: (constructionYear: string) => {
                return {payload: {constructionYear}};
            },
        },
        removeConstructionYearTo: {
            reducer: (state, action: PayloadAction<{constructionYear: string}>) => {
                if (typeof initialState.filter.constructionYearTo === 'string') {
                    action.payload.constructionYear = initialState.filter.constructionYearTo;
                }
                state.filter.constructionYearTo = action.payload.constructionYear;
            },
            prepare: (constructionYear: string) => {
                return {payload: {constructionYear}};
            },
        },
        removeAnimalPermission: {
            reducer: (state, action: PayloadAction<{animalPermission: string}>) => {
                action.payload.animalPermission = initialState.filter.animalPermission;
                state.filter.animalPermission = action.payload.animalPermission;
            },
            prepare: (animalPermission: string) => {
                return {payload: {animalPermission}};
            },
        },
        removeFacility: {
            reducer: (state, action: PayloadAction<{facility: string}>) => {
                state.filter.facilities = state.filter.facilities.filter(
                    (facility) => facility !== action.payload.facility,
                );
            },
            prepare: (facility: string) => {
                return {payload: {facility}};
            },
        },
        removeCity: {
            reducer: (state, action: PayloadAction<{city: string | undefined}>) => {
                action.payload.city = undefined;
                state.filter.city = action.payload.city;
            },
            prepare: (city: string) => {
                return {payload: {city}};
            },
        },
    },
    extraReducers: builder => {
        builder.addCase(
            filterOperations.getFilteredOffers.pending,
            (state, action) => {
                state.isLoading = true;
            },
        );
        builder.addCase(
            filterOperations.getFilteredOffers.rejected,
            (state, action) => {
                state.isLoading = false;
            },
        );
        builder.addCase(
            filterOperations.getFilteredOffers.fulfilled,
            (state, action) => {
                filteredOffersAdapter.setMany(state.filteredOffers, action.payload.data.elements);
                state.totalElements = action.payload.data.totalElements;
                state.totalPages = action.payload.data.totalPages;
                state.isLoading = false;
            },
        );
        builder.addCase(
            filterOperations.getSmartSearchedOffers.fulfilled,
            (state, action) => {
                state.smartSearchedOffers = action.payload;
            },
        );
    },
});

export default filterSlice;