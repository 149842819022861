import React, {useEffect, useState} from 'react';
import Modal from '../../../common/Modal';
import {NotificationType} from '../../../features/notification/notificationSlice';
import {NotificationTypeEnum} from '../notification/Notification';
import AcceptancesContactModal from '../../Acceptances/AcceptancesModal/contactModal/AcceptancesContactModal';
import {useDispatch, useSelector} from 'react-redux';
import offersOperations from '../../../features/offers/offersOperations';
import offersSelectors from '../../../features/offers/offersSelectors';
import {OfferCreatorType} from '../../../models/generalTypes';
import offersSlice from '../../../features/offers/offersSlice';
import acceptanceOperations from '../../../features/acceptance/acceptanceOperations';
import AcceptancesModal from '../../Acceptances/AcceptancesModal/AcceptancesModal';
import {useTranslation} from 'react-i18next';
import useWindowSize from '../../../hooks/useWindowSize';

import WindowSize from '../../../enums/WindowSize';
import Sort from '../../Acceptances/enums/Sort';
import Status from '../../Acceptances/enums/Status';

import closeButton from '../../Acceptances/images/closeButton.svg';
import circleCheck from '../../../assets/icons/notification/circle-check.svg';
import circleInformationYellow from '../../../assets/icons/notification/circle-unformation-yellow.svg';
import circleInformationBlue from '../../../assets/icons/notification/circle-information-blue.svg';
import circleError from '../../../assets/icons/notification/circle-error.svg';

import notificationModalStyles from './NotificationModal.module.scss';
import modalStyles from '../../../styles/modalStyles.module.scss';

interface Props {
    isModalOpen: boolean;
    toggleModal: (arg0: boolean) => any | void;
    notification: NotificationType;
}

const NotificationModal: React.FC<Props> = ({isModalOpen, toggleModal, notification}) => {

    const {t} = useTranslation();

    type ModalContentType = {
        header: string;
        greeting: string;
        body: string;
        link: string;
        parting: string;
    }

    const selectNotificationImage = (type: string) => {
        switch (type) {
            case NotificationTypeEnum.NewAcceptance:
                return <img src={circleInformationYellow} alt="yellow" className={notificationModalStyles.circle} />;
            case NotificationTypeEnum.ApproveAcceptance:
                return <img src={circleCheck} alt="check" className={notificationModalStyles.circle} />;
            case NotificationTypeEnum.RejectAcceptance:
                return <img src={circleError} alt="reject" className={notificationModalStyles.circle} />;
            case NotificationTypeEnum.OfferSubscription:
                return <img src={circleInformationBlue} alt="blue" className={notificationModalStyles.circle} />;
            default:
                return '';
        }
    };

    const getAcceptanceId = (context: string) => {
        const parsedResponse = JSON.parse(context);
        return parsedResponse.acceptanceId;
    };

    const getOfferSubscriptionId = (context: string) => {
        const id: string = JSON.parse(context).id;
        return id;
    };

    const getAddress = (context: string) => {
        const offerAddress: string = JSON.parse(context).offerAddress;
        return offerAddress;
    };

    const getOfferId = (str: string) => {
        const {offerId}: {offerId: number} = JSON.parse(str);
        return offerId;
    };

    const selectTypeContentNotification = (type: string) => {
        switch (type) {
            case NotificationTypeEnum.NewAcceptance:
                return {
                    header: `${t(`notification.modal.${NotificationTypeEnum.NewAcceptance}.header`)}`,
                    greeting: `${t(`notification.modal.${NotificationTypeEnum.NewAcceptance}.greeting`)}`,
                    body: `${t(`notification.modal.${NotificationTypeEnum.NewAcceptance}.body`)}`,
                    link: notification.notificationContext !== null ? '' : '',
                    parting: `${t(`notification.modal.${NotificationTypeEnum.NewAcceptance}.parting`)}`,
                } as ModalContentType;
            case NotificationTypeEnum.ApproveAcceptance:
                return {
                    header: `${t(`notification.modal.${NotificationTypeEnum.ApproveAcceptance}.header`)}`,
                    greeting: `${t(`notification.modal.${NotificationTypeEnum.ApproveAcceptance}.greeting`)}`,
                    body: `${t(`notification.modal.${NotificationTypeEnum.ApproveAcceptance}.body`)}`,
                    link: notification.notificationContext !== null ? '' : '',
                    parting: `${t(`notification.modal.${NotificationTypeEnum.ApproveAcceptance}.parting`)}`,
                } as ModalContentType;
            case NotificationTypeEnum.RejectAcceptance:
                return {
                    header: `${t(`notification.modal.${NotificationTypeEnum.RejectAcceptance}.header`)}`,
                    greeting: `${t(`notification.modal.${NotificationTypeEnum.RejectAcceptance}.greeting`)}`,
                    body: `${t(`notification.modal.${NotificationTypeEnum.RejectAcceptance}.body`)}`,
                    link: notification.notificationContext !== null ? '/offers' : '',
                    parting: `${t(`notification.modal.${NotificationTypeEnum.RejectAcceptance}.parting`)}`,
                } as ModalContentType;
            case NotificationTypeEnum.OfferSubscription:
                return {
                    header: `${t(`notification.modal.${NotificationTypeEnum.OfferSubscription}.header`)}`,
                    greeting: `${t(`notification.modal.${NotificationTypeEnum.OfferSubscription}.greeting`)}`,
                    body: `${t(`notification.modal.${NotificationTypeEnum.OfferSubscription}.body`)}`,
                    link: notification.notificationContext !== null ? `/offers/${getOfferSubscriptionId(notification.notificationContext)}` : '',
                    parting: `${t(`notification.modal.${NotificationTypeEnum.OfferSubscription}.parting`)}`,
                } as ModalContentType;
            default:
                return {} as ModalContentType;
        }
    };

    const [contentNotificationModal, setContentNotificationModal] = useState({} as ModalContentType);

    const [isContactModal, toggleContactModal] = useState(false);

    const [isAcceptanceModalOpen, toggleAcceptanceModal] = useState(false);

    const selectNotificationBtn = (type: string) => {
        switch (type) {
            case NotificationTypeEnum.NewAcceptance:
                return (
                    <a
                        className={notificationModalStyles.view}
                        onClick={async () => {
                            await dispatch(
                                acceptanceOperations.getOfferAcceptances({
                                    id: getOfferId(notification.notificationContext),
                                    page: 0,
                                    size: 5,
                                    sort: Sort.Created,
                                    statuses: Status.Waiting,
                                }),
                            );
                            toggleAcceptanceModal(!isAcceptanceModalOpen);
                        }}
                    >
                        {t(`notification.modal.btn.${NotificationTypeEnum.NewAcceptance}`)}
                    </a>
                );
            case NotificationTypeEnum.ApproveAcceptance:
                return (
                    <a onClick={() => toggleContactModal(!isContactModal)}
                       className={notificationModalStyles.details_btn}
                    >
                        {t(`notification.modal.btn.${NotificationTypeEnum.ApproveAcceptance}`)}
                    </a>
                );
            case NotificationTypeEnum.RejectAcceptance:
                return (
                    <a href={contentNotificationModal.link}
                       className={notificationModalStyles.view}
                    >
                        {t(`notification.modal.btn.${NotificationTypeEnum.RejectAcceptance}`)}
                    </a>
                );
            case NotificationTypeEnum.OfferSubscription:
                return (
                    <a href={contentNotificationModal.link}
                       className={notificationModalStyles.view}
                    >
                        {t(`notification.modal.btn.${NotificationTypeEnum.OfferSubscription}`)}
                    </a>
                );
            default:
                return '';
        }
    };

    const dispatch = useDispatch();

    useEffect(() => {
        if (isModalOpen) {
            setContentNotificationModal(selectTypeContentNotification(notification.notificationType));
            if (notification.notificationType === NotificationTypeEnum.ApproveAcceptance) {
                dispatch(offersOperations.getOfferCreator(getOfferId(notification.notificationContext)));
            }
        }
    }, [isModalOpen]);

    const offerCreator: Array<OfferCreatorType> = useSelector(offersSelectors.getOfferCreator.selectAll);

    const clear = () => dispatch(offersSlice.actions.clearOfferCreator(offerCreator));

    const {isScreenResolutionMobile} = useWindowSize();

    return (
        <Modal width={isScreenResolutionMobile ? '100%' : '50%'}
               height="auto"
               isOpen={isModalOpen}
               toggle={toggleModal}>

            <div className={notificationModalStyles.flex_start}>
                {selectNotificationImage(notification.notificationType)}
                <h5 className={notificationModalStyles.title}>{contentNotificationModal.header}</h5>
            </div>
            <div
                role="button"
                tabIndex={1}
                onKeyDown={() => toggleModal(!isModalOpen)}
                onClick={() => {
                    toggleModal(!isModalOpen);
                }}
                className={modalStyles.close}
            >
                <p>
                    <img src={closeButton} alt="close" />
                </p>
            </div>
            <div className={modalStyles.line}></div>

            <div className={notificationModalStyles.content}>
                <p className="mb-2">
                    {contentNotificationModal.greeting}
                </p>

                <p className={notificationModalStyles.body}>
                    {contentNotificationModal.body}
                </p>

                <p>
                    {selectNotificationBtn(notification.notificationType)}
                </p>

                <p>
                    {contentNotificationModal.parting}
                </p>
            </div>

            {isContactModal &&
              <AcceptancesContactModal isModalOpen={isContactModal} toggleModal={toggleContactModal}
                                       creator={offerCreator} clear={clear} key={notification.id} />}
            {isAcceptanceModalOpen && <AcceptancesModal address={getAddress(notification.notificationContext)}
                                                        isModalOpen={isAcceptanceModalOpen}
                                                        toggleModal={toggleAcceptanceModal}
                                                        offerId={getOfferId(notification.notificationContext)}
                                                        isNotification={true}
                                                        key={notification.id} />}

        </Modal>
    );
};

export default NotificationModal;