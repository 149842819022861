// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BasicModal_question__0grGf {\n  position: relative;\n  color: #4f4f4f;\n  text-align: center;\n  margin-top: 50px;\n  width: 100%;\n  font-size: 18px;\n}", "",{"version":3,"sources":["webpack://./src/components/BasicModal/BasicModal.module.scss","webpack://./src/styles/_colors.scss"],"names":[],"mappings":"AAGA;EACE,kBAAA;EACA,cCFa;EDGb,kBAAA;EACA,gBAAA;EACA,WAAA;EACA,eAAA;AAFF","sourcesContent":["@import \"src/styles/colors\";\n@import \"src/styles/sizes\";\n\n.question {\n  position: relative;\n  color: $asphalt-grey;\n  text-align: center;\n  margin-top: 50px;\n  width: 100%;\n  font-size: 18px;\n}","$green: #34a790;\n$aqua: #4ba1a7;\n$dark-blue: #253040;\n$asphalt-grey: #4f4f4f;\n$grey: #828282;\n$medium-grey: #bdbdbd;\n$semi-transparent-grey: #e0e0e0;\n$light-grey: #f2f2f2;\n$red: #eb5757;\n$white: #ffffff;\n$primary-green: #34A790;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"question": "BasicModal_question__0grGf"
};
export default ___CSS_LOADER_EXPORT___;
