import axios from "axios";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {baseURL} from "../base";

axios.defaults.baseURL = 'http://api.rentbox.com.ua';

const contactUs = createAsyncThunk(
    'contact/us',
    async (cred: { email: string, phone: string, name: string, message: string }) => {
        try {
            const {data} = await axios.post(`${baseURL}/notifications/emails/contact-us`, cred);
            return data;
        } catch (error) {
            console.log('error', error);
        }
    }
)

const operations = {
    contactUs
}

export default operations;