import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    FilterType,
} from '../../../models/generalTypes';
import filterSlice from '../../../features/filter/filterSlice';
import filterSelectors from '../../../features/filter/filterSelectors';
import {useTranslation} from 'react-i18next';
import {useHistory} from "react-router-dom";
import useWindowSize from "../../../hooks/useWindowSize";

import WindowSize from "../../../enums/WindowSize";

import './DropdownLink.scss';

type DropDownCurrencyPricePropsType = {
    width?: string | number;
    borderRadius?: string;
};
const DropdownLink: React.FC<DropDownCurrencyPricePropsType> = ({width, borderRadius,}) => {

    const {t} = useTranslation();

    const history = useHistory();

    const filter = useSelector(filterSelectors.getFilter) as FilterType;

    const ref = React.useRef<HTMLDivElement>(null);

    const initialHeader = t('filterModal.rooms');

    const [header, setHeader] = useState('');

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const dispatch = useDispatch();

    const handleChange = (e: any) => {
        {
            e.target.checked === false && e.target.id === '4'
                ? dispatch(filterSlice.actions.removeRoom('4')) &&
                dispatch(filterSlice.actions.removeRoom('5')) &&
                dispatch(filterSlice.actions.removeRoom('6')) &&
                dispatch(filterSlice.actions.removeRoom('7'))
                : e.target.checked === false && e.target.id !== '4'
                    ? dispatch(filterSlice.actions.removeRoom(e.target.id))
                    : e.target.checked === true && e.target.id === '4'
                        ? dispatch(filterSlice.actions.setRoom('4')) &&
                        dispatch(filterSlice.actions.setRoom('5')) &&
                        dispatch(filterSlice.actions.setRoom('6')) &&
                        dispatch(filterSlice.actions.setRoom('7'))
                        : e.target.checked === true &&
                        e.target.id !== '4' &&
                        dispatch(filterSlice.actions.setRoom(e.target.id));
        }
    };

    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);
        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isMenuOpen]);

    useEffect(() => {
        const rooms = filter.rooms.toString();
        if (rooms.includes('4')) {
            setHeader(rooms.replace('4,5,6,7', '4+'));
        } else setHeader(rooms);
    }, [filter]);

    const {isScreenResolutionMobile} = useWindowSize();

    const getCheckboxStyles = () => isScreenResolutionMobile ? "link_checkbox" : "";

    return (
        <div className="parent_div">
            <div ref={ref}>
                <div
                    role="button"
                    tabIndex={0}
                    style={{width, borderRadius}}
                    className={history.location.pathname === '/offers' ? "selector_dropdown_offers selector_dropdown__bg" : "selector_dropdown"}
                    onKeyDown={() => setIsMenuOpen(oldState => !oldState)}
                    onClick={() => setIsMenuOpen(oldState => !oldState)}
                >
                    <span className="dropdown-label">
                      <div className="flex-rows">
                          {`${initialHeader} `}
                          {!isScreenResolutionMobile &&
                              <span className="rooms_header">
                                {header}
                              </span>}
                      </div>
                    </span>
                    <div>
                        <svg
                            className="icon_down_room"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M17 10L12 16L7 10H17Z" fill="#253040"/>
                        </svg>
                    </div>
                </div>

                {isMenuOpen ? (
                    <div>
                        <form
                            className={history.location.pathname === '/offers' ? "dropdown-container-offers" : "dropdown-container"}
                            onChange={handleChange}>
                            {filter.rooms !== undefined &&
                            filter.rooms?.length !== 0 &&
                            filter.rooms.includes('1') ? (
                                <label className="control" htmlFor="1">
                                    <input type="checkbox" checked name="topics" id="1"
                                           className={getCheckboxStyles()}/>
                                    <span className="control__content">1</span>
                                </label>
                            ) : (
                                <label className="control" htmlFor="1">
                                    <input type="checkbox" name="topics" id="1"
                                           className={getCheckboxStyles()}/>
                                    <span className="control__content">1</span>
                                </label>
                            )}
                            {filter.rooms !== undefined &&
                            filter.rooms?.length !== 0 &&
                            filter.rooms.includes('2') ? (
                                <label className="control" htmlFor="2">
                                    <input type="checkbox" checked name="topics" id="2"
                                           className={getCheckboxStyles()}/>
                                    <span className="control__content">2</span>
                                </label>
                            ) : (
                                <label className="control" htmlFor="2">
                                    <input type="checkbox" name="topics" id="2"
                                           className={getCheckboxStyles()}/>
                                    <span className="control__content">2</span>
                                </label>
                            )}

                            {filter.rooms !== undefined &&
                            filter.rooms?.length !== 0 &&
                            filter.rooms.includes('3') ? (
                                <label className="control" htmlFor="3">
                                    <input type="checkbox" checked name="topics" id="3"
                                           className={getCheckboxStyles()}/>
                                    <span className="control__content">3</span>
                                </label>
                            ) : (
                                <label className="control" htmlFor="3">
                                    <input type="checkbox" name="topics" id="3"
                                           className={getCheckboxStyles()}/>
                                    <span className="control__content">3</span>
                                </label>
                            )}

                            {filter.rooms !== undefined &&
                            filter.rooms?.length !== 0 &&
                            filter.rooms.includes('4') ? (
                                <label className="control" htmlFor="4">
                                    <input type="checkbox" checked name="topics" id="4"
                                           className={getCheckboxStyles()}/>
                                    <span className="control__content">4+</span>
                                </label>
                            ) : (
                                <label className="control" htmlFor="4">
                                    <input type="checkbox" name="topics" id="4"
                                           className={getCheckboxStyles()}/>
                                    <span className="control__content">4+</span>
                                </label>
                            )}
                        </form>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default DropdownLink;
