import {AppStateType} from '../../redux/store';

const getIsAuthenticated = (state: AppStateType) => state.auth.isLoggedIn;

const getIsAuthModalOpen = (state: AppStateType) => state.auth.isAuthModalOpen;

const getIsUpdatePassword = (state: AppStateType) => state.auth.isUpdatePassword;

const getPassword = (state: AppStateType) => state.auth.password;

const getIsNotCorrectPassword = (state: AppStateType) => state.auth.isNotCorrectPassword;

const getToken = (state: AppStateType) => state.auth.authToken;

const enterCodeModal = (state: AppStateType) => state.auth.isCodeModalOpen;

const enterEmailCodeModal = (state: AppStateType) => state.auth.isEmailCodeModalOpen;

const getPhone = (state: AppStateType) => state.auth.phone;

const getHeaderByMobileVersion = (state: AppStateType) => state.auth.headerByMobileVersion;

const getName = (state: AppStateType) => state.auth.name;

const isConfirmedModalOpen = (state: AppStateType) => state.auth.isConfirmedModalOpen;

const getEmail = (state: AppStateType) => state.auth.email;

const authSelectors = {
    getName,
    getToken,
    getHeaderByMobileVersion,
    getIsAuthenticated,
    isConfirmedModalOpen,
    getIsAuthModalOpen,
    enterCodeModal,
    enterEmailCodeModal,
    getIsNotCorrectPassword,
    getPhone,
    getEmail,
    getPassword,
    getIsUpdatePassword
};
export default authSelectors;