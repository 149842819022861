import {AppStateType} from "../../redux/store";

export const getBalance = (state: AppStateType) =>
    state.payment.balance;

export const getPrices = (state: AppStateType) =>
    state.payment.prices;

const paymentSelectors = {
    getBalance,
    getPrices
}

export default paymentSelectors;