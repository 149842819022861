import {AppStateType} from '../../redux/store';
import {subscriptionAdapter} from './subscriptionSlice';

export const isSubscribe = (state: AppStateType) =>
    state.subscription.subscribe;

export const getSubscriptions = subscriptionAdapter.getSelectors<AppStateType>(
    state => state.subscription.subscriptions,
);

export const isLoading = (state: AppStateType) =>
    state.subscription.isLoading;

export const getTotalCount = (state: AppStateType) =>
    state.subscription.totalCount;

const subscriptionSelectors = {
    isSubscribe,
    getSubscriptions,
    isLoading,
    getTotalCount,
};

export default subscriptionSelectors;