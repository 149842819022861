import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import UpdateOffer from '../../components/UpdateOffer/UpdateOffer';
import offersOperations from '../../features/offers/offersOperations';
import offersSelectors from '../../features/offers/offersSelectors';
import offersSlice from "../../features/offers/offersSlice";

const UpdateOfferPage: React.FC = () => {

    const dispatch = useDispatch();

    type ParamsType = {
        id: string;
    };

    const params: ParamsType = useParams();

    const {id} = params;

    useEffect(() => {
        dispatch(offersOperations.getFacilities);
    }, []);

    useEffect(() => {
        dispatch(offersOperations.getOfferWithId(id));
    }, []);

    useEffect(() => {
        return () => {
            dispatch(offersSlice.actions.setOfferIdNull());
        };
    }, []);

    const offerWithId = useSelector(offersSelectors.getOfferWithId);

    return (
        <div className="background-grey">
            {offerWithId !== null && (
                <UpdateOffer id={id} offerWithId={offerWithId}/>
            )}
        </div>
    );
};
export default UpdateOfferPage;