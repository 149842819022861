import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import { store, persistor } from '../redux/store';
import axiosInstance from '../helper/axiosInstance';

axiosInstance();

const Root: React.FC = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <Route component={App} />
      </Router>
    </PersistGate>
  </Provider>
);

export default Root;
