import axios from 'axios';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {baseURL} from "../base";

axios.defaults.baseURL = 'http://api.rentbox.com.ua';

export interface GeoType {
    address: string;
    city: string;
}

const getGeolocation = createAsyncThunk(
    'geo/getGeolocation',
    async (credentials: GeoType) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        };
        try {
            const response = await axios.get(
                `${baseURL}/geolocations?address= ${credentials.address} ${credentials.city}`,
                config,
            );
            return response.data;
        } catch (error) {
            console.log('error', error);
        }
    },
);

const getCities = createAsyncThunk(
    'geo/getCities',
    async () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        };
        try {
            const response = await axios.get(`${baseURL}/cities`, config);
            return response.data;
        } catch (error) {
            console.log('error', error);
        }
    },
);

const getCityMarkers = createAsyncThunk(
    'geo/getCityMarkers',
    async (code: string) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        };
        try {
            const response = await axios.get(
                `${baseURL}/offers/geolocations?city=${code}`,
                config,
            );
            return response.data;
        } catch (error) {
            console.log('error', error);
        }
    },
);

const operations = {
    getGeolocation,
    getCities,
    getCityMarkers,
};

export default operations;
