import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import subscriptionOperation, {SubscriptionsType} from './subscriptionOperation';
import {toast} from 'react-toastify';
import i18n from 'i18next';

export const subscriptionAdapter = createEntityAdapter<SubscriptionsType>();

interface InitialState {
    subscribe: any,
    subscriptions: any;
    isLoading: boolean;
    totalCount: {
        elements: number,
        pages: number,
    }
}

const initialState: InitialState = {
    subscribe: null,
    subscriptions: subscriptionAdapter.getInitialState(),
    isLoading: false,
    totalCount: {
        elements: 0,
        pages: 0,
    },
};

const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState,
    reducers: {
        clearSubscriptions: (state, {payload}) => {
            subscriptionAdapter.removeAll(state.subscriptions);
        },
    },
    extraReducers: builder => {
        builder.addCase(subscriptionOperation.subscribe.rejected,
            (state, {payload}) => {
            },
        );
        builder.addCase(subscriptionOperation.subscribe.fulfilled,
            (state, {payload}) => {
                toast.success(i18n.t('notification.subscribe'));
                state.subscribe = payload;
            },
        );
        builder.addCase(subscriptionOperation.getSubscriptions.pending,
            (state, action) => {
                state.isLoading = true;
            },
        );
        builder.addCase(subscriptionOperation.getSubscriptions.rejected,
            (state, action) => {
                state.isLoading = false;
            },
        );
        builder.addCase(subscriptionOperation.subscriptionAcceptances.rejected,
            (state, action) => {
                toast.success(i18n.t('subscriptionsPage.toast.notSent'));
            },
        );
        builder.addCase(subscriptionOperation.subscriptionAcceptances.fulfilled,
            (state, action) => {
                toast.success(i18n.t('subscriptionsPage.toast.sent'));
            },
        );
        builder.addCase(subscriptionOperation.getSubscriptions.fulfilled,
            (state, action) => {
                if (action.payload !== undefined) {
                    subscriptionAdapter.setAll(state.subscriptions, action.payload.elements);
                    state.totalCount.elements = action.payload.totalElements;
                    state.totalCount.pages = action.payload.totalPages;
                    state.isLoading = false;
                }
            },
        );
    },
});

export default subscriptionSlice;