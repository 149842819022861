import axios from 'axios';
import {createBrowserHistory} from 'history';
import {toast} from 'react-toastify';
import {store} from '../redux/store';
import i18n from "i18next";

import '../styles/base.scss';

export const history = createBrowserHistory();

const axiosInstance = () => {

    axios.interceptors.request.use(
        (request: any) => {
            if (
                request.url.includes('acceptance') ||
                request.url.includes('contact') ||
                request.url.includes('subscription') ||
                request.url.includes('address') ||
                request.url.includes('payment-profile') ||
                request.url.includes('acceptances') ||
                request.url.includes('notification') ||
                request.url.includes('user') ||
                request.url.includes('acceptance-creator') ||
                request.url.includes('offer-creator') ||
                request.method === 'post' ||
                request.method === 'put' ||
                request.method === 'delete' ||
                request.url.includes('photos')
            ) {
                request.headers.Authorization = store.getState().auth.authToken;
            }

            return request;
        },
        error => {
            return Promise.reject(error);
        },
    );

    axios.interceptors.response.use(
        response => {
            return new Promise((resolve) => {
                resolve(response);
            });
        },
        error => {
            if (!error.response) {
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }
            if (error.response.status === 401 || error.response.status === 403) {
                toast.error(i18n.t('notification.userError'));
            }
            if (error.response.status === 429) {
                toast.warning(i18n.t('notification.againLater'));
            }
            if (error.response.status === 400) {
                toast.error(i18n.t('notification.incorrectData'));
            }
            if (
                error.response.status === 404 &&
                error.response.config.method === 'get'
            ) {
                history.push('/notfound');
            }
            if (error.response.status === 500) {
                toast.warning(i18n.t('notification.serverError'));
            } else {
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }
        },
    );
};

export default axiosInstance;
