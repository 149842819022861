import React from 'react';
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

import number from '../../assets/icons/notFound/404.svg';
import picture from '../../assets/icons/notFound/picture.svg';

import notFoundStyles from '../NotFound/NotFound.module.scss';


const NotFound = () => {

    const history = useHistory();

    const {t} = useTranslation();

    return (
        <>
            <div className={notFoundStyles.main}>
                <div className={notFoundStyles.photo_block}>
                    <img src={number} alt="number" className={notFoundStyles.number}/>
                    <img src={picture} alt="picture" className={notFoundStyles.picture}/>
                </div>
                <div className={notFoundStyles.text_block}>
                    <h1 className={notFoundStyles.text}>
                        {t('notFound.message')}
                    </h1>
                    <button className={notFoundStyles.redirect} onClick={() => history.push('/')}>
                        {t('notFound.btn')}
                    </button>
                </div>
            </div>
        </>
    );
}

export default NotFound;
