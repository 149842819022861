import {useEffect, useRef, useState} from "react";

const usePrevScrollSticky = () => {

    const [isSticky, setIsSticky] = useState(false);
    const prevScrollY = useRef<number | null>(null);

    useEffect(() => {
        const handleScroll = () => {
            if (prevScrollY.current !== null) {
                const isScrollingUp = window.scrollY < prevScrollY.current;
                setIsSticky(isScrollingUp);
            }

            prevScrollY.current = window.scrollY;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return isSticky;
};

export default usePrevScrollSticky;