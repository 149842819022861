import axios from 'axios';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {EmailTypeVerification} from '../auth/authOperations';
import {baseURL} from '../base';

const getUserProfile = createAsyncThunk('user/profile', async () => {
    const response = await axios.get(`${baseURL}/user`);
    return response;
});

export type ProfileType = {
    name: string,
    lastName: string,
    description: string
}

const updateUser = createAsyncThunk('user/updateName', async (credentials: {
    id: string | number,
    profile: ProfileType
}) => {
    const response = await axios.put(`${baseURL}/users/${credentials.id}`, credentials.profile);
    return response.data;
});

type UserOffersType = {
    page: string | number,
    size: string | number,
    hasAcc?: boolean
}

const getUserOffers = createAsyncThunk(
    'user/userOffers',
    async ({page, size, hasAcc = false}: UserOffersType) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        };
        const response = await axios.get(`${baseURL}/user/offers?page=${page}&size=${size}&sort=createdOn,desc&hasAcc=${hasAcc}`, config);
        return response;
    },
);

const getAcceptancesCountToUserOffer = createAsyncThunk(
    'user/acceptancesCount',
    async () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        };
        const response = await axios.get(`${baseURL}/user/offers/acc-count`, config);
        return response;
    },
);

const uploadUserPhoto = createAsyncThunk(
    'user/uploadUserPhoto',
    async (credentials: {id: string; photos: Array<any>}) => {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: '*/*',
                'Cache-Control': 'no-cache',
            },
        };
        const formData = new FormData();
        credentials.photos.map((photo: any) => formData.append('photos', photo));
        const response = await axios.post(
            `${baseURL}/users/${credentials.id}/photos`,
            formData,
            config,
        );

        return response;
    },
);

const reportRealtor = createAsyncThunk(
    'user/reportRieltor',
    async (id: string | number) => {
        const response = await axios.put(`${baseURL}/complaints?userId=${id}`);
        return response;
    },
);

const deleteOffer = createAsyncThunk('offers/deleteOffer', async (id: any) => {
    const response = await axios.delete(`${baseURL}/offers/${id}`);
    return id;
});

const setOfferIdState = createAsyncThunk(
    'user/setOfferIdState',
    async (credentials: {offerId: string; state: string}) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        };
        const response = await axios.put(`${baseURL}/offers/${credentials.offerId}/state?state=${credentials.state}`, config);
        return response.data;
    },
);

const changeUserEmail = createAsyncThunk(
    'user/changeUserEmail',
    async (email: string) => {
        try {
            const response = await axios.post(
                `${baseURL}/security-user/email/code?email=${email}`,
            );
            return {email};
        } catch (error) {
            console.log('error', error);
        }
    },
);

const codeToChangeEmail = createAsyncThunk(
    'auth/verificationEmail',
    async (inputs: EmailTypeVerification) => {
        const response = (await axios.put(
            `${baseURL}/security-user/email`,
            inputs,
        ));
        return response.data;
    },
);

const changeUserPhone = createAsyncThunk(
    'user/changeUserPhone',
    async (phone: string) => {
        try {
            const response = await axios.post(
                `${baseURL}/security-user/phone/code?phone=${encodeURIComponent(phone)}`,
            );
            return {phone};
        } catch (error) {
            console.log('error', error);
        }
    },
);

type PhoneTypeVerification = {
    code: string;
    phone: string;
}
const codeToChangePhone = createAsyncThunk(
    'auth/codeToChangePhone',
    async (inputs: PhoneTypeVerification) => {
        const response = (await axios.put(
            `${baseURL}/security-user/phone`,
            inputs,
        ));

        return response.data;
    },
);

const operations = {
    updateUser,
    changeUserPhone,
    codeToChangePhone,
    codeToChangeEmail,
    changeUserEmail,
    getAcceptancesCountToUserOffer,
    setOfferIdState,
    uploadUserPhoto,
    reportRealtor,
    getUserProfile,
    getUserOffers,
    deleteOffer,
};

export default operations;