import React, {ChangeEvent, useCallback, useEffect, useState} from 'react';
import Select from 'react-select';
import offersSlice from '../../../features/offers/offersSlice';
import {useDispatch, useSelector} from 'react-redux';
import geolocationOperations, {GeoType} from '../../../features/geolocation/geolocationOperations';
import _ from 'lodash';
import geolocationSelectors from '../../../features/geolocation/geolocationSelectors';
import {GetGeolocation} from '../../../models/generalTypes';
import {useTranslation} from 'react-i18next';
import {FieldValues, UseFormRegister, UseFormSetError, UseFormSetValue, UseFormWatch} from 'react-hook-form';
import {FieldErrors} from 'react-hook-form/dist/types/errors';

interface Props {
    geo: string,
    setGeolocation: (arg0: string) => never | void,
    register: UseFormRegister<FieldValues>,
    errors: FieldErrors,
    defaultAddress?: string,
    defaultCity?: string,
    setValue?: UseFormSetValue<FieldValues>,
    setError?: UseFormSetError<FieldValues>,
    watch?: UseFormWatch<FieldValues>
}

const AddressBlock = ({
                          setGeolocation,
                          geo,
                          register,
                          errors,
                          defaultAddress = '',
                          defaultCity = 'Kyiv',
                          setValue,
                      }: Props) => {

    const dispatch = useDispatch();

    const {t} = useTranslation();

    const minimumLengthValidity = 5;

    const inputAddress: Array<GetGeolocation> = useSelector(
        geolocationSelectors.getAddress,
    );

    const fetchedCities = useSelector(
        geolocationSelectors.getFetchedCities,
    ) as Array<any>;

    const [city, setCity] = useState({code: 'KBP', name: 'Kyiv'});

    const customStyles = {
        option: (provided: any, state: any) => ({
            ...provided,
            color: 'black',
            backgroundColor: 'white',
            padding: '5px 5px 10px 20px',
            '&:hover': {
                backgroundColor: '#d6d6d6',
            },
        }),
        control: (baseStyles: any, state: any) => ({
            ...baseStyles,
            borderColor: state.isFocused ? 'green' : 'grey',
            height: '60px',
            paddingLeft: 10,
            '&:hover': {
                border: '2px solid grey',
            },
            '&:focus': {
                border: '2px solid green',
            },
            '&:active': {
                border: '2px solid green',
            },
        }),
    };

    const handleChangeCity = (e: any) => {
        dispatch(
            offersSlice.actions.setCityWhenCreateOffer({
                code: e.latitude,
                name: e.name,
            }),
        );
        setCity({code: e.latitude, name: e.name});
    };

    const [debouncedState, setDebouncedState] = useState(defaultAddress);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === 'address') {
            setGeolocation(e.target.value);
            if (geo.length > minimumLengthValidity) {
                debounced(e.target.value);
            }
        }
    };

    const debounced = useCallback(
        _.debounce((_searchVal: string) => {
            setDebouncedState(_searchVal);
        }, 1000),
        [debouncedState],
    );

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    useEffect(() => {
        if (geo !== '' && geo.length > minimumLengthValidity) {
            setIsDropdownOpen(true);
        } else {
            setIsDropdownOpen(false);
        }
    }, [geo]);

    useEffect(() => {
        setCity({code: '', name: defaultCity});
    }, []);

    useEffect(() => {
        defaultAddress !== '' && setGeolocation(defaultAddress);
    }, []);

    useEffect(() => {
        if (setValue) {
            defaultAddress !== '' && setValue('address', defaultAddress);
        }
    }, [setValue]);

    const credentialObject = {
        address: debouncedState,
        city: city.name,
    } as GeoType;

    const addressPattern = /^[a-zA-Z0-9\u0400-\u04FF\s,'-]*$/;

    useEffect(() => {
        if (addressPattern.test(geo)) {
            dispatch(geolocationOperations.getGeolocation(credentialObject));
        }
    }, [debouncedState, city]);

    const validateAddress = (): boolean | string => {
        if (Object.keys(errors).length === 0) {
            return true;
        }
        return inputAddress.filter(item => item.formattedAddress === geo).length !== 0;
    };

    return (
        <div>
            <h4>{t('createNewOfferPage.address.title')}</h4>

            <Select
                placeholder={t('createNewOfferPage.address.city.title')}
                onChange={handleChangeCity}
                name="city"
                id={city.code}
                value={city}
                getOptionLabel={option => {
                    return t(`city.${option.name}`);
                }}
                getOptionValue={option => {
                    return option.code;
                }}
                options={fetchedCities}
                styles={customStyles}
            />

            <div className="mt-3">
                <input
                    className={
                        !validateAddress()
                            ? 'error'
                            : 'address'
                    }
                    placeholder={t('createNewOfferPage.address.address')}
                    {...register('address', {
                        required: t('formErrors.required').toString(),
                        validate: validateAddress,
                        min: {
                            value: minimumLengthValidity,
                            message: t('formErrors.minLength').toString(),
                        },
                    })}
                    onChange={handleChange}
                    value={geo}
                    name="address"
                    type="text"
                    autoComplete="off"
                />
                {!validateAddress() ? (
                    <p className="error-message">{t('formErrors.selectFromList')}</p>
                ) : (
                    <p className="error-message"></p>
                )}
                <div className="dropdown">
                    <ul
                        className={`dropdown-menu ${isDropdownOpen ? 'show' : ''} dropdown_address rounded-3`}
                        onClick={() => setIsDropdownOpen(false)}
                    >
                        {inputAddress.length !== 0 ? inputAddress.map((input, index) => (
                                <li
                                    key={index}
                                    onClick={async () => {
                                        await setGeolocation(input.formattedAddress);
                                        setIsDropdownOpen(false);
                                    }}
                                    className="mb-1 pl"
                                >
                                    {input.formattedAddress}</li>
                            ))
                            :
                            <li key="1" className="mb-0">{t('createNewOfferPage.address.search')}.</li>
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default AddressBlock;