import React, {Dispatch, SetStateAction, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {ErrorMessage} from '@hookform/error-message';
import Input from 'react-phone-number-input/input';
import userOperations from '../../../features/user/userOperations';
import userSelectors from '../../../features/user/userSelectors';
import {ResponseRefreshUserSuccessActionPayloadType} from '../../../models/generalTypes';
import {emailPattern, phonePattern} from '../../../pattern/formPattern';
import LoaderButtonFormService from '../../../common/Button/loaderButtonFormService/LoaderButtonFormService';

import LoaderColor from '../../../common/Button/enum/LoaderColor';

import './EmailPhoneChangerModal.scss';

interface Props {
    children?: React.ReactNode;
    toggleModal: Dispatch<SetStateAction<boolean>>;
    isModalOpen: boolean;
    label: string;
}

const EmailPhoneChangerModal: React.FC<Props> = ({
                                                     toggleModal,
                                                     isModalOpen,
                                                     label,
                                                 }) => {

    const {t} = useTranslation();

    const user = useSelector(
        userSelectors.getUserProfile,
    ) as ResponseRefreshUserSuccessActionPayloadType;

    const [inputs, setInputs] = useState({
        phone: user.phone,
        email: user.email,
        code: '',
        name: `${user.name}`,
        lastName: `${user.lastName}`,
        description: `${user.description}`,
    });
    const [submitted, setSubmitted] = useState(false);

    const {phone, name, code, lastName, description, email} = inputs;

    const {
        register,
        formState: {errors},
        handleSubmit,
        clearErrors,
    } = useForm();

    const dispatch = useDispatch();

    const changeHandler = (e: any) => {
        const {name: inputName, value} = e.target;
        setInputs({...inputs, [inputName]: value});
        if (
            phonePattern.test(
                e.target.value,
            )
        ) {
            clearErrors('phone');
        }
    };

    const submitHandler = async () => {
        setSubmitted(true);
        {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            label === 'email'
                ? await dispatch(userOperations.changeUserEmail(inputs.email))
                : label === 'phone'
                    ? await dispatch(userOperations.changeUserPhone(inputs.phone))
                    : label === 'profile'
                        ?
                        (await dispatch(
                            userOperations.updateUser({
                                id: user.id,
                                profile: {lastName, name, description},
                            }),
                        ) && toggleModal(!isModalOpen))
                        : null;
        }
    };

    return (
        <div className="auth_form">
            <form onSubmit={handleSubmit(submitHandler)}>
                <div onChange={changeHandler} className="">
                    {label === 'email' ? (
                        <div>
                            <p className="auth-subtitle">
                                {t('userProfile.enterNewEmail')}
                            </p>
                            <p className="auth-label auth-label__pad">
                                {t('userProfile.email')}
                            </p>
                            <input
                                {...register('email', {
                                    required: t('formErrors.required').toString(),
                                    pattern: {
                                        value: emailPattern,
                                        message: t('formErrors.pattern'),
                                    },
                                })}
                                name="email"
                                type="text"
                                value={email}
                                className={errors.email ? 'inputNumber_error' : 'inputNumber'}
                                onChange={() => changeHandler}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="email"
                                render={({message}) => (
                                    <p className="error-message">{message}</p>
                                )}
                            />
                        </div>
                    ) : label === 'phone' ? (
                        <div>
                            <p className="auth-subtitle">
                                {t('userProfile.enterNewPhone')}
                            </p>
                            <p className="auth-label">{t('userProfile.phone')}</p>
                            <Input
                                {...register('phone', {
                                    pattern: {
                                        value: phonePattern,
                                        message: t('formErrors.pattern'),
                                    },
                                    required: t('formErrors.required').toString(),
                                })}
                                className={errors.phone ? 'inputNumber_error' : 'inputNumber'}
                                placeholder="+380 99 333 3333"
                                value={phone}
                                name="phone"
                                onChange={() => changeHandler}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="phone"
                                render={({message}) => (
                                    <p className="error-message">{message}</p>
                                )}
                            />
                        </div>
                    ) : label === 'profile' ? (
                        <div>
                            <p className="auth-subtitle">
                                {t('userProfile.changeProfile')}
                            </p>
                            <p className="auth-label auth-label__pad">
                                {t('userProfile.Name')}
                            </p>
                            <input
                                {...register('name', {
                                    required: t('formErrors.required').toString(),
                                })}
                                name="name"
                                type="text"
                                defaultValue={user.name}
                                className={errors.name ? 'inputNumber_error' : 'inputNumber'}
                                onChange={() => changeHandler}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="name"
                                render={({message}) => (
                                    <p className="error-message">{message}</p>
                                )}
                            />
                            <p className="auth-label auth-label__pad">
                                {t('userProfile.LastName')}
                            </p>
                            <input
                                {...register('lastName', {})}
                                name="lastName"
                                type="text"
                                defaultValue={user.lastName}
                                className={errors.lastName ? 'inputNumber_error' : 'inputNumber'}
                                onChange={() => changeHandler}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="lastName"
                                render={({message}) => (
                                    <p className="error-message">{message}</p>
                                )}
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                </div>

                <div className="form-group1">
                    <LoaderButtonFormService buttonContent={t('userProfile.Submit')}
                                             buttonClassName={'user_profile_form'}
                                             submit={handleSubmit(submitHandler)}
                                             color={LoaderColor.White} />
                </div>
            </form>
        </div>
    );
};

export default EmailPhoneChangerModal;