import React, {useEffect, useState} from 'react';
import Lightbox from 'react-image-lightbox';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useJsApiLoader, GoogleMap, Marker} from '@react-google-maps/api';
import {OfferElementsType} from '../../models/generalTypes';
import CreateAcceptance from '../CreateAcceptance/CreateAcceptance';
import {Box, ImageList, ImageListItem} from '@mui/material';
import useWindowSize from '../../hooks/useWindowSize';
import BasicModal from '../BasicModal/BasicModal';
import userOperations from '../../features/user/userOperations';
import Slider from 'react-slick';

import PhotoSize from '../Acceptances/enums/PhotoSize';
import WindowSize from '../../enums/WindowSize';

import tickGreen from '../../assets/icons/tickGreen.svg';
import close from '../../assets/icons/close.svg';
import marker from '../../assets/icons/marker.svg';
import camera from '../../assets/icons/camera-black.svg';
import plug from '../../assets/photos/plug.png';
import paw from '../../assets/icons/paw.svg';
import security from '../../assets/icons/security.svg';
import calendar from '../../assets/icons/offer-calendar.svg';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-image-lightbox/style.css';
import s from './Offer.module.scss';

type PropType = {
    offer: OfferElementsType;
    isUserOffer?: OfferElementsType | undefined;
};

const Offer: React.FC<PropType> = ({isUserOffer, offer}) => {

    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyAXdo83AHcu-6rsJcmmmSvXFuylgZEVpn0',
    });

    const {t} = useTranslation();

    const [photoIndex, setPhotoIndex] = useState(0);

    const [isOpen, setIsOpen] = useState(false);

    const [isReportModal, toggleReportModal] = useState(false);

    // const offerPhotos = useSelector(offersSelectors.uploadedPhotosToUserOffer);
    //
    // // useEffect(() => {
    // //     if (offerPhotos === '') {
    // //         window.location.reload();
    // //     }
    // // }, [offerPhotos]);

    const parseIsoDate = (isoString: string | Date, time?: boolean) => {
        const date = new Date(isoString as string);
        const year = date.getFullYear();
        let month = (date.getMonth() + 1) as string | number;
        let dt = date.getDate() as string | number;
        let minutes = date.getMinutes() as string | number;
        let hours = date.getHours() as string | number;

        if (dt < 10) {
            dt = `0${dt}`;
        }
        if (month < 10) {
            month = `0${month}`;
        }
        if (hours < 10) {
            hours = `0${hours}`;
        }
        if (minutes < 10) {
            minutes = `0${minutes}`;
        }

        if (time) {
            return `${dt}.${month}.${year} ${hours}:${minutes}`;
        } else return `${dt}.${month}.${year}`;
    };

    type PhotoType = {
        img: string
        title: string,
        rows: number,
        cols: number
    }

    const getPhotosGallery = () => {

        const photos = offer.photos.filter(photo => photo.photoSize === PhotoSize.Large).map(item => (item));

        let first: PhotoType;
        let second: PhotoType;
        let third: PhotoType;
        let fourth: PhotoType;
        let fifth: PhotoType;

        if (photos[0] !== undefined) {
            first = {img: photos[0].photoLocation, title: '1', rows: 4, cols: 2};
        } else first = {img: plug, title: '1', rows: 4, cols: 2};

        if (photos[1] !== undefined) {
            second = {img: photos[1].photoLocation, title: '2', rows: 1, cols: 1};
        } else second = {img: plug, title: '2', rows: 1, cols: 1};

        if (photos[2] !== undefined) {
            third = {img: photos[2].photoLocation, title: '3', rows: 1, cols: 1};
        } else third = {img: plug, title: '3', rows: 1, cols: 1};

        if (photos[3] !== undefined) {
            fourth = {img: photos[3].photoLocation, title: '4', rows: 1, cols: 1};
        } else fourth = {img: plug, title: '4', rows: 1, cols: 1};

        if (photos[4] !== undefined) {
            fifth = {img: photos[4].photoLocation, title: '5', rows: 1, cols: 1};
        } else fifth = {img: plug, title: '5', rows: 1, cols: 1};

        return Array.from([first, second, third, fourth, fifth]);
    };

    const galleryPhotos = getPhotosGallery();

    function srcset(image: string, size: number, rows = 1, cols = 1) {
        return {
            src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
            srcSet: `${image}?w=${size * cols}&h=${
                size * rows
            }&fit=crop&auto=format&dpr=2 2x`,
        };
    }

    const getPhotoId = (e: any) => {
        if (e.target.id !== '') {
            if (+e.target.id >= 0) {
                return +e.target.id + 1;
            }
            return +e.target.id;
        } else return 0;
    };

    useEffect(() => {
        if (!isOpen) {
            setPhotoIndex(0);
        }
    }, [isOpen]);

    const photoCount = offer.photos.filter(photo => photo.photoSize === PhotoSize.Large).length;

    const localizationFormatPhotos = () => {
        if (photoCount === 1 || photoCount === 31) {
            return t('offer.photo.one');
        } else if (photoCount > 1 && photoCount <= 4 || photoCount > 31 && photoCount <= 34) {
            return t('offer.photo.two');
        } else if (photoCount > 4 || photoCount > 34) {
            return t('offer.photo.three');
        }
    };

    const {isScreenResolutionMobile} = useWindowSize();

    const settings = {
        className: 'center',
        centerPadding: '60px',
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        dotsClass: 'slick-dots',
    };

    const getMapContainerStyles = () => isScreenResolutionMobile ? {width: '100%', height: '339px'} : {
        width: '688px',
        height: '339px',
    };

    const dispatch = useDispatch();

    const report = async () => {
        await dispatch(userOperations.reportRealtor(offer.userId));
        toggleReportModal(!isReportModal);
    };

    return (
        <div className={s.container_offer}>
            <div className={`${s.flex_row} ${s.header}`}>
                <span className={s.publication}>
                    {t('offer.published')} {parseIsoDate(offer.createdOn, true)}
                </span>
                <button className={s.report}
                        onClick={() => toggleReportModal(!isReportModal)}
                >
                    {t('offer.complain')}
                </button>
            </div>

            <BasicModal isModalOpen={isReportModal}
                        toggleModal={toggleReportModal}
                        header={t('offer.reportModal.title')}
                        question={t('offer.reportModal.question')}
                        leftButton={t('offer.reportModal.btn.cancel')}
                        rightButton={t('offer.reportModal.btn.confirm')}
                        onClick={report}
            />

            {isScreenResolutionMobile ?
                (galleryPhotos.filter((photo: PhotoType) => photo.img !== plug).length !== 0 ?
                        <Slider {...settings} className={s.sliders}>
                            {galleryPhotos
                                .filter((photo: PhotoType) => photo.img !== plug)
                                .map((photo) => (
                                    <div className={''} key={photo.title}
                                         onClick={async (e) => {
                                             await setPhotoIndex(getPhotoId(e));
                                             if (photo.img !== plug) {
                                                 setIsOpen(true);
                                             }
                                         }}>
                                        <img
                                            className={s.slider_photo}
                                            alt="photo"
                                            src={photo.img}
                                        />
                                    </div>
                                ))}
                        </Slider> :
                        <div>
                            <img
                                className={s.slider_photo}
                                alt="photo"
                                src={plug}
                            />
                        </div>
                )
                :
                <div className={s.photo}>
                    <div className={s.photo_count_button}>
                        <div className={`${s.rows} ${s.photo_count_size}`}
                             onClick={() => setIsOpen(true)}
                        >
                            <img src={camera} alt="camera" />
                            <span className={s.photo_count_title}>
                            {photoCount} {localizationFormatPhotos()}
                        </span>
                        </div>
                    </div>
                    <Box sx={{display: 'flex', width: '100%'}}>
                        <ImageList
                            sx={{width: '50%', height: 400, marginRight: 1, overflow: 'hidden'}}
                            cols={1}
                            rowHeight={408}
                        >
                            {galleryPhotos.length !== 0 &&
                              <ImageListItem
                                onClick={async (e) => {
                                    await setPhotoIndex(getPhotoId(e));
                                    if (galleryPhotos[0].img !== plug) {
                                        setIsOpen(true);
                                    }
                                }}>
                                <img
                                    {...srcset(galleryPhotos[0].img, 121, galleryPhotos[0].rows, galleryPhotos[0].cols)}
                                    alt={galleryPhotos[0].title}
                                    loading="lazy"
                                    style={{height: '100%', objectFit: 'cover'}}
                                />
                              </ImageListItem>
                            }
                        </ImageList>

                        <ImageList
                            sx={{width: '50%', height: 400, overflow: 'hidden'}}
                            cols={2}
                            rowHeight={200}
                        >
                            {galleryPhotos.length !== 0 && galleryPhotos.slice(1).map((photo, index) => (
                                <ImageListItem
                                    sx={{marginBottom: 0}}
                                    cols={photo.cols}
                                    rows={photo.rows}
                                    key={index}
                                    onClick={async (e) => {
                                        if (photo.img !== plug) {
                                            await setPhotoIndex(getPhotoId(e));
                                            setIsOpen(true);
                                        }
                                    }}>
                                    <img
                                        {...srcset(photo.img, 121, photo.rows, photo.cols)}
                                        alt={photo.title}
                                        id={index.toString()}
                                        style={{height: '100%', objectFit: 'cover'}}
                                        loading="lazy"
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </Box>
                </div>}

            {isOpen && offer.photos.length !== 0 && (
                <Lightbox
                    mainSrc={
                        offer.photos.filter(photo => photo.photoSize === PhotoSize.Large)[
                            photoIndex
                            ].photoLocation
                    }
                    nextSrc={
                        offer.photos.filter(photo => photo.photoSize === PhotoSize.Large)[
                            photoIndex
                            ].photoLocation[
                        (photoIndex + 1) %
                        offer.photos.filter(photo => photo.photoSize === PhotoSize.Large)
                            .length
                            ]
                    }
                    prevSrc={
                        offer.photos.filter(photo => photo.photoSize === PhotoSize.Large)[
                            photoIndex
                            ].photoLocation[
                        (photoIndex +
                            offer.photos.filter(photo => photo.photoSize === PhotoSize.Large)
                                .length -
                            1) %
                        offer.photos.filter(photo => photo.photoSize === PhotoSize.Large)
                            .length
                            ]
                    }
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex(
                            (photoIndex +
                                offer.photos.filter(photo => photo.photoSize === PhotoSize.Large)
                                    .length -
                                1) %
                            offer.photos.filter(photo => photo.photoSize === PhotoSize.Large)
                                .length,
                        )
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex(
                            (photoIndex + 1) %
                            offer.photos.filter(photo => photo.photoSize === PhotoSize.Large)
                                .length,
                        )
                    }
                />
            )}

            <div className={s.main_row}>

                {isScreenResolutionMobile &&
                  <div className={s.create_acceptance}>
                    <div className="mt-3">
                      <p className={s.creator_name}>{offer.creator.name}</p>
                      <p className={s.createdOn}>
                          {t('offer.acceptance.date')} {parseIsoDate(offer.creator.createdOn)}
                      </p>
                      <p className={s.creator_price}>
                          {offer.price} {offer.currency}/{t('offer.acceptance.price')}
                      </p>
                      <div className={s.flex_acceptance}>
                        <p className={s.deposit}>
                          <img src={paw} alt="paw" />
                          <span className={s.ml}>
                                        {offer.petDeposit} {offer.currency}
                                    </span>
                        </p>
                        <p className={s.deposit}>
                          <img src={security} alt="security" />
                          <span className={s.ml}>
                                        {offer.securityDeposit} {offer.currency}
                                    </span>
                        </p>
                      </div>
                    </div>
                    <CreateAcceptance offerId={offer.id} offer={offer} />
                  </div>
                }

                <div className={s.offer}>
                    {offer.geolocation.address && (
                        <h2 className={s.address}>{offer.geolocation.address} </h2>
                    )}
                    <div className={s.row}>
                        <div className={s.rooms}>
                            {offer.rooms} {t('offer.room')}
                            <span className={s.ml}>
                                {offer.totalArea} / {offer.livingArea} / {offer.kitchenArea} {t('offer.unit')}
                            </span>
                        </div>
                        <div className={s.rooms}>
                            {t('offer.floor')}
                            <span className={s.ml}>
                                    {offer.floor} / {offer.floors}
                                </span>
                        </div>
                    </div>

                    <div className={s.line}></div>

                    <h2 className={s.section}>{t('offer.description.name')}</h2>
                    <div className={s.description}>
                        {offer.description}
                    </div>
                    <div className={`${s.square} ${s.row}`}>
                        <span className={s.name}>{t('offer.description.total')}</span>
                        <span className={s.between}></span>
                        <span className={s.value}> {offer.totalArea} {t('offer.description.unit')} </span>
                    </div>

                    <div className={`${s.square} ${s.row}`}>
                        <span className={s.name}>{t('offer.description.living')}</span>
                        <span className={s.between}></span>
                        <span className={s.value}> {offer.livingArea} {t('offer.description.unit')}</span>
                    </div>

                    <div className={`${s.square} ${s.row}`}>
                        <span className={s.name}>{t('offer.description.kitchen')}</span>
                        <span className={s.between}></span>
                        <span className={s.value}> {offer.kitchenArea} {t('offer.description.unit')}</span>
                    </div>

                    <div className={s.line}></div>

                    <h2 className={s.section}>{t('offer.date.name')}</h2>
                    <div className={s.date}>
                        {t('offer.date.free')}
                        <span className={s.calendar}>
                                <img src={calendar} alt="calendar" />
                            </span>
                        <span className={s.date_value}>
                                {parseIsoDate(offer.availableFrom)}
                            </span>
                    </div>

                    <div className={s.line}></div>

                    <div className={s.facility}>
                        <h2 className={s.section}>{t('offer.facility.name')}</h2>
                        {offer.facilities !== undefined &&
                            offer.facilities.length !== 0 &&
                            offer.facilities.map(fac => (
                                <label key={fac} className={s.column} htmlFor={fac}>
                                    <div className={s.row}>
                                        <img src={tickGreen} className={s.tick} alt="tick" />
                                        <span className={s.facility_name}>
                                                {t(`offer.${fac}`)}
                                            </span>
                                    </div>
                                </label>
                            ))}
                    </div>

                    <div className={s.line_facility}></div>

                    <div>
                        <h2 className={s.section}>{t('offer.rules.name')}</h2>
                        <div className={s.row}>
                            {offer.animalPermission ? <img src={tickGreen} alt="tick" className={s.tick} /> :
                                <img src={close} alt="close" className={s.close} />}
                            <span className={s.pet}>
                                {t('offer.rules.pet')}
                            </span>
                        </div>
                    </div>

                    <div className={s.line}></div>

                    <div>
                        {isLoaded &&
                          <GoogleMap
                            mapContainerStyle={getMapContainerStyles()}
                            center={{
                                lat: offer.geolocation.latitude,
                                lng: offer.geolocation.longitude,
                            }}
                            zoom={11}
                            options={{scrollwheel: true}}
                          >
                            <Marker
                              key={offer.id}
                              position={{
                                  lat: offer.geolocation.latitude,
                                  lng: offer.geolocation.longitude,
                              }}
                              icon={marker}
                              animation={google.maps.Animation.DROP}
                            />

                          </GoogleMap>
                        }
                    </div>
                    <br />
                </div>

                {!isScreenResolutionMobile &&
                  <div className={s.acs}>
                    <div className={s.creator}>
                      <div className={s.indent}>
                        <p className={s.creator_name}>{offer.creator.name}</p>
                        <p className={s.createdOn}>
                            {t('offer.acceptance.date')} {parseIsoDate(offer.creator.createdOn)}
                        </p>
                        <p className={s.creator_price}>
                            {offer.price} {offer.currency}/{t('offer.acceptance.price')}
                        </p>
                        <div className={s.flex_acceptance}>
                          <p className={s.deposit}>
                            <img src={paw} alt="paw" />
                            <span className={s.ml}>
                                            {offer.petDeposit}
                                        </span>
                          </p>
                          <p className={s.deposit}>
                            <img src={security} alt="security" />
                            <span className={s.ml}>
                                            {offer.securityDeposit}
                                        </span>
                          </p>
                        </div>
                      </div>
                      <hr />
                    </div>
                    <CreateAcceptance offerId={offer.id} offer={offer} />
                  </div>
                }
            </div>
        </div>
    );
};

export default Offer;