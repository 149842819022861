import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import acceptanceOperations from "../../../../features/acceptance/acceptanceOperations";
import acceptanceSlice from "../../../../features/acceptance/acceptanceSlice";
import {AcceptanceType} from "../../../../models/generalTypes";
import acceptanceSelectors from "../../../../features/acceptance/acceptanceSelectors";

import Sort from "../../enums/Sort";
import Status from "../../enums/Status";

import caretDown from '../../images/caretDown.svg';

import './AcceptancesModalFilter.scss';

interface Props {
    offerId: any;
    setFilterSettings: any;
    setPage: any;
}

enum SortType {
    Date = 'date',
    Price = 'price'
}

const AcceptancesModalFilter: React.FC<Props> = ({offerId, setFilterSettings, setPage}) => {

    const [isOpen, toggle] = useState(false);

    const [checked, setChecked] = useState(false);

    const {t} = useTranslation();

    const ref = React.useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();

    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            if (isOpen && ref.current && !ref.current.contains(e.target)) {
                toggle(false);
            }
        };

        document.addEventListener('mousedown', checkIfClickedOutside);

        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isOpen]);

    const offerAcceptances: Array<AcceptanceType> = useSelector(
        acceptanceSelectors.getOfferAcceptances.selectAll
    );

    const key = "acceptance_filter_" + offerId;

    const filtering = (sort: string, statuses: string) => {

        dispatch(acceptanceSlice.actions.clearOfferAcceptances(offerAcceptances));

        setFilterSettings({
            sort: sort,
            statuses: statuses
        });

        const filterSettings = {
            sort: sort,
            statuses: statuses
        }

        setPage(1);
        dispatch(
            acceptanceOperations.getOfferAcceptances({
                id: offerId,
                page: 0,
                size: 3,
                sort: filterSettings.sort,
                statuses: filterSettings.statuses
            }),
        );
    }

    const [sortType, setSortType] = useState('');

    return (
        <>
            <div className="filter">
                <div
                    className="filter__more"
                >
                    <p className="filter__name">{t('acceptancesModal.filter.checkbox')}</p>
                    <input id="checkbox" checked={checked} className="filter__checkbox" type="checkbox"
                           onChange={() => {
                               setChecked(!checked);
                               const settings = JSON.parse(localStorage.getItem(key) || "");
                               if (!checked) {
                                   filtering(settings.sort, Status.Approved)
                               } else filtering(settings.sort, Status.Waiting)
                           }}/>
                </div>
                <div ref={ref}
                     className="dropdown"
                >
                    <div className="dropdown__btn" onClick={() => toggle(!isOpen)}>
                        {sortType === SortType.Price ? t('acceptancesModal.filter.dropdown.item.price') : t('acceptancesModal.filter.dropdown.item.date')}
                        <img className="dropdown__btn-svg" src={caretDown} alt="caret"/>
                    </div>
                    {isOpen ? <div className="dropdown__content">
                        <button
                            onClick={() => {
                                setSortType(SortType.Date);
                                const settings = JSON.parse(localStorage.getItem(key) || "");
                                filtering(Sort.Created, settings.statuses);
                                toggle(false);
                            }}
                            className="dropdown__content-btn">{t('acceptancesModal.filter.dropdown.item.date')}
                        </button>
                        <button
                            onClick={() => {
                                setSortType(SortType.Price)
                                const settings = JSON.parse(localStorage.getItem(key) || "");
                                filtering(Sort.Price, settings.statuses);
                                toggle(false);
                            }}
                            className="dropdown__content-btn">{t('acceptancesModal.filter.dropdown.item.price')}
                        </button>
                    </div> : null}
                </div>
            </div>
        </>
    );
}

export default AcceptancesModalFilter;