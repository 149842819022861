enum BlockId {
    Address = 'address',
    Building = 'building',
    Flat = 'flat',
    Facilities = 'facilities',
    Rules = 'rules',
    Description = 'description',
    Rent = 'rent'
}

export default BlockId;