import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import filterSelectors from "../../../features/filter/filterSelectors";
import {FilterType, OfferElementsType} from "../../../models/generalTypes";
import filterOperations from "../../../features/filter/filterOperations";
import filterSlice from "../../../features/filter/filterSlice";
import {Type} from "../../../pages/SearchPage/SearchPage";

import Sort from "../../Acceptances/enums/Sort";

import caretDown from '../../../components/Acceptances/images/caretDown.svg';

import sortOffersStyles from './SortOffers.module.scss';

interface Props {
    totalElements: number;
    setSort: any;
    setPage: (arg: number) => any;
}

enum SortType {
    Date = 'date',
    Price = 'price'
}

const SortOffers: React.FC<Props> = ({totalElements, setSort, setPage}) => {

    const [isOpenSort, toggle] = useState(false);

    const {t} = useTranslation();

    const ref = React.useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();

    const filter = useSelector(filterSelectors.getFilter) as FilterType;

    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            if (isOpenSort && ref.current && !ref.current.contains(e.target)) {
                toggle(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);
        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isOpenSort]);

    const [sortType, setSortType] = useState('');

    const key = "offers_page_sort";

    const filteredOffers: Array<OfferElementsType> = useSelector(
        filterSelectors.getFilteredOffers,
    );

    const sortPicker = async (type: string) => {
        if (type === SortType.Date) {
            setSortType(SortType.Date);
            await setSort({sort: Sort.Created});
        } else if (type === SortType.Price) {
            setSortType(SortType.Price);
            await setSort({sort: Sort.Price});
        }
        const settings = JSON.parse(sessionStorage.getItem(key) || "");
        await dispatch(filterSlice.actions.clearFilteredOffers(filteredOffers));
        await setPage(1);
        dispatch(filterOperations.getFilteredOffers({filter, page: 0, sort: settings.sort}));
        toggle(false);
    }

    const type = useSelector(filterSelectors.getType);

    return (
        <div className={type !== Type.Looking ? sortOffersStyles.main : sortOffersStyles.main_rent}>
            <span className={sortOffersStyles.search__result}>{totalElements} {t('offersForm.resultSearch')}</span>

            <div ref={ref} className={sortOffersStyles.dropdown}>
                <div className={sortOffersStyles.dropdown__btn} onClick={() => toggle(!isOpenSort)}>
                    {sortType === SortType.Price ? t('offersForm.sortOffers.price') : t('offersForm.sortOffers.date')}
                    <img className={sortOffersStyles.dropdown__btn_svg} src={caretDown} alt="caret"/>
                </div>
                {isOpenSort ? <div className={sortOffersStyles.dropdown__content}>
                    <button
                        onClick={() => sortPicker(SortType.Date)}
                        className={sortOffersStyles.dropdown__content_btn}>{t('offersForm.sortOffers.date')}
                    </button>
                    <button
                        onClick={() => sortPicker(SortType.Price)}
                        className={sortOffersStyles.dropdown__content_btn}>{t('offersForm.sortOffers.price')}
                    </button>
                </div> : null}
            </div>
        </div>
    );
}

export default SortOffers;