import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import filterSelectors from '../../../features/filter/filterSelectors';
import filterSlice from '../../../features/filter/filterSlice';
import {useTranslation} from 'react-i18next';
import useWindowSize from '../../../hooks/useWindowSize';
import usePrevScrollSticky from '../../../hooks/usePrevScrollSticky';
import {Type} from '../../../pages/SearchPage/SearchPage';
import {FilterType, SubscriptionsFilterType} from '../../../models/generalTypes';

import closeButton from '../../../assets/icons/close-button.svg';
import tick from '../../../assets/icons/tick.svg';

import filterAppliedStyles from './FilterApplied.module.scss';

const FilterApplied = () => {

    const dispatch = useDispatch();

    const {t} = useTranslation();

    // const filter = useSelector(filterSelectors.getFilter) as FilterType;

    const filterOffers = useSelector(filterSelectors.getFilter) as FilterType;

    const filterSubscriptions = useSelector(filterSelectors.getSubscriptionFilter) as SubscriptionsFilterType;

    const [filter, setFilter] = useState() as any;

    const type = useSelector(filterSelectors.getType);

    useEffect(() => {
        if (type === Type.Rent) {
            setFilter(filterOffers);
        } else setFilter(filterSubscriptions);
    }, [type, filterSubscriptions, filterOffers]);

    const [activeFilters, setActiveFilters] = useState({} as any);

    const [totalFilterApplied, setTotalFilterApplied] = useState(0);

    useEffect(() => {
        const finalValues = {
            ...filter,
        };
        setActiveFilters(finalValues);
    }, [filter]);

    const [finishFilters, setFinishFilters] = useState(new Map);

    const castStringView = (from: string, to: string) => {
        if (from !== '' && to !== '') {
            return `${from} - ${to}`;
        } else if (from !== '' && to === '') {
            return `${t('filterModal.from')} ${from}`;
        } else if (from === '' && to !== '') {
            return `${t('filterModal.to')} ${to}`;
        }
    };

    const castRoomsView = (rooms: Array<string>) => {
        return rooms.toString().replace('4,5,6,7', '4+');
    };

    useEffect(() => {
        const objectConstruction = (obj: any) => {

            const result = {
                room: obj.rooms !== undefined && obj.rooms.length > 0 ? castRoomsView(obj.rooms) : null,
                price: type === Type.Looking ? obj.price : castStringView(obj.priceFrom, obj.priceTo),
                area: castStringView(obj.areaFrom, obj.areaTo),
                floor: castStringView(obj.floorFrom, obj.floorTo),
                buildingFloor: castStringView(obj.buildingFloorFrom, obj.buildingFloorTo),
                constructionYear: castStringView(obj.constructionYearFrom, obj.constructionYearTo),
                animalPermission: obj.animalPermission ? '+' : null,
            };

            const updValues = Object.fromEntries(
                Object.entries(result).filter(
                    n =>
                        n[0] !== '' &&
                        n[0] !== 'currency' &&
                        n[1] !== 'undefined' &&
                        n[1] !== 'filterApplied.undefined' &&
                        n[1] !== 'null' &&
                        n[1] !== undefined &&
                        n[1] !== ' ' &&
                        n[1] !== null,
                ),
            );

            const finalValues = new Map(Object.entries(updValues));

            setFinishFilters(finalValues);
        };

        objectConstruction(activeFilters);

    }, [activeFilters, t]);

    type ResultType = {
        name: string,
        value: string
    }

    const [result, setResult] = useState(Array<ResultType>());

    useEffect(() => {
        const arr = Array.from(finishFilters, ([name, value]) => ({name, value}));
        setResult(arr);
        setTotalFilterApplied(arr.length);
    }, [finishFilters]);

    const filterRemove = (str: string) => {
        if (type === Type.Rent) {
            switch (str) {
                case 'price':
                    dispatch(filterSlice.actions.removePriceFrom(filter.priceFrom as string));
                    dispatch(filterSlice.actions.removePriceTo(filter.priceTo as string));
                    break;
                case 'area':
                    dispatch(filterSlice.actions.removeAreaFrom(filter.areaFrom as string));
                    dispatch(filterSlice.actions.removeAreaTo(filter.areaTo as string));
                    break;
                case 'floor':
                    dispatch(filterSlice.actions.removeFloorFrom(filter.floorFrom as string));
                    dispatch(filterSlice.actions.removeFloorTo(filter.floorTo as string));
                    break;
                case 'buildingFloor':
                    dispatch(filterSlice.actions.removeBuildingFloorFrom(filter.buildingFloorFrom as string));
                    dispatch(filterSlice.actions.removeBuildingFloorTo(filter.buildingFloorTo as string));
                    break;
                case 'constructionYear':
                    dispatch(filterSlice.actions.removeConstructionYearFrom(filter.constructionYearFrom as string));
                    dispatch(filterSlice.actions.removeConstructionYearTo(filter.constructionYearTo as string));
                    break;
                case 'animalPermission':
                    dispatch(filterSlice.actions.removeAnimalPermission(filter.animalPermission));
                    break;
                case 'room':
                    dispatch(filterSlice.actions.removeRooms(filter.rooms.toString()));
                    break;
            }
        } else dispatch(filterSlice.actions.removePrice(filter.price as string))
    };

    const [heightDiv, setHeightDiv] = useState<number | null>(null);
    const myDivRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (myDivRef.current) {
            const height = myDivRef.current.offsetHeight;
            setHeightDiv(height);
        }
    }, [totalFilterApplied]);

    useEffect(() => {
        dispatch(filterSlice.actions.setFilterAppliedWidth(heightDiv));
    }, [heightDiv]);

    const isSticky = usePrevScrollSticky();

    const {isScreenResolutionMobile} = useWindowSize();

    return (
        <div
            className={`${type === Type.Rent ?
                filterAppliedStyles.flex_applied : filterAppliedStyles.flex_applied_width} 
                ${isSticky && !isScreenResolutionMobile ? filterAppliedStyles.sticky : ''}`}
            ref={myDivRef}>
            {result.length !== 0 &&
                finishFilters.size !== 0 &&
                result.filter(item => item.value !== 'undefined - undefined')
                    .map(item => (
                        item.value &&
                        <div className={filterAppliedStyles.filter} key={item.name}>
                            {type === Type.Rent ? t('filterApplied.' + item.name) : t('filterApplied.rentalPrice')} : {item.value === '+' ?
                            <img className={filterAppliedStyles.tick} src={tick} alt="tick" /> : item.value}
                            <div className={filterAppliedStyles.size}
                                 onClick={() => {
                                     filterRemove(item.name);
                                 }}
                            >
                                <img className={filterAppliedStyles.remove} src={closeButton} alt="close" />
                            </div>
                        </div>
                    ))
            }
        </div>
    );
};

export default FilterApplied;