import React, {useCallback, useEffect, useState} from 'react';
import {
    AcceptanceType,
    OfferCreatorType,
    ResponseRefreshUserSuccessActionPayloadType,
} from '../../../models/generalTypes';
import {useDispatch, useSelector} from 'react-redux';
import acceptanceSelectors from '../../../features/acceptance/acceptanceSelectors';
import acceptanceOperations from '../../../features/acceptance/acceptanceOperations';
import userSelectors from '../../../features/user/userSelectors';
import Slider from 'react-slick';
import offersSelectors from '../../../features/offers/offersSelectors';
import offersSlice from '../../../features/offers/offersSlice';
import AcceptancesContactModal from '../AcceptancesModal/contactModal/AcceptancesContactModal';
import acceptanceSlice from '../../../features/acceptance/acceptanceSlice';
import UpdateAcceptanceModal from './updateAcceptanceModal/UpdateAcceptanceModal';
import {useTranslation} from 'react-i18next';
import LoaderButtonService from '../../../common/Button/loaderButtonService/LoaderButtonService';
import offersOperations from '../../../features/offers/offersOperations';
import useWindowSize from '../../../hooks/useWindowSize';
import BasicModal from '../../BasicModal/BasicModal';
import useDateFormatting from '../../../hooks/useDateFormatting';
import {Link} from 'react-router-dom';

import WindowSize from '../../../enums/WindowSize';
import LoaderColor from '../../../common/Button/enum/LoaderColor';
import Sort from '../enums/Sort';
import Status from '../enums/Status';
import PhotoSize from '../enums/PhotoSize';
import DateFormat from '../../../hooks/enums/DateFormat';

import closeButtonSmall from '../images/closeButtonSmall.svg';
import update from '../images/update.svg';
import information from '../../../assets/icons/information.svg';
import price from '../images/price.svg';
import address from '../images/address.svg';
import updateMobile from '../images/updateMobile.svg';

import './Acceptances.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../../styles/pagination.scss';

const Acceptances = () => {

    const totalElements = useSelector(acceptanceSelectors.getTotalElementsUserAcceptances);
    const [page, setPage] = useState(1);
    const totalPages = Math.ceil(totalElements / 4);

    const settings = {
        className: 'center',
        centerPadding: '60px',
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
    };

    const dispatch = useDispatch();

    const [isContactModalOpen, toggleContactModal] = useState(false);

    const [isRemoveModal, toggleRemoveModal] = useState(false);

    const [isUpdateModal, toggleUpdateModal] = useState(false);

    const [checked, setChecked] = useState(false);

    const {t} = useTranslation();

    const formattedDate = useDateFormatting();

    const userAcceptances: Array<AcceptanceType> = useSelector(
        acceptanceSelectors.getUserAcceptances.selectAll,
    );

    const totalUserAcceptances = useSelector(acceptanceSelectors.getTotalElementsUserAcceptances);

    const user = useSelector(
        userSelectors.getUserProfile,
    ) as ResponseRefreshUserSuccessActionPayloadType;

    useEffect(() => {
        dispatch(acceptanceOperations.getUserAcceptances({
            size: 4,
            page: 0,
            sort: Sort.Created,
            statuses: Status.Waiting,
        }));
    }, []);

    const pageElementsCount = useSelector(acceptanceSelectors.getPageElementsCountUserAcceptances);

    const [filterSettings, setFilterSettings] = useState({
        sort: Sort.Created,
        statuses: Status.Waiting,
    });

    const serialFilterSettings = JSON.stringify(filterSettings);

    const key = 'acceptance_page_filter_' + user?.id;

    localStorage.setItem(key, serialFilterSettings);

    const values = JSON.parse(localStorage.getItem(key) || '');

    useEffect(() => {
        if (pageElementsCount === 0 && totalElements > 0) {
            setPage(page - 1);
            dispatch(acceptanceOperations.getUserAcceptances({
                size: 4,
                page: page - 2,
                sort: values.sort,
                statuses: values.statuses,
            }));
        }
    }, [pageElementsCount]);

    useEffect(() => {
        return () => {
            dispatch(acceptanceSlice.actions.clearUserAcceptances(userAcceptances));
            dispatch(acceptanceSlice.actions.clearTotalElementsUserAcceptances(totalUserAcceptances));
        };
    }, []);

    const offerCreator: Array<OfferCreatorType> = useSelector(offersSelectors.getOfferCreator.selectAll);

    const clear = () => dispatch(offersSlice.actions.clearOfferCreator(offerCreator));

    const [acceptanceId, setAcceptanceId] = useState('');

    const getId = useCallback(
        (id: string) => {
            setAcceptanceId(id);
        },
        [acceptanceId],
    );

    const filtering = (sort: any, statuses: any) => {

        dispatch(acceptanceSlice.actions.clearUserAcceptances(userAcceptances));

        setFilterSettings({
            sort: sort,
            statuses: statuses,
        });

        const filtersSettings = {
            sort: sort,
            statuses: statuses,
        };

        dispatch(acceptanceOperations.getUserAcceptances({
            size: 4,
            page: 0,
            sort: filtersSettings.sort,
            statuses: filtersSettings.statuses,
        }));
    };

    const removeAcceptance = async () => {
        await dispatch(acceptanceOperations.updateAcceptanceStatus({
            id: acceptanceId,
            status: Status.Rejected,
        }));
        await dispatch(acceptanceSlice.actions.removeOne(acceptanceId));
        const item = JSON.parse(localStorage.getItem(key) || '');
        dispatch(acceptanceOperations.getUserAcceptances({
            size: 4,
            page: page,
            sort: item.sort,
            statuses: item.statuses,
        }));
        toggleRemoveModal(!isRemoveModal);
    };

    const {isScreenResolutionMobile} = useWindowSize();

    return (
        <div className="acceptances">

            <div className="acceptances__filter">
                <p className="acceptances__count">{t('acceptancePage.acceptances.count')}: {totalUserAcceptances}</p>
                {!isScreenResolutionMobile ?
                    <>
                        <p className="acceptances__filter-name">{t('acceptancePage.acceptances.filter')}</p>
                        <input id="checkbox" checked={checked} className="acceptances__filter-checkbox" type="checkbox"
                               onChange={() => {
                                   setChecked(!checked);
                                   if (!checked) {
                                       filtering(values.sort, Status.Approved);
                                   } else filtering(values.sort, Status.Waiting);
                               }} />
                    </>
                    :
                    <div className="acceptances__filter-flex_mobile">
                        <p className="acceptances__filter-name">{t('acceptancePage.acceptances.filter')}</p>
                        <input id="checkbox" checked={checked} className="acceptances__filter-checkbox" type="checkbox"
                               onChange={() => {
                                   setChecked(!checked);
                                   if (!checked) {
                                       filtering(values.sort, Status.Approved);
                                   } else filtering(values.sort, Status.Waiting);
                               }} />
                    </div>
                }
            </div>

            <div className="acceptances__main">
                {userAcceptances.length !== 0 && userAcceptances.map(acceptance =>

                    (acceptance.offer &&

                      <div className="acceptances__item" key={acceptance.id}>

                        <div className="acceptances__flex">

                          <div className="acceptances__item-left">
                              {isScreenResolutionMobile && acceptance.offer.photos.length !== 0 &&
                                <div
                                  className="acceptances__item-right-close"
                                  onClick={() => {
                                      toggleRemoveModal(!isRemoveModal);
                                      getId(acceptance.id);
                                  }}
                                >
                                  <p>
                                    <img src={closeButtonSmall} alt="closeButton" />
                                  </p>
                                </div>}
                              {acceptance.offer.photos.length !== 0 ? (
                                  <Slider {...settings} className="acceptances__item-left-slider">
                                      {acceptance.offer.photos
                                          .filter(photo => photo.photoSize === PhotoSize.Large)
                                          .map(photo => (
                                              <div key={photo.id} className="acceptances__item-left-img-div">
                                                  <img
                                                      className="acceptances__item-left-img"
                                                      alt="house"
                                                      src={photo.photoLocation}
                                                  />
                                              </div>
                                          ))}
                                  </Slider>
                              ) : (
                                  <Slider {...settings} className="acceptances__item-left-slider">
                                      <div className="acceptances__item-left-img-div">
                                          <img className="acceptances__item-left-img"
                                               src="https://images.all-free-download.com/images/graphicwebp/bird_houses_208582.webp"
                                               alt="house"
                                          />
                                          {isScreenResolutionMobile &&
                                            <div
                                              className="acceptances__item-right-close"
                                              onClick={() => {
                                                  toggleRemoveModal(!isRemoveModal);
                                                  getId(acceptance.id);
                                              }}
                                            >
                                              <p>
                                                <img src={closeButtonSmall} alt="closeButton" />
                                              </p>
                                            </div>}
                                      </div>
                                  </Slider>
                              )}
                              {!isScreenResolutionMobile &&
                                <div className="acceptances__item-left-flex">
                                  <p>
                                    <img src={price} alt="price" />
                                  </p>
                                  <p
                                    className="acceptances__item-left-offer-price">{acceptance.offer.price} {acceptance.currency}/{t('acceptancePage.acceptances.currency')}</p>
                                </div>}
                          </div>

                          <div className="acceptances__item-right">

                            <div className="acceptances__item-right-column">

                              <div className="acceptances__item-right-flex">
                                <div className="acceptances__item-right-row">
                                  <p>
                                    <img src={price} alt="price" />
                                  </p>
                                    {isScreenResolutionMobile ?
                                        <p className="acceptances__item-right-price">
                                                    <span className="acceptances__item-right-price-grey">
                                                        {acceptance.offer.price}
                                                    </span> / {acceptance.price} {acceptance.currency} / {t('acceptancePage.acceptances.currency')}
                                        </p>
                                        :
                                        <p className="acceptances__item-right-price">
                                            {acceptance.price} {acceptance.currency}/{t('acceptancePage.acceptances.currency')}
                                        </p>}
                                </div>
                                  {!isScreenResolutionMobile &&
                                    <div className="acceptances__item-right-row">
                                        {acceptance.status === Status.Approved ?
                                            <div className="acceptances__item-right-opacity">
                                                <p>
                                                    <img src={update} alt="update" />
                                                </p>
                                            </div> :
                                            <div className="acceptances__item-right-change" onClick={() => {
                                                toggleUpdateModal(!isUpdateModal);
                                                getId(acceptance.id);
                                            }}>
                                                <p>
                                                    <img src={update} alt="update" />
                                                </p>
                                            </div>}

                                      <div
                                        className="acceptances__item-right-close"
                                        onClick={() => {
                                            toggleRemoveModal(!isRemoveModal);
                                            getId(acceptance.id);
                                        }}
                                      >
                                        <p>
                                          <img src={closeButtonSmall} alt="closeButton" />
                                        </p>
                                      </div>
                                    </div>}
                              </div>
                              <div className="acceptances__item-right-row">
                                <p>
                                  <img src={address} alt="address" />
                                </p>
                                <p
                                  className="acceptances__item-right-address">{acceptance.offer.geolocation.address}</p>
                              </div>
                                {!isScreenResolutionMobile ?
                                    <div className="acceptances_details_flex_end">
                                        <p className="acceptances__item-right-date">{formattedDate(acceptance.createdOn, DateFormat.NormalAndTime)}</p>
                                        <div className="acceptances_details_flex">
                                            <Link className="acceptances_link_details"
                                                  to={`/offers/${acceptance.offerId}`}>
                                                <span className="acceptances_details">{t('acceptancePage.acceptances.details')}</span>
                                            </Link>
                                            {acceptance.status === Status.WaitingSolo ?
                                                <p className="acceptances__item-right-confirm">{t('acceptancePage.acceptances.confirm.false')}</p>
                                                :
                                                <LoaderButtonService
                                                    query={offersOperations.getOfferCreator(acceptance.offerId)}
                                                    buttonContent={t('acceptancePage.acceptances.confirm.true')}
                                                    buttonClassName={'acceptance_contact_btn'}
                                                    stateCondition={isContactModalOpen}
                                                    setStateCondition={toggleContactModal}
                                                    color={LoaderColor.Green}
                                                />
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <p className="acceptances__item-right-date">{formattedDate(acceptance.createdOn, DateFormat.NormalAndTime)}</p>
                                        <div className="acceptances__item-right-sb">
                                            {acceptance.status === Status.WaitingSolo ?
                                                <p className="acceptances__item-right-confirm">{t('acceptancePage.acceptances.confirm.false')}</p>
                                                :
                                                <LoaderButtonService
                                                    query={offersOperations.getOfferCreator(acceptance.offerId)}
                                                    buttonContent={t('acceptancePage.acceptances.confirm.true')}
                                                    buttonClassName={'acceptance_contact_btn'}
                                                    stateCondition={isContactModalOpen}
                                                    setStateCondition={toggleContactModal}
                                                    color={LoaderColor.Green}
                                                />}
                                            {isScreenResolutionMobile &&
                                              <Link className="acceptances__item-left-information_svg"
                                                    to={`/offers/${acceptance.offerId}`}>
                                                <img src={information} alt="information" />
                                              </Link>}
                                            {acceptance.status === Status.Approved ?
                                                <div className="acceptances__item-right-opacity">
                                                    <p>
                                                        <img src={updateMobile} alt="update" />
                                                    </p>
                                                </div> :
                                                <div className="acceptances__item-right-change" onClick={() => {
                                                    toggleUpdateModal(!isUpdateModal);
                                                    getId(acceptance.id);
                                                }}>
                                                    <p>
                                                        <img src={updateMobile} alt="update" />
                                                    </p>
                                                </div>}
                                        </div>
                                    </div>}
                            </div>
                          </div>
                        </div>
                      </div>
                    ),
                )}
                {userAcceptances.length !== 0 && totalPages > 1 && userAcceptances.length < totalUserAcceptances &&
                  <LoaderButtonService
                    query={acceptanceOperations.getUserAcceptances({
                        size: 4,
                        page: page,
                        sort: values.sort,
                        statuses: values.statuses,
                    })}
                    color={LoaderColor.Green}
                    buttonContent={t('acceptancePage.acceptances.more')}
                    page={page}
                    setPage={setPage}
                    buttonClassName={'btn_more'}
                  />
                }
            </div>

            {offerCreator &&
              <AcceptancesContactModal
                isModalOpen={isContactModalOpen}
                toggleModal={toggleContactModal}
                creator={offerCreator}
                clear={clear}
              />}
            {acceptanceId !== '' &&
              <BasicModal
                isModalOpen={isRemoveModal}
                toggleModal={toggleRemoveModal}
                header={t('acceptancePage.removeAcceptanceModal.header')}
                question={t('acceptancePage.removeAcceptanceModal.question')}
                leftButton={t('acceptancePage.removeAcceptanceModal.btn.cancel')}
                rightButton={t('acceptancePage.removeAcceptanceModal.btn.remove')}
                onClick={removeAcceptance}
              />}
            {userAcceptances.length !== 0 &&
              <UpdateAcceptanceModal
                isModalOpen={isUpdateModal}
                toggleModal={toggleUpdateModal}
                acceptance={userAcceptances.filter(item => item.id === acceptanceId)[0]}
                acceptanceId={acceptanceId}
              />}
        </div>
    );
};

export default Acceptances;