import React, {useState} from 'react';
import {ResponseRefreshUserSuccessActionPayloadType} from "../../../models/generalTypes";
import {useSelector} from "react-redux";
import userSelectors from "../../../features/user/userSelectors";
import {useTranslation} from "react-i18next";

import approved from "../../../assets/icons/userProfile/approved.svg";
import pending from "../../../assets/icons/userProfile/pending.svg";

import confirmedMenuStyles from './EmailPhoneConfirmedMenu.module.scss';

interface Props {
    contactSwitch: boolean;
}

const EmailPhoneConfirmedMenu: React.FC<Props> = ({contactSwitch}) => {

    const [isMenuVisible, setMenuVisibility] = useState(false);

    const user = useSelector(
        userSelectors.getUserProfile,
    ) as ResponseRefreshUserSuccessActionPayloadType;

    const {t} = useTranslation();

    return (
        <>
            {contactSwitch ?
                <div className={confirmedMenuStyles.menu_position}>
                    {user.phoneConfirmed ?
                        <img
                            src={approved}
                            alt="approved"
                            className={confirmedMenuStyles.flex_confirmed_img}
                            onMouseEnter={() => setMenuVisibility(true)}
                            onMouseLeave={() => setMenuVisibility(false)}
                        />
                        :
                        <img
                            src={pending}
                            alt="pending"
                            className={confirmedMenuStyles.flex_confirmed_img}
                            onMouseEnter={() => setMenuVisibility(true)}
                            onMouseLeave={() => setMenuVisibility(false)}
                        />}
                    {isMenuVisible && (
                        <div className={confirmedMenuStyles.menu}>
                            {user.phoneConfirmed ?
                                <p className={confirmedMenuStyles.menu_text}>{t('emailPhoneConfirmedMenu.phone.confirmed')}</p> :
                                <p className={confirmedMenuStyles.menu_text}>{t('emailPhoneConfirmedMenu.phone.notConfirmed')}</p>}
                        </div>
                    )}
                </div>
                :
                <div className={confirmedMenuStyles.menu_position}>
                    {user.emailConfirmed ?
                        <img
                            src={approved}
                            alt="approved"
                            className={confirmedMenuStyles.flex_confirmed_img}
                            onMouseEnter={() => setMenuVisibility(true)}
                            onMouseLeave={() => setMenuVisibility(false)}
                        />
                        :
                        <img
                            src={pending}
                            alt="pending"
                            className={confirmedMenuStyles.flex_confirmed_img}
                            onMouseEnter={() => setMenuVisibility(true)}
                            onMouseLeave={() => setMenuVisibility(false)}
                        />}
                    {isMenuVisible && (
                        <div className={confirmedMenuStyles.menu}>
                            {user.emailConfirmed ? <p className={confirmedMenuStyles.menu_text}>{t('emailPhoneConfirmedMenu.email.confirmed')}</p> :
                                <p className={confirmedMenuStyles.menu_text}>{t('emailPhoneConfirmedMenu.email.notConfirmed')}</p>}
                        </div>
                    )}
                </div>
            }
        </>
    );
}

export default EmailPhoneConfirmedMenu;