import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import subscriptionOperation, {SubscriptionsType} from '../../features/subscription/subscriptionOperation';
import subscriptionSelectors from '../../features/subscription/subscriptionSelectors';
import {useTranslation} from 'react-i18next';
import LoaderButtonService from '../../common/Button/loaderButtonService/LoaderButtonService';
import useDateFormatting from '../../hooks/useDateFormatting';
import {ThreeDots} from 'react-loader-spinner';
import subscriptionSlice from '../../features/subscription/subscriptionSlice';
import filterSelectors from '../../features/filter/filterSelectors';
import {FilterType, SubscriptionsFilterType} from '../../models/generalTypes';
import SubscriptionsModal from './dropdown/SubscriptionsModal';

import LoaderColor from '../../common/Button/enum/LoaderColor';
import DateFormat from '../../hooks/enums/DateFormat';
import PhotoSize from '../Acceptances/enums/PhotoSize';

import price from '../../assets/icons/subscriptions/price.svg';
import stub from '../../assets/icons/userProfile/stub.svg';

import subscriptionsStyles from './SubscriptionsForm.module.scss';

const SubscriptionsForm = () => {

    const dispatch = useDispatch();

    const {t} = useTranslation();

    const formattedDate = useDateFormatting();

    const filter = useSelector(filterSelectors.getSubscriptionFilter) as SubscriptionsFilterType;

    const [page, setPage] = useState(0);

    const subscriptions: Array<SubscriptionsType> = useSelector(subscriptionSelectors.getSubscriptions.selectAll);

    const totalCount = useSelector(subscriptionSelectors.getTotalCount);

    useEffect(() => {
        // dispatch(subscriptionOperation.getSubscriptions({filter, size: 10, page: 0}));
        return () => {
            dispatch(subscriptionSlice.actions.clearSubscriptions(subscriptions));
        };
    }, []);

    const showBtn = () => {
        return subscriptions.length !== 0 &&
            totalCount.pages > 1 &&
            subscriptions.length < totalCount.elements;
    };

    return (
        <div className={subscriptionsStyles.box}>
            {subscriptions.length !== 0 ?
                subscriptions.map(item => (
                    <div className={subscriptionsStyles.main} key={item.id}>
                        <div className={subscriptionsStyles.photo_block}>
                            {item.user.photos.length !== 0 ? (
                                <>
                                    {item.user.photos
                                        .filter(photo => photo.photoSize === PhotoSize.Large)
                                        .map(photo => (
                                            <div key={photo.id}>
                                                <img
                                                    className={subscriptionsStyles.img}
                                                    alt="hata"
                                                    src={photo.photoLocation}
                                                />
                                            </div>
                                        ))[item.user.photos.length - 1]}
                                </>
                            ) : (
                                <>
                                    <img
                                        className={subscriptionsStyles.img}
                                        alt="stub"
                                        src={stub}
                                    />
                                </>
                            )}
                        </div>
                        <div className={subscriptionsStyles.info_block}>
                            <p>{item.user.name} {item.user.lastName}</p>
                            {item.rooms.length !== 0 &&
                              <p>{t('subscriptionsPage.subscriptionsForm.rooms.first')} {item.rooms.toString()} {t('subscriptionsPage.subscriptionsForm.rooms.second')}</p>}
                            <div className={`${subscriptionsStyles.flex_gap} mb-3`}>
                                {t('subscriptionsPage.subscriptionsForm.price')}
                                <div className={subscriptionsStyles.flex}>
                                    <img className={subscriptionsStyles.svg} src={price} alt="price" />
                                    <p className="mb-0">{item.priceFrom} {t('subscriptionsPage.subscriptionsForm.currency')}</p>
                                </div>
                                &ndash;
                                <p className="mb-0">{item.priceTo} {t('subscriptionsPage.subscriptionsForm.currency')}</p>
                            </div>
                            {item.user.description !== '' &&
                              <div className={subscriptionsStyles.description}>
                                <details>
                                  <summary className={subscriptionsStyles.summary}>
                                      {t('subscriptionsPage.subscriptionsForm.moreInformation')}
                                  </summary>
                                  <p className={subscriptionsStyles.description_text}>
                                      {item.user.description}
                                  </p>
                                </details>
                              </div>}
                            <div className={subscriptionsStyles.flex_space_between}>
                                <p className={subscriptionsStyles.date}>{formattedDate(item.createdOn, DateFormat.Normal)}</p>
                                <SubscriptionsModal subscriptionId={item.id} />
                            </div>
                        </div>
                    </div>
                )) :
                (
                    <div className={subscriptionsStyles.loader}>
                        <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color={LoaderColor.Green}
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            visible={true}
                        />
                    </div>
                )}
            <div className={subscriptionsStyles.more_btn}>
                {showBtn() ?
                    <LoaderButtonService
                        query={subscriptionOperation.getSubscriptions({page: page, filter})}
                        color={LoaderColor.White}
                        page={page}
                        setPage={setPage}
                        buttonContent={t('subscriptionsPage.subscriptionsForm.moreBtn')}
                        buttonClassName={'btn_more_offers_page'}
                    /> : null}
            </div>
        </div>
    );
};

export default SubscriptionsForm;