import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Slider from 'react-slick';
import {ResponseRefreshUserSuccessActionPayloadType} from '../../models/generalTypes';
import {useTranslation} from 'react-i18next';
import userOperations from '../../features/user/userOperations';
import userSelectors from '../../features/user/userSelectors';
import EmailPhoneChanger from './EmailPhoneChanger/EmailPhoneChanger';
import EmailPhoneConfirmedMenu from './EmailPhoneConfirmedMenu/EmailPhoneConfirmedMenu';

import PhotoSize from '../Acceptances/enums/PhotoSize';

import camera from '../../assets/icons/camera-photo.svg';
import stub from '../../assets/icons/userProfile/stub.svg';

import userProfileStyles from './UserProfile.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const UserProfile: React.FC = () => {

    const settings = {
        className: 'center',
        centerPadding: '60px',
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <></>,
        prevArrow: <></>,
    };

    const {t} = useTranslation();

    const isUploadedPhoto = useSelector(userSelectors.isUploadedPhoto);

    const updatedUserProfile = useSelector(userSelectors.updatedUserProfile);

    useEffect(() => {
        dispatch(userOperations.getUserProfile());
    }, [isUploadedPhoto, updatedUserProfile]);

    const user = useSelector(
        userSelectors.getUserProfile,
    ) as ResponseRefreshUserSuccessActionPayloadType;

    const dispatch = useDispatch();

    const [photos, setPhotos] = useState({file: [null] as Array<any>});

    useEffect(() => {
        photos.file[0] !== null &&
        dispatch(
            userOperations.uploadUserPhoto({id: user.id, photos: photos.file}),
        );
    }, [photos.file]);

    const uploadFiles = (e: any) => {
        const files = Array.from(e.target.files);
        setPhotos({file: files});
    };

    const [textAreaValue, setTextAreaValue] = useState<string>(user.description !== 'null' ? user.description : '');

    const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTextAreaValue(event.target.value);
    };

    const updateDescription = () => {
        if (textAreaValue !== '') {
            dispatch(
                userOperations.updateUser({
                    id: user.id,
                    profile: {
                        lastName: user.lastName,
                        name: user.name,
                        description: textAreaValue,
                    },
                }),
            );
        }
    };

    return (
        (user && <div className={userProfileStyles.container}>
            <div className={userProfileStyles.wrapper}>
                {user.photos.length !== 0 ? (
                    <Slider {...settings} className={userProfileStyles.slider_avatar}>
                        {user.photos
                            .filter(photo => photo.photoSize === PhotoSize.Large)
                            .reverse()
                            .map(photo => (
                                <div key={photo.id}>
                                    <img
                                        className={userProfileStyles.slider_img}
                                        alt="hata"
                                        src={photo.photoLocation}
                                    />
                                </div>
                            ))}
                    </Slider>
                ) : (
                    <Slider {...settings} className={userProfileStyles.slider_avatar}>
                        <div>
                            <img
                                className={userProfileStyles.slider_img}
                                alt="stub"
                                src={stub}
                            />
                        </div>
                    </Slider>
                )}
              <label className={userProfileStyles.label}>
                <img src={camera} alt="camera" className={userProfileStyles.camera} />
                <input
                  onChange={uploadFiles}
                  name="photos"
                  type="file"
                  className={userProfileStyles.input_upload}
                />
              </label>
            </div>
                {(
                    <div className={userProfileStyles.user_data}>
                        <div>
                            <p className={userProfileStyles.name}>
                                {user.name} {user.lastName}
                            </p>

                            <EmailPhoneChanger label="profile" />
                        </div>
                        <div>
                            <div className={userProfileStyles.flex_confirmed}>
                                <EmailPhoneConfirmedMenu contactSwitch={false} />
                                {user.email !== null ? (
                                    <p className={userProfileStyles.email}>
                                        {user.email}
                                    </p>
                                ) : (
                                    <p className={userProfileStyles.email}>
                                        {t('userProfile.no-email')}
                                    </p>
                                )}
                            </div>

                            <EmailPhoneChanger label="email" isConfirmed={!user.emailConfirmed} />
                        </div>
                        <div>
                            <div className={userProfileStyles.flex_confirmed}>
                                <EmailPhoneConfirmedMenu contactSwitch={true} />
                                {user.phone !== null ? (
                                    <p className={userProfileStyles.phone}>
                                        {user.phone}
                                    </p>
                                ) : (
                                    <p className={userProfileStyles.email}>
                                        {t('userProfile.no-phone')}
                                    </p>
                                )}
                            </div>

                            <EmailPhoneChanger label="phone" isConfirmed={!user.phoneConfirmed} />
                        </div>
                        <div>
                            <p className={userProfileStyles.additional_info}>Додаткова інформація</p>
                            <div className={userProfileStyles.flex_column}>
                                    <textarea
                                        placeholder={t('userProfile.description')}
                                        cols={55}
                                        rows={3}
                                        value={textAreaValue}
                                        onChange={handleTextAreaChange}
                                        className={userProfileStyles.text_area}
                                    />
                                <span
                                    className={userProfileStyles.save_btn}
                                    onClick={updateDescription}
                                >
                                    {t('userProfile.saveBtn')}
                                </span>
                            </div>
                        </div>
                    </div>
                )}
          </div>
        )
    );
};

export default UserProfile;
