import React, {useState} from 'react';

import close from "../../../assets/icons/homePage/close.svg";
import open from "../../../assets/icons/homePage/open.svg";

import questionStyles from "./QuestionBlock.module.scss";

interface Props {
    number: string;
    question: string;
    answer: string;
}

const QuestionBlock = ({number, question, answer}: Props) => {

    const [isOpenQuestion, setIsOpenQuestion] = useState(false);

    return (
        <>
            <div className={questionStyles.question_block_text_flex}>
                <div className="flex-row">
                    <p className={questionStyles.question_block_number}>{number}</p>
                    <div>
                        <h4 className={questionStyles.question_block_question}>{question}</h4>
                        {isOpenQuestion && <p className={questionStyles.question_block_answer}>{answer}</p>}
                    </div>
                </div>
                {isOpenQuestion ?
                    <img className={questionStyles.question_block_close} onClick={() => setIsOpenQuestion(false)} src={close}
                         alt="close"/> :
                    <img className={questionStyles.question_block_close} onClick={() => setIsOpenQuestion(true)} src={open}
                         alt="open"/>}
            </div>
            <hr className={questionStyles.question_block_line}></hr>
        </>
    );
}

export default QuestionBlock;