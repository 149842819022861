import React, { useEffect, useState } from 'react';
import icon from './Icon.svg';
import styles from './ScrollToTopButton.module.scss';

const ScrollToTopButton: React.FC = () => {
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const handleVisibleButton = () => {
    setShowScrollToTop(window.pageYOffset > 150);
  };
  const handleScrollUp = () => {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  };
  useEffect(() => {
    window.addEventListener('scroll', handleVisibleButton);
  }, []);

  return (
    <div
      className={showScrollToTop ? '' : styles.scrollToTopHidden}
      onClick={handleScrollUp}
    >
      <button type={'button'} className={styles.scrollToTop}>
        <img src={icon} alt="scroll to top" />
      </button>
    </div>
  );
};

export default ScrollToTopButton;
