import React, {useState} from 'react';
import offersSlice from '../../../features/offers/offersSlice';
import {useDispatch} from 'react-redux';
import useWindowSize from '../../../hooks/useWindowSize';
import offersOperations from '../../../features/offers/offersOperations';
import {PhotosType} from '../../../models/generalTypes';
import {useTranslation} from 'react-i18next';

import WindowSize from '../../../enums/WindowSize';

import add from '../../../assets/icons/add-circle.svg';
import turn from '../../../assets/icons/turn.svg';
import basket from '../../../assets/icons/basket.svg';
import close from '../../../assets/icons/close.svg';

import p from './PhotoManager.module.scss';

interface Props {
    photo: string;
    index?: number;
    isUpdateOffer?: boolean;
    offerWithIdPhotos?: PhotosType[];
    offerId?: string;
}

const PhotoManager = ({photo, index, isUpdateOffer = false, offerWithIdPhotos = [], offerId = undefined}: Props) => {

    const dispatch = useDispatch();

    const {t} = useTranslation();

    const [rotation, setRotate] = useState(0);

    const rotatePhoto = () => {
        const angleRotation = 90;
        setRotate(prevState => prevState + angleRotation);
    };

    const removePhoto = (item: string) => {
        dispatch(offersSlice.actions.removePhoto(item));
    };

    const removePhotoOfferWithId = async (item: string) => {
        const filteredPhotos = offerWithIdPhotos?.filter(offerPhoto => offerPhoto.photoLocation === photo);
        if (filteredPhotos.length !== 0 && offerId !== undefined) {
            await dispatch(offersOperations.deleteOfferPhotos({
                offerId: offerId,
                photoGroupId: filteredPhotos[0].photoGroupId,
            }));
            dispatch(offersSlice.actions.removePhotoOfferWithId(item));
        } else dispatch(offersSlice.actions.removePhotoOfferWithId(item));
    };

    const [isHovering, setIsHovering] = useState(false);

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    const uploadMultipleFiles = (e: any) => {
        const files = Array.from(e.target.files);
        if (!isUpdateOffer) {
            dispatch(offersSlice.actions.setPhotosFilesWhenCreateOffer(files));
        } else files.map(file => dispatch(offersSlice.actions.setPhotosFilesWhenUpdateOffer(file)));
        const previews: string[] = [];
        for (let i = 0; i < files.length; i++) {
            const file: any = files[i];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                previews.push(reader.result as string);
                if (previews.length === files.length) {
                    !isUpdateOffer ?
                        dispatch(offersSlice.actions.setPhotosWhenCreateOffer(previews)) :
                        dispatch(offersSlice.actions.setPhotosWhenOfferUpdate(previews));
                }
            };
        }
    };

    const {isScreenResolutionMobile} = useWindowSize();

    return (
        <>
            {!isScreenResolutionMobile ?
                (<div className={p.main}>
                    {photo.indexOf('data:image') === -1 && photo.indexOf('thor-app-ddi-dev.s3') === -1 ?
                        <div className={p.container}
                             onMouseEnter={handleMouseEnter}
                             onMouseLeave={handleMouseLeave}
                        >
                            <div className={isHovering ? `${p.photo_hover} ${p.div_photo}` : `${p.div_photo}`}>
                                <img src={photo} alt=""
                                     className={p.img_style}
                                     style={{transform: `rotate(${rotation}deg)`}}
                                />
                            </div>
                            <div className={isHovering ? `${p.btn}` : `${p.none}`}>
                                <img src={add}
                                     alt="add"
                                     className={p.add}
                                />
                            </div>
                            <input
                                id="input-text"
                                onChange={uploadMultipleFiles}
                                name="photos"
                                className={p.input_file}
                                accept="image/png, image/jpg, image/jpeg"
                                multiple
                                type="file"
                            />
                        </div>
                        :
                        <div className={p.container}
                             onMouseEnter={handleMouseEnter}
                             onMouseLeave={handleMouseLeave}
                        >
                            <div className={isHovering ? `${p.photo_hover} ${p.div_photo}` : `${p.div_photo}`}>
                                <img src={photo} alt=""
                                     className={p.img_style}
                                     style={{transform: `rotate(${rotation}deg)`}}
                                />
                            </div>
                            <div className={isHovering ? `${p.btn}` : `${p.none}`}>
                                <img src={turn} alt="turn"
                                     onClick={rotatePhoto}
                                     className={p.rotate}
                                />
                                <img src={basket} alt="basket"
                                     onClick={() => {
                                         !isUpdateOffer ? removePhoto(photo) : removePhotoOfferWithId(photo);
                                     }}
                                     className={p.remove}
                                />
                            </div>
                        </div>
                    }
                </div>) :
                (<div>
                    {photo.indexOf('data:image') === -1 && photo.indexOf('thor-app-ddi-dev.s3') === -1 ?
                        null
                        :
                        <div className={p.container}>
                            <div className={p.div_photo}>
                                <img src={photo} alt=""
                                     className={p.img_style}
                                     style={{transform: `rotate(${rotation}deg)`}}
                                />
                            </div>
                            {index === 0 &&
                              <div className={p.shadow_block}>
                                <span className={p.shadow_text}>{t('createNewOfferPage.photos.mainPhoto')}</span>
                              </div>}
                            <div
                                className={p.close}
                                onClick={() => {
                                    !isUpdateOffer ? removePhoto(photo) : removePhotoOfferWithId(photo);
                                }}>
                                <img src={close} alt="close" />
                            </div>
                        </div>
                    }
                </div>)
            }
        </>
    );
};

export default PhotoManager;