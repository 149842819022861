import {createSlice} from "@reduxjs/toolkit";
import contactOperations from "./contactOperations";
import {toast} from "react-toastify";
import i18n from "i18next";

export type ContactType = {
    email: string;
    name: string;
    phone: string;
    message: string
}

interface InitialState {
    contact: ContactType | null;
}

const initialState: InitialState = {
    contact: null
}

const contactSlice = createSlice(
    {
        name: 'contact',
        initialState,
        reducers: {},
        extraReducers: builder => {
            builder.addCase(
                contactOperations.contactUs.fulfilled,
                (state, action) => {
                    state.contact = action.payload;
                    toast.success(i18n.t('homePage.email.toast'))
                }
            )
        }
    }
)

export default contactSlice;