import React, {Dispatch, SetStateAction} from 'react';
import {FieldValues, UseFormHandleSubmit} from "react-hook-form";
import useLoader from "../../../hooks/useLoader";
import {ThreeDots} from "react-loader-spinner";
import LoaderColor from "../enum/LoaderColor";
import {useHistory} from "react-router-dom";

import '../../../styles/loaderButtons.scss';

interface Props {
    buttonContent: string | undefined;
    buttonClassName: string;
    submit: UseFormHandleSubmit<FieldValues> | any;
    color: LoaderColor;
    setStateCondition?: Dispatch<SetStateAction<boolean>>;
    stateCondition?: boolean;
    isActive?: boolean;
}

const LoaderButtonFormService: React.FC<Props> = ({
                                                      buttonContent,
                                                      buttonClassName,
                                                      submit,
                                                      color,
                                                      setStateCondition,
                                                      stateCondition,
                                                      isActive = true
                                                  }) => {

    const {startLoading, stopLoading, isLoading} = useLoader();

    const history = useHistory();

    const submitHandler = async () => {
        startLoading();
        try {
            await submit();
            if (setStateCondition) {
                setStateCondition(!stateCondition);
            }
        } catch (error) {
            console.log('error', error);
        }
        stopLoading();
    };

    return (
        <>
            <button
                className={buttonClassName}
                onClick={submitHandler}
                disabled={isLoading || !isActive}
            >
                {isLoading ?
                    <div
                        className={history.location.pathname === "/create" ? "centering_form_loader_create" : "centering_form_loader"}>
                        <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color={color}
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            visible={true}
                        />
                    </div>
                    :
                    buttonContent
                }
            </button>
        </>
    );
}

export default LoaderButtonFormService;