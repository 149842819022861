import React, {useEffect, useState} from 'react';
import {NavLink, useHistory} from 'react-router-dom';
import useWindowSize from '../../hooks/useWindowSize';
import AuthModal from '../Authentification/AuthModal/AuthModal';
import {useDispatch, useSelector} from 'react-redux';
import authSelectors from '../../features/auth/authSelectors';
import {useTranslation} from 'react-i18next';
import AuthProfileDropdown from '../Authentification/AuthProfileDropdown/AuthProfileDropdown';
import AuthProfileDropdownMobile from '../Authentification/AuthProfileDropDownMobile/AuthProfileDropdownMobile';
import authSlice from '../../features/auth/authSlice';
import BasicModal from '../BasicModal/BasicModal';

import WindowSize from '../../enums/WindowSize';

import logo from '../../assets/icons/navigation/logo.svg';

import navigationStyles from './NavigationPanel.module.scss';

const NavigationPanel = () => {

    const {isScreenResolutionMobile} = useWindowSize();

    const isAuthenticated = useSelector(authSelectors.getIsAuthenticated);

    const {t} = useTranslation();

    const dispatch = useDispatch();

    const [isConfirmedModalOpen, toggleConfirmedModal] = useState(false);

    const isConfirmedModalOpenState: boolean = useSelector(authSelectors.isConfirmedModalOpen);

    useEffect(() => {
        isConfirmedModalOpenState && toggleConfirmedModal(true);
    }, [isConfirmedModalOpenState]);

    useEffect(() => {
        return () => {
            dispatch(authSlice.actions.setIsConfirmedModal(false));
        };
    }, []);

    const [isModalOpen, toggleModal] = useState(false);

    const history = useHistory();

    return (
        <div className={navigationStyles.main}>
            <div className={navigationStyles.flex_row}>
                <NavLink to="/">
                    <img className={navigationStyles.nav_link_size} src={logo} alt="logo" />
                </NavLink>
                {!isScreenResolutionMobile &&
                  <div className={navigationStyles.flex_group}>
                    <button className={navigationStyles.nav_btn}>
                      <a href="#about" className={navigationStyles.nav_btn__text}>
                          {t('navigation.about')}
                      </a>
                    </button>
                    <button className={navigationStyles.nav_btn}>
                      <a href="#steps" className={navigationStyles.nav_btn__text}>
                          {t('navigation.steps')}
                      </a>
                    </button>
                    <button className={navigationStyles.nav_btn}>
                      <a href="#advantages" className={navigationStyles.nav_btn__text}>
                          {t('navigation.advantages')}
                      </a>
                    </button>
                  </div>}
                {isAuthenticated ?
                    (<>
                        {isScreenResolutionMobile ?
                            <AuthProfileDropdownMobile />
                            :
                            <div>
                                <AuthProfileDropdown />
                            </div>}
                    </>)
                    :
                    (<>
                        {isScreenResolutionMobile ?
                            <AuthProfileDropdownMobile />
                            :
                            <button
                                className={navigationStyles.auth_btn}
                                onClick={() => toggleModal(!isModalOpen)}
                            >
                                {t('navigation.btn')}
                            </button>}
                    </>)
                }
            </div>
            <AuthModal isAuthModalOpen={isModalOpen} toggleAuthModal={toggleModal} />
            <BasicModal isModalOpen={isConfirmedModalOpen}
                        toggleModal={toggleConfirmedModal}
                        header={t('confirmedModal.header')}
                        question={t('confirmedModal.question')}
                        leftButton={t('confirmedModal.btn.later')}
                        rightButton={t('confirmedModal.btn.confirm')}
                        onClick={() => history.push('/account/profile')}
                        additionalFunction={() => dispatch(authSlice.actions.setIsConfirmedModal(false))}
            />
        </div>
    );
};

export default NavigationPanel;