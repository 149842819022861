import axios from 'axios';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {CreateAcceptanceSuccessActionPayloadType} from '../../models/generalTypes';

axios.defaults.baseURL = 'http://api.rentbox.com.ua';

const createAcceptance = createAsyncThunk(
    'acceptance/createAcceptance',
    async (cred: {
        id: string;
        credentials: CreateAcceptanceSuccessActionPayloadType;
    }) => {
        try {
            const {data} = await axios.post(
                `/api/v1/acceptances?offerId=${cred.id}&statuses=`,
                cred.credentials,
            );
            return data;
        } catch (error) {
            console.log('error', error);
        }
    },
);

const updateAcceptance = createAsyncThunk(
    'acceptance/updateAcceptance',
    async (cred: {
        id: string;
        credentials: CreateAcceptanceSuccessActionPayloadType;
    }) => {
        try {
            const {data} = await axios.put(
                `/api/v1/acceptances/${cred.id}`,
                cred.credentials,
            );
            return data;
        } catch (error) {
            console.log('error', error);
        }
    },
);

const updateAcceptanceStatus = createAsyncThunk(
    'acceptance/updateAcceptanceStatus',
    async (cred: {
        id: string;
        status: string;
    }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            };
            const {data} = await axios.put(
                `/api/v1/acceptances/${cred.id}/status?status=${cred.status}`,
                config
            );
            return data;
        } catch (error) {
            console.log('error', error);
        }
    }
);

const getAcceptanceCreator = createAsyncThunk(
    'acceptance/acceptanceCreator',
    async (id: string) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            };
            const {data} = await axios.get(
                `/api/v1/private-data/acceptance-creator?acceptanceId=${id}`,
                config,
            );
            return data;
        } catch (error) {
            console.log('error', error);
        }
    }
);

export interface CredentialsUserAcceptances {
    statuses?: string;
    page?: number | string;
    size?: number | string;
    sort?: string;
}

const getUserAcceptances = createAsyncThunk(
    'acceptance/UserAcceptances',
    async ({statuses = '', page = 0, size = 10, sort = ''}: CredentialsUserAcceptances) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            };
            const {data} = await axios.get(
                `/api/v1/acceptances?statuses=${statuses}&page=${page}&size=${size}&sort=${sort}`,
                config,
            );
            return data;
        } catch (error) {
            console.log('error', error);
        }
    },
);

export interface CredentialsOfferAcceptances {
    statuses?: string;
    page?: number | string;
    size?: number | string;
    sort?: string;
    id: any;
}

const getOfferAcceptances = createAsyncThunk(
    'acceptance/OfferAcceptances',
    async ({statuses = '', page = 0, size = 10, sort = '', id}: CredentialsOfferAcceptances) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            };
            const {data} = await axios.get(
                `/api/v1/acceptances?offerId=${id}&statuses=${statuses}&page=${page}&size=${size}&sort=${sort}`,
                config,
            );
            return data;
        } catch (error) {
            console.log('error', error);
        }
    },
);

const getAcceptanceById = createAsyncThunk(
    'acceptance/id',
    async (id: number) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            };
            const {data} = await axios.get(`/api/v1/acceptances/${id}`, config);
            return data;
        } catch (error) {
            console.log('error', error);
        }
    }
)

const getOffersAcceptancesCount = createAsyncThunk(
    'acceptance/getOffersAcceptancesCount',
    async (id: any) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            };
            const {data} = await axios.get(`/api/v1/acceptances/count?offersIds=${id}`, config);
            return data;
        } catch (error) {
            console.log('error', error);
        }
    }
)

const operations = {
    createAcceptance,
    updateAcceptance,
    getUserAcceptances,
    getOfferAcceptances,
    getOffersAcceptancesCount,
    updateAcceptanceStatus,
    getAcceptanceCreator,
    getAcceptanceById
};
export default operations;
