import React, {
    useEffect,
} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import AddNewOffer from '../../components/AddNewOffer/AddNewOffer';
import offersSelectors from '../../features/offers/offersSelectors';
import offersOperations from '../../features/offers/offersOperations';
import offersSlice from '../../features/offers/offersSlice';

import 'bootstrap/dist/css/bootstrap.min.css';
import c from './CreateNewOffer.module.scss';

const CreateNewOffer: React.FC = () => {

    const dispatch = useDispatch();

    const history = useHistory();

    const id = useSelector(offersSelectors.getId);

    const photos = useSelector(offersSelectors.photosFilesWhenCreateOffer);

    const offerPhotos = useSelector(offersSelectors.uploadedPhotosToUserOffer);

    useEffect(() => {
        dispatch(offersOperations.getFacilities);
    });

    useEffect(() => {
        id !== null && dispatch(offersOperations.uploadOfferPhotos({id, photos}));
    }, [id]);

    useEffect(() => {
        offerPhotos !== null && history.replace(`/offers/${id}`);
        offerPhotos !== null && dispatch(offersSlice.actions.setIdNull());
    }, [offerPhotos]);

    return (
        <div className={`${c.bg_grey} w-100 h-100`}>
            <AddNewOffer />
        </div>
    );
};
export default CreateNewOffer;
