import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import userSelectors from '../../../features/user/userSelectors';
import {OfferElementsType} from '../../../models/generalTypes';
import subscriptionOperation from '../../../features/subscription/subscriptionOperation';
import Modal from '../../../common/Modal';
import Select from 'react-select';
import LoaderButtonService from '../../../common/Button/loaderButtonService/LoaderButtonService';
import useWindowSize from '../../../hooks/useWindowSize';
import {useTranslation} from 'react-i18next';
import userOperations from '../../../features/user/userOperations';

import LoaderColor from '../../../common/Button/enum/LoaderColor';

import price from '../../../assets/icons/subscriptions/price.svg';
import closeButton from '../../Acceptances/images/closeButton.svg';

import modalStyles from '../../../styles/modalStyles.module.scss';
import styles from './SubscriptionsModal.module.scss';

interface Props {
    subscriptionId: string | number;
}

const SubscriptionsModal = ({subscriptionId}: Props) => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const userOffers = useSelector(
        userSelectors.getUserOffers.selectAll,
    ) as Array<OfferElementsType>;

    const customStyles = {
        option: (provided: any, state: any) => ({
            ...provided,
            color: 'black',
            backgroundColor: '#fff',
            padding: '5px 5px 5px 15px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '&:hover': {
                backgroundColor: '#d6d6d6',
            },
            '&:active': {
                backgroundColor: '#d6d6d6',
            },
            outline: 'none',
            border: 'none',
            boxShadow: 'none',
        }),
        control: (baseStyles: any, state: any) => ({
            ...baseStyles,
            border: '1px solid #d6d6d6',
            boxShadow: 'none',
            '&:hover': {
                border: '2px solid #d6d6d6',
            },
            '&:active': {
                border: '2px solid #d6d6d6',
            },
            marginTop: '20px',
            height: '50px',
            fontsize: '16px',
            fontWeight: '500',
            cursor: 'pointer',
            width: 'auto',
        }),
        menu: (provided: any) => ({
            ...provided,
            borderRadius: '14px',
            overflow: 'hidden',
            boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.5)',
            zIndex: 9999999,
        }),
        menuPortal: (provided: any) => ({
            ...provided,
            zIndex: 9999,
        }),
    };

    interface TransformedOffer {
        code: string;
        name: string;
        image: string;
    }

    const transformOffers = (offers: Array<OfferElementsType>): TransformedOffer[] => {
        return offers.map(offer => ({
            code: offer.id,
            name: `${offer.price} ${t('subscriptionsPage.subscriptionsModal.currency')} - ${offer.title}`,
            image: price,
        }));
    };

    const [selectOffers, setSelectOffers] = useState({} as TransformedOffer[]);

    useEffect(() => {
        setSelectOffers(transformOffers(userOffers));
    }, [userOffers]);

    const initialOffer = {code: '', name: '', image: ''};

    const [offer, setOffer] = useState(initialOffer);

    const handleSelectChange = (e: any) => {
        setOffer({code: e.code, name: e.name, image: price});
    };

    const formatOptionLabel = (option: TransformedOffer) => (
        <div className={styles.format_main}>
            <img src={option.image} alt={option.name} className={styles.format_img} />
            {option.name}
        </div>
    );

    const {t} = useTranslation();

    const isOfferEmpty = offer.code === '' && offer.image === '' && offer.name === '';

    const {isScreenResolutionMobile} = useWindowSize();

    const dispatch = useDispatch();

    useEffect(() => {
        if (isModalOpen) {
            dispatch(userOperations.getUserOffers({page: 0, size: 10}));
        }
    }, [isModalOpen]);

    return (
        <div>
            <button className={styles.create_btn}
                    onClick={() => setIsModalOpen(!isModalOpen)}>{t('subscriptionsPage.subscriptionsModal.create')}
            </button>
            <Modal
                height={isScreenResolutionMobile ? '230px' : '250px'}
                width={isScreenResolutionMobile ? '100%' : '500px'}
                isOpen={isModalOpen}
                toggle={setIsModalOpen}
            >
                <h4 className={modalStyles.header}>
                    {t('subscriptionsPage.subscriptionsModal.create')}
                </h4>
                <div
                    role="button"
                    tabIndex={1}
                    onKeyDown={() => setIsModalOpen(!isModalOpen)}
                    onClick={() => {
                        setIsModalOpen(!isModalOpen);
                    }}
                    id="animated"
                    className={modalStyles.close}
                >
                    <p>
                        <img src={closeButton} alt="close" />
                    </p>
                </div>
                <div className={modalStyles.line}></div>
                <Select
                    placeholder={isOfferEmpty ? t('subscriptionsPage.subscriptionsModal.placeHolder') : ''}
                    onChange={handleSelectChange}
                    value={isOfferEmpty ? null : offer}
                    name="offer"
                    formatOptionLabel={formatOptionLabel}
                    id={offer.code}
                    isSearchable={false}
                    getOptionLabel={option => {
                        return option.name;
                    }}
                    getOptionValue={option => {
                        return option.code;
                    }}
                    options={selectOffers}
                    styles={customStyles}
                />
                <div className={styles.flex}>
                    <button className={styles.clear_btn} onClick={() => setOffer(initialOffer)}>Очистити</button>
                    <LoaderButtonService
                        query={offer.code !== undefined && !isOfferEmpty && subscriptionOperation.subscriptionAcceptances({
                            offerId: offer.code,
                            subscriptionId: subscriptionId,
                        })}
                        buttonContent={t('subscriptionsPage.subscriptionsModal.sendBtn')}
                        stateCondition={isModalOpen}
                        setStateCondition={setIsModalOpen}
                        buttonClassName={'subscription_modal_btn'}
                        color={LoaderColor.White}
                    />
                </div>
            </Modal>
        </div>
    );
};

export default SubscriptionsModal;