import {combineReducers} from 'redux';
import {configureStore} from '@reduxjs/toolkit';
import {
    persistStore,
    persistReducer,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authSlice from '../features/auth/authSlice';
import userSlice from '../features/user/userSlice';
import offersSlice from '../features/offers/offersSlice';
import filterSlice from '../features/filter/filterSlice';
import geolocationSlice from '../features/geolocation/geolocationSlice';
import notificationSlice from '../features/notification/notificationSlice';
import acceptanceSlice from '../features/acceptance/acceptanceSlice';
import paymentSlice from "../features/payment/paymentSlice";
import subscriptionSlice from "../features/subscription/subscriptionSlice";
import contactSlice from "../features/contact/contactSlice";

const authPersistConfig = {
    key: 'auth',
    storage: storage,
    whitelist: ['authToken', 'isLoggedIn'],
};

const acceptancePersistConfig = {
    key: 'acceptance',
    storage: storage,
    whitelist: ['userOffersAcceptances'],
};

const userPersistConfig = {
    key: 'user',
    storage: storage,
    whitelist: ['userProfile'],
};

const geoPersistConfig = {
    key: 'geolocation',
    storage: storage,
    whitelist: ['cityObj', 'centerMapCity'],
};

const rootReducer = combineReducers({
    notification: notificationSlice.reducer,
    subscription: subscriptionSlice.reducer,
    contact: contactSlice.reducer,
    geolocation: persistReducer(geoPersistConfig, geolocationSlice.reducer),
    filter: filterSlice.reducer,
    offers: offersSlice.reducer,
    payment: paymentSlice.reducer,
    user: persistReducer(userPersistConfig, userSlice.reducer),
    auth: persistReducer(authPersistConfig, authSlice.reducer),
    acceptance: persistReducer(acceptancePersistConfig, acceptanceSlice.reducer),
});

type RootReducerType = typeof rootReducer;

export type AppStateType = ReturnType<RootReducerType>;

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false
        }),
    devTools: process.env.NODE_ENV === 'development',
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;