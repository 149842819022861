import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {OfferCreatorType, OfferElementsType} from '../../models/generalTypes';
import filterOperations from '../filter/filterOperations';
import offersOperations from './offersOperations';
import {acceptanceCreatorAdapter, acceptancesAdapter} from '../acceptance/acceptanceSlice';
import {toast} from 'react-toastify';
import i18n from 'i18next';

export const offerCreatorAdapter = createEntityAdapter<OfferCreatorType>();

interface InitialState {
    isOfferUpdated: boolean,
    photosWhenCreateOffer: any;
    photosFilesWhenCreateOffer: any;
    photosWhenOfferUpdate: any;
    photosFilesWhenOfferUpdate: any;
    formSubmitted: boolean;
    id: string | null;
    latestOffers: any;
    allOffers: any;
    offerWithId: any;
    uploadedPhotosToUserOffer: any;
    allFacilities: any;
    offerCreator: any;
    newOffer: {
        updatedAnimalPermission: boolean;
        updatedCommunalIncluding: boolean;
        city: {code: string, name: string};
        createdOfferFacilities: any;
        facilities: any;
    };
}

const initialState: InitialState = {
    isOfferUpdated: false,
    photosWhenCreateOffer: [],
    formSubmitted: false,
    photosFilesWhenCreateOffer: [],
    photosWhenOfferUpdate: [],
    photosFilesWhenOfferUpdate: [],
    id: null,
    latestOffers: [],
    allOffers: [],
    offerWithId: null,
    uploadedPhotosToUserOffer: null,
    allFacilities: [],
    offerCreator: offerCreatorAdapter.getInitialState(),
    newOffer: {
        createdOfferFacilities: [],
        updatedAnimalPermission: false,
        updatedCommunalIncluding: false,
        city: {code: 'KBP', name: 'Kyiv'},
        facilities: [],
    },
};

type CityType = {
    code: string;
    name: string;
};

const offersSlice = createSlice({
    name: 'offers',
    initialState,
    reducers: {
        setOfferIdNull: {
            reducer: (state, action: any) => {
                state.offerWithId = null;
            },
            prepare: () => {
                return {payload: {}};
            },
        },
        setIdNull: {
            reducer: (state, action: any) => {
                state.id = null;
            },
            prepare: () => {
                return {payload: {}};
            },
        },
        setFormSubmitted: {
            reducer: (state, action: any) => {
                state.formSubmitted = !state.formSubmitted;
            },
            prepare: () => {
                return {payload: {}};
            },
        },
        setPhotosWhenCreateOffer: {
            reducer: (state, action) => {
                state.photosWhenCreateOffer.push(action.payload);
            },
            prepare: (files: any) => {
                return {payload: files} as any;
            },
        },
        setPhotosWhenOfferUpdate: {
            reducer: (state, action) => {
                state.photosWhenOfferUpdate = state.photosWhenOfferUpdate.concat(action.payload);
            },
            prepare: (files: any) => {
                return {payload: files} as any;
            },
        },
        setPhotosFilesWhenCreateOffer: {
            reducer: (state, action) => {
                state.photosFilesWhenCreateOffer.push(action.payload);
            },
            prepare: (files: any) => {
                return {payload: files} as any;
            },
        },
        setPhotosFilesWhenUpdateOffer: {
            reducer: (state, action) => {
                state.photosFilesWhenOfferUpdate.push(action.payload);
            },
            prepare: (files: any) => {
                return {payload: files} as any;
            },
        },
        removePhoto: {
            reducer: (state, action) => {
                state.photosWhenCreateOffer = state.photosWhenCreateOffer
                    .map((item: any) => item
                        .filter((photo: string) => photo !== action.payload));
            },
            prepare: (file: any) => {
                return {payload: file} as any;
            },
        },
        removePhotoOfferWithId: {
            reducer: (state, action) => {
                const photoLocation = state.photosWhenOfferUpdate.indexOf(action.payload);
                state.offerWithId.photos = state.offerWithId.photos
                    .filter((photo: any) => photo.photoLocation !== action.payload);
                if (photoLocation !== -1) {
                    state.photosWhenOfferUpdate = state.photosWhenOfferUpdate
                        .filter((photo: any) => photo !== action.payload);
                    state.photosFilesWhenOfferUpdate.splice(photoLocation, 1);
                }
            },
            prepare: (file: any) => {
                return {payload: file} as any;
            },
        },
        setFacility: {
            reducer: (state, action) => {
                state.newOffer.facilities.includes(
                    action.payload as string,
                ) === false && state.newOffer.facilities.push(action.payload);
            },
            prepare: (fac: string) => {
                return {payload: fac} as any;
            },
        },
        setIsOfferUpdated: {
            reducer: (state, action) => {
                state.isOfferUpdated = false;
            },
            prepare: (isOfferUpdate: boolean) => {
                return {payload: isOfferUpdate} as any;
            },
        },
        setUpdateOfferFacility: {
            reducer: (state, action) => {
                state.offerWithId.facilities.includes(
                    action.payload as string,
                ) === false && state.offerWithId.facilities.push(action.payload);
            },
            prepare: (fac: string) => {
                return {payload: fac} as any;
            },
        },
        removeFacility: {
            reducer: (state, action) => {
                state.newOffer.facilities = state.newOffer.facilities.filter((fac: any) => fac !== action.payload);
            },
            prepare: (fac: string) => {
                return {payload: fac} as any;
            },
        },
        setCityWhenCreateOffer: {
            reducer: (state, action) => {
                state.newOffer.city = action.payload;
            },
            prepare: (city: CityType) => {
                return {payload: city} as any;
            },
        },
        clearOfferCreator: (state, {payload}) => {
            acceptancesAdapter.removeAll(state.offerCreator);
        },
    },
    extraReducers: builder => {
        builder.addCase(
            offersOperations.getAllOffers.fulfilled,
            (state, action) => {
                state.allOffers = action.payload;
            },
        );
        builder.addCase(
            offersOperations.getLatestOffers.fulfilled,
            (state, action) => {
                state.latestOffers = action.payload as Array<OfferElementsType>;
            },
        );
        builder.addCase(
            offersOperations.getOfferWithId.fulfilled,
            (state, action) => {
                state.offerWithId = action.payload;
            },
        );
        builder.addCase(offersOperations.createOffer.fulfilled, (state, action) => {
            state.id = action.payload.id;
            toast.success(i18n.t('notification.offerCreated'));
        });
        builder.addCase(
            offersOperations.uploadOfferPhotos.fulfilled,
            (state, action) => {
                state.uploadedPhotosToUserOffer = action.payload;
            },
        );
        builder.addCase(
            offersOperations.updateOfferWithId.fulfilled,
            (state, action) => {
                state.isOfferUpdated = true;
            },
        );
        builder.addCase(
            filterOperations.getSmartSearchedOffers.fulfilled,
            (state, action) => {
                state.offerWithId = [];
            },
        );
        builder.addCase(
            offersOperations.getFacilities.fulfilled,
            (state, action) => {
                state.allFacilities = action.payload;
            },
        );
        builder.addCase(
            offersOperations.getOfferCreator.fulfilled,
            (state, action) => {
                acceptanceCreatorAdapter.setOne(state.offerCreator, action.payload);
            },
        );
        builder.addCase(
            offersOperations.deleteOfferPhotos.fulfilled,
            (state, action) => {
            },
        );
    },
});

export default offersSlice;