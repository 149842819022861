import React, {Dispatch, SetStateAction} from 'react';
import useLoader from '../../../hooks/useLoader';
import {useDispatch} from 'react-redux';
import {ThreeDots} from 'react-loader-spinner';
import LoaderColor from '../enum/LoaderColor';

import '../../../styles/loaderButtons.scss';

interface Props {
    query: any;
    buttonContent: string;
    buttonClassName: string;
    setStateCondition?: Dispatch<SetStateAction<boolean>>;
    stateCondition?: boolean;
    page?: number;
    setPage?: any;
    color: LoaderColor;
    isBasicModalOpen?: boolean;
    setIsBasicModalOpen?: Dispatch<SetStateAction<boolean>>;
}

const LoaderButtonService: React.FC<Props> = ({
                                                  query,
                                                  buttonContent,
                                                  page,
                                                  setStateCondition,
                                                  stateCondition,
                                                  setPage,
                                                  buttonClassName,
                                                  color,
                                                  isBasicModalOpen,
                                                  setIsBasicModalOpen,
                                              }) => {

    const {startLoading, stopLoading, isLoading} = useLoader();

    const dispatch = useDispatch();

    const handleButtonClick = async () => {
        startLoading();
        try {
            page !== undefined && setPage(page + 1);
            if (!isBasicModalOpen) {
                await dispatch(query);
            } else {
                if (setIsBasicModalOpen) {
                    setIsBasicModalOpen(true);
                }
            }
            if (setStateCondition) {
                setStateCondition(!stateCondition);
            }
        } catch (error) {
            console.log('error', error);
        }
        stopLoading();
    };

    return (
        <>
            <button
                className={buttonClassName}
                onClick={handleButtonClick}
                disabled={isLoading}
            >
                {isLoading ?
                    <div className="centering_loader">
                        <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color={color}
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            visible={true}
                        />
                    </div>
                    :
                    buttonContent
                }
            </button>
        </>
    );
};

export default LoaderButtonService;