import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import qs from 'qs';
import OffersForm from '../../components/OffersForm/OffersForm';
import {
    FilterType,
    OfferElementsType, ResponseRefreshUserSuccessActionPayloadType,
} from '../../models/generalTypes';
import filterSelectors from '../../features/filter/filterSelectors';
import filterOperations from '../../features/filter/filterOperations';
import geolocationSlice from '../../features/geolocation/geolocationSlice';
import offersSlice from '../../features/offers/offersSlice';
import filterSlice from '../../features/filter/filterSlice';
import SubscriptionsForm from '../../components/SubscriptionsForm/SubscriptionsForm';
import userSelectors from '../../features/user/userSelectors';
import userOperations from '../../features/user/userOperations';
import {useTranslation} from 'react-i18next';
import ParentTabs from '../../components/ParentTabs/ParentTabs';
import FilterSmartSearchForm from '../../components/FilterOffersPage/FilterSmartSearchForm/FilterSmartSearchForm';
import FilterApplied from '../../components/FilterOffersPage/FilterApplied/FilterApplied';

import Sort from '../../components/Acceptances/enums/Sort';

import s from './SearchPage.module.scss';

export enum Type {
    Rent = 'RENT',
    Looking = 'LOOKING_FOR_RENT'
}

const SearchPage: React.FC = () => {

    const dispatch = useDispatch();

    const filter = useSelector(filterSelectors.getFilter) as FilterType;

    const filteredOffers: Array<OfferElementsType> = useSelector(
        filterSelectors.getFilteredOffers,
    );

    const type = useSelector(filterSelectors.getType);

    const history = useHistory();

    const [filterFromQuery, setFilterFromQuery] = useState({} as FilterType);

    useEffect(() => {
        const parsed = qs.parse(history.location.search.substring(1), {
            delimiter: '&',
        });

        const actualFilter = {
            ...parsed,
            rooms: parsed.rooms !== undefined ? parsed.rooms.toString().split(',') : parsed.rooms,
        } as FilterType;

        const filtersFromQuery: FilterType | any = {
            type: actualFilter.type,

            city: actualFilter.city,

            animalPermission: actualFilter.animalPermission,

            rooms: actualFilter.rooms,

            areaFrom: actualFilter.areaFrom,
            areaTo: actualFilter.areaTo,

            floorFrom: actualFilter.floorFrom,
            floorTo: actualFilter.floorTo,

            priceFrom: actualFilter.priceFrom,
            priceTo: actualFilter.priceTo,

            buildingFloorFrom: actualFilter.buildingFloorFrom,
            buildingFloorTo: actualFilter.buildingFloorTo,

            currency: actualFilter.currency,

            constructionYearFrom: actualFilter.constructionYearFrom,
            constructionYearTo: actualFilter.constructionYearTo,
        };

        for (const key in filtersFromQuery) {
            // eslint-disable-next-line no-prototype-builtins
            if (filtersFromQuery.hasOwnProperty(key)) {
                if (filtersFromQuery[key] === undefined) delete filtersFromQuery[key];
            }
        }

        if (Object.keys(filtersFromQuery).length !== 0) {
            dispatch(offersSlice.actions.setOfferIdNull());
            dispatch(geolocationSlice.actions.setHoverNull());
            setFilterFromQuery(filtersFromQuery);
            dispatch(filterSlice.actions.setFilter(filtersFromQuery));
        }
    }, []);

    useEffect(() => {
        const query = `currency=${
            filter.currency
        }&country=${filter.country}&city=${filter.city}&state=${
            filter.state
        }&animalPermission=${(filter.animalPermission)
        }&areaFrom=${
            filter.areaFrom === '' ||
            filter.areaFrom === undefined ||
            filter.areaFrom === null
                ? '0'
                : filter.areaFrom
        }&areaTo=${
            filter.areaTo === '' ||
            filter.areaTo === undefined ||
            filter.areaTo === null
                ? '0'
                : filter.areaTo
        }&priceFrom=${
            filter.priceFrom === '' ||
            filter.priceFrom === undefined ||
            filter.priceFrom === null
                ? '0'
                : filter.priceFrom
        }&priceTo=${
            filter.priceTo === '' ||
            filter.priceTo === undefined ||
            filter.priceTo === null
                ? '0'
                : filter.priceTo
        }&rooms=${
            filter.rooms !== undefined && filter.rooms.length !== 0
                ? filter.rooms.toString()
                : '0-0'
        }&floorFrom=${
            filter.floorFrom === '' ||
            filter.floorFrom === undefined ||
            filter.floorFrom === null
                ? '0'
                : filter.floorFrom
        }&floorTo=${
            filter.floorTo === '' ||
            filter.floorTo === undefined ||
            filter.floorTo === null
                ? '0'
                : filter.floorTo
        }&buildingFloorFrom=${
            filter.buildingFloorFrom === '' ||
            filter.buildingFloorFrom === undefined ||
            filter.buildingFloorFrom === null
                ? '0'
                : filter.buildingFloorFrom
        }&buildingFloorTo=${
            filter.buildingFloorTo === '' ||
            filter.buildingFloorTo === undefined ||
            filter.buildingFloorTo === null
                ? '0'
                : filter.buildingFloorTo
        }&constructionYearFrom=${
            filter.constructionYearFrom === '' ||
            filter.constructionYearFrom === undefined ||
            filter.constructionYearFrom === null
                ? '0'
                : filter.constructionYearFrom
        }&constructionYearTo=${
            filter.constructionYearTo === '' ||
            filter.constructionYearTo === undefined ||
            filter.constructionYearTo === null
                ? '0'
                : filter.constructionYearTo
        }&`;

        const parsedQuery = qs.parse(query, {delimiter: '&'});

        type FilterTypeParsed = {
            type?: string | undefined;

            animalPermission?: boolean | undefined | string;

            currency?: string | null | undefined;

            country?: string | number;

            city?: string;

            state?: string;

            areaFrom?: string | number | null;
            areaTo?: string | number | null;

            priceFrom?: string | number | null;
            priceTo?: string | number | null;

            rooms?: string | undefined;

            floorFrom?: string | number | null;
            floorTo?: string | number | null;

            buildingFloorFrom?: string | number | null;
            buildingFloorTo?: string | number | null;

            constructionYearFrom?: string | number | null;
            constructionYearTo?: string | number | null;
        };
        const updValues: FilterTypeParsed = Object.fromEntries(
            Object.entries(parsedQuery).filter(
                n =>
                    n[0] !== '' &&
                    n[1] !== 'undefined' &&
                    n[1] !== 'null' &&
                    n[1] !== 'false' &&
                    n[1] !== undefined &&
                    n[1] !== '' &&
                    n[1] !== null &&
                    n[1] !== '0' &&
                    n[1] !== '0-0' &&
                    n[1] !== '0-0 ',
            ),
        );

        const finalValues = {
            ...updValues,
        };

        const toFilter = qs.stringify(finalValues, {
            delimiter: '&',
            arrayFormat: 'indices',
        });

        history.push({
            pathname: 'offers',
            search: toFilter,
        });
    }, [filter]);

    const [sort, setSort] = useState({
        sort: Sort.Created,
    });
    const serialSortSettings = JSON.stringify(sort);
    const key = 'offers_page_sort';
    sessionStorage.setItem(key, serialSortSettings);

    const settingsSort = JSON.parse(sessionStorage.getItem(key) || '');

    console.log('filter', filter);

    useEffect(() => {
        // if (Object.keys(filterFromQuery).length !== 0) {
            dispatch(filterOperations.getFilteredOffers({filter, size: 10, page: 0, sort: settingsSort.sort}));
            dispatch(userOperations.getUserOffers({page: 0, size: 10}));
        // }
    }, [filter]);

    useEffect(() => {
        if (type === Type.Rent) {
            dispatch(filterOperations.getFilteredOffers({filter, size: 10, page: 0, sort: settingsSort.sort}));
            dispatch(userOperations.getUserOffers({page: 0, size: 10}));
        }
    }, [type]);

    const isChangeType: boolean = useSelector(filterSelectors.isChangeType);

    // useEffect(() => {
    //     if (isChangeType) {
    //         dispatch(filterSlice.actions.removeFilter(filter));
    //     }
    // }, [type]);

    // useEffect(() => {
    //     return () => {
    //         dispatch(filterSlice.actions.setDefaultChangeType(false));
    //         dispatch(filterSlice.actions.setDefaultFilter(filter));
    //     };
    // }, []);

    const {t} = useTranslation();

    const user = useSelector(
        userSelectors.getUserProfile,
    ) as ResponseRefreshUserSuccessActionPayloadType;

    const setLookingType = async () => {
        await dispatch(filterSlice.actions.setType(Type.Looking));
        history.push('/subscriptions');
    };

    useEffect(() => {
        dispatch(filterSlice.actions.setType(Type.Rent));
    }, []);

    return (
        <div>
            <div className={s.flex_container}>
                <div className={s.choice}>
                    <button
                        className={`${s.choice_btn} ${type === Type.Rent && s.choice_btn__underline}`}
                    >
                        {t('searchPage.choice.looking')}
                    </button>
                    {user.hasOffers &&
                      <button
                        onClick={setLookingType}
                        className={`${s.choice_btn} ${type === Type.Looking && s.choice_btn__underline}`}
                      >
                          {t('searchPage.choice.rent')}
                      </button>}
                </div>
                <FilterSmartSearchForm />
                <FilterApplied />
                <OffersForm filteredOffers={filteredOffers} setSort={setSort} />
            </div>
        </div>
    );
};

export default SearchPage;