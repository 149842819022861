enum FieldId {
    Address = 'address',
    Rooms = 'rooms',
    ConstructionYear ='constructionYear',
    Floor = 'floor',
    Floors = 'floors',
    TotalArea = 'totalArea',
    Facility = 'facility',
    AnimalPermission = 'animalPermission',
    Title = 'title',
    Description = 'description',
    Price = 'price',
    PetDeposit = 'petDeposit'
}

export default FieldId;