import React, {useEffect, useState} from 'react';
import Modal from '../../../common/Modal';
import AcceptancesModalFilter from './filter/AcceptancesModalFilter';
import AcceptancesModalItem from './item/AcceptancesModalItem';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import acceptanceSelectors from '../../../features/acceptance/acceptanceSelectors';
import {AcceptanceType, OffersAcceptancesCountType} from '../../../models/generalTypes';
import acceptanceOperations from '../../../features/acceptance/acceptanceOperations';
import acceptanceSlice from '../../../features/acceptance/acceptanceSlice';
import LoaderButtonService from '../../../common/Button/loaderButtonService/LoaderButtonService';
import useWindowSize from '../../../hooks/useWindowSize';

import WindowSize from '../../../enums/WindowSize';
import Status from '../enums/Status';
import Sort from '../enums/Sort';
import LoaderColor from '../../../common/Button/enum/LoaderColor';

import closeButton from '../images/closeButton.svg';

import './AcceptanceModal.scss';
import modalStyles from '../../../styles/modalStyles.module.scss';

interface Props {
    address: string;
    isModalOpen: boolean;
    toggleModal: (arg0: boolean) => any | void;
    offerId: any;
    isNotification?: boolean;
}

const AcceptancesModal: React.FC<Props> = ({address, isModalOpen, toggleModal, offerId, isNotification = false}) => {

    const dispatch = useDispatch();

    const {t} = useTranslation();

    const offerAcceptances: Array<AcceptanceType> = useSelector(
        acceptanceSelectors.getOfferAcceptances.selectAll,
    );

    const getOffersAcceptancesCount: Array<OffersAcceptancesCountType> = useSelector(
        acceptanceSelectors.getOffersAcceptancesCount.selectAll,
    );

    const totalPages = useSelector(acceptanceSelectors.getTotalPages);

    const totalElements = useSelector(acceptanceSelectors.getTotalElementsOfferAcceptances);

    const acceptancesCount = getOffersAcceptancesCount?.filter(count => count.offerId === offerId)[0]?.acceptancesCount;

    const [page, setPage] = useState(0);
    const acceptancesCountOnPage = 3;

    const [filterSettings, setFilterSettings] = useState({
        sort: Sort.Created,
        statuses: Status.Waiting,
    });

    useEffect(() => {
        return () => {
            dispatch(acceptanceSlice.actions.clearOfferAcceptances(offerAcceptances));
        };
    }, []);


    const serialFilterSettings = JSON.stringify(filterSettings);

    const key = 'acceptance_filter_' + offerId;

    localStorage.setItem(key, serialFilterSettings);

    const settings = JSON.parse(localStorage.getItem(key) || '');

    useEffect(() => {
        setPage(1);
        dispatch(
            acceptanceOperations.getOfferAcceptances({
                id: offerId,
                page: page,
                size: acceptancesCountOnPage,
                sort: settings.sort,
                statuses: settings.statuses,
            }),
        );
    }, [offerId]);

    const {isScreenResolutionMobile} = useWindowSize();

    return (
        <Modal
            width={isScreenResolutionMobile ? '100%' : '700px'}
            height={isScreenResolutionMobile ? '100vh' : '650px'}
            isOpen={isModalOpen}
            toggle={toggleModal}
            key={offerId}
        >
            <h4 className={modalStyles.header}>{t('acceptancesModal.header')}
                {!isNotification && getOffersAcceptancesCount.length !== 0 &&
                    `(${acceptancesCount})`
                }
            </h4>
            <div
                role="button"
                tabIndex={1}
                onKeyDown={() => toggleModal(!isModalOpen)}
                onClick={() => {
                    toggleModal(!isModalOpen);
                }}
                id="animated"
                className={modalStyles.close}
            >
                <p>
                    <img src={closeButton} alt="close" />
                </p>
            </div>
            <div className={modalStyles.line}></div>
            <AcceptancesModalFilter offerId={offerId} setFilterSettings={setFilterSettings} setPage={setPage} />
            <div className="scroll">
                {offerAcceptances.length !== 0 &&
                    offerAcceptances.map(acceptance => <AcceptancesModalItem address={address}
                                                                             acceptance={acceptance}
                                                                             key={acceptance.id} />,
                    )}

                {offerAcceptances.length !== 0 &&
                totalPages > 1 &&
                offerAcceptances.length < totalElements ?
                    <LoaderButtonService buttonClassName={'acceptance_modal_more'}
                                         buttonContent={t('acceptancesModal.btn.main')}
                                         color={LoaderColor.White}
                                         query={acceptanceOperations.getOfferAcceptances({
                                             id: offerId,
                                             page: page,
                                             size: acceptancesCountOnPage,
                                             sort: settings.sort,
                                             statuses: settings.statuses,
                                         })}
                                         page={page}
                                         setPage={setPage} /> : null}
            </div>
        </Modal>
    );
};

export default AcceptancesModal;