import React, {ChangeEvent, useEffect, useState} from 'react';
import offersSlice from "../../../features/offers/offersSlice";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {FieldErrors} from "react-hook-form/dist/types/errors";
import {FieldValues, UseFormRegister, UseFormSetValue} from "react-hook-form";

import f from '../falcility/FacilityBlock.module.scss';

interface Props {
    register: UseFormRegister<FieldValues>,
    errors: FieldErrors,
    facilities?: Array<string>,
    setValue?: UseFormSetValue<FieldValues>
}

interface MyObject {
    [key: string]: boolean;
}

const FacilityBlock = ({register, errors, facilities = [], setValue}: Props) => {

    const dispatch = useDispatch();

    const {t} = useTranslation();

    const handleFacilityChange = (e: ChangeEvent<HTMLInputElement>) => {
        !e.target.checked
            ? dispatch(offersSlice.actions.removeFacility(e.target.id))
            : e.target.checked &&
            dispatch(offersSlice.actions.setFacility(e.target.id));
    };

    const facility: Array<string> = [
        'facility.iron',
        'facility.air_condition',
        'facility.wi_fi',
        'facility.washing_machine',
        'facility.oven',
        'facility.fridge',
        'facility.dish_washer',
        'facility.electric_stove',
        'facility.free_parking',
        'facility.car_charger',
        'facility.tv',
    ];

    const [checkboxes, setCheckboxes] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        const initialCheckboxes: { [key: string]: boolean } = {};
        facility.forEach((item) => {
            initialCheckboxes[item] = facilities.includes(item);
        });
        setCheckboxes(initialCheckboxes);
    }, []);

    const convertSelectedCheckboxesToFacilityArray = (obj: MyObject): string[] => {
        const facilityArray: string[] = [];

        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key]) {
                facilityArray.push(key);
            }
        }

        return facilityArray;
    }

    const handleCheckboxClick = (item: string) => {
        setCheckboxes((prevCheckboxes) => {
            const newCheckboxes = {...prevCheckboxes};
            newCheckboxes[item] = !newCheckboxes[item];
            return newCheckboxes;
        });
        dispatch(offersSlice.actions.setUpdateOfferFacility(item));
    };

    useEffect(() => {
        if (setValue && checkboxes) {
            const initialFacility: string[] = convertSelectedCheckboxesToFacilityArray(checkboxes)
            setValue('facilities', initialFacility);
        }
    }, [checkboxes]);

    return (
        <div>
            <h4>{t('createNewOfferPage.facility.title')}</h4>

            <div onChange={handleFacilityChange} className={`${f.facility}`}>
                {facility.map((fac) => (
                    <label key={fac} className="control" htmlFor={fac}>
                        <input
                            checked={checkboxes[fac]}
                            onClick={() => handleCheckboxClick(fac)}
                            type="checkbox"
                            id={fac}
                            value={fac}
                            key="10"
                            {...register('facilities', {
                                required: t('formErrors.requiredFacility').toString(),
                            })}
                        />
                        <span className="content">{t(`createNewOfferPage.${fac}`)}</span>
                    </label>
                ))}
            </div>

            {errors.facility ? (
                <p className="error-message">
                    {errors.facility.message}
                </p>
            ) : (
                <p></p>
            )}
        </div>
    );
}

export default FacilityBlock;