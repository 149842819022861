import React, {useEffect, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import {Link, useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Slider from 'react-slick';
import {useDispatch, useSelector} from 'react-redux';
import {FilterType, OfferElementsType, ResponseRefreshUserSuccessActionPayloadType} from '../../models/generalTypes';
import CreateAcceptance from '../CreateAcceptance/CreateAcceptance';
import LoaderButtonService from '../../common/Button/loaderButtonService/LoaderButtonService';
import SortOffers from './sort/SortOffers';
import offersSelectors from '../../features/offers/offersSelectors';
import geolocationSlice from '../../features/geolocation/geolocationSlice';
import filterSelectors from '../../features/filter/filterSelectors';
import filterOperations from '../../features/filter/filterOperations';
import subscriptionOperation, {SubscribeType} from '../../features/subscription/subscriptionOperation';
import {ThreeDots} from 'react-loader-spinner';
import Popup from './titlePopup/Popup';
import useDateFormatting from '../../hooks/useDateFormatting';
import userSelectors from '../../features/user/userSelectors';
import BasicModal from '../BasicModal/BasicModal';

import LoaderColor from '../../common/Button/enum/LoaderColor';
import DateFormat from '../../hooks/enums/DateFormat';

import address from '../../assets/icons/address.svg';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import offersFormStyles from './OffersForm.module.scss';

type OffersFormPropsType = {
    filteredOffers: Array<OfferElementsType>;
    setSort: any;
};

const OffersForm: React.FC<OffersFormPropsType> = ({filteredOffers, setSort}) => {

    const filter = useSelector(filterSelectors.getFilter) as FilterType;

    const [page, setPage] = useState(1);

    const [responseData, setResponseData] = useState([...filteredOffers]);

    const [showSubscribeBtn, setShowSubscribeBtn] = useState(true);

    const isLoading: boolean = useSelector(filterSelectors.isLoading);

    const {t} = useTranslation();

    const formattedDate = useDateFormatting();

    const LARGE = 'LARGE';

    useEffect(() => {
        setShowSubscribeBtn(true);
    }, [filter]);

    const PrevArrow = (props: any) => {
        const {className, style, onClick} = props;
        return (
            <div
                className={`${className}`}
                style={{...style}}
                onClick={onClick}
            />
        );
    };

    const NextArrow = (props: any) => {
        const {className, style, onClick} = props;
        return (
            <div
                className={`${className}`}
                style={{...style}}
                onClick={onClick}
            />
        );
    };

    const settings = {
        className: 'center',
        centerPadding: '60px',
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        dotsClass: 'slick-dots',
        prevArrow: <PrevArrow className={offersFormStyles.prevArrow} />,
        nextArrow: <NextArrow className={offersFormStyles.nextArrow} />,
    };

    const dispatch = useDispatch();

    const id: string | number | null | any = useSelector(offersSelectors.getId);

    const offerWithId: OfferElementsType = useSelector(
        offersSelectors.getOfferWithId,
    );

    const user = useSelector(
        userSelectors.getUserProfile,
    ) as ResponseRefreshUserSuccessActionPayloadType;

    const handleOver = (lat: number, lng: number) => {
        if (lat !== null && lng !== null) {
            const geo = {
                lat,
                lng,
            };
            dispatch(geolocationSlice.actions.setCoordinateWhenHover(geo));
        }
    };

    const fetchItems = () => {
        dispatch(filterOperations.getFilteredOffers({filter, page}));

        setResponseData([...responseData, ...filteredOffers]);

        setPage(page + 1);
    };

    const totalElements = useSelector(filterSelectors.getTotalElements);

    const totalPages = useSelector(filterSelectors.getTotalPages);

    const key = 'offers_page_sort';
    const settingsSort = JSON.parse(sessionStorage.getItem(key) || '');

    const filterAppliedWidth: number = useSelector(filterSelectors.getFilterAppliedWidth);

    const showBtn = () => {
        return filteredOffers.length !== 0 &&
            totalPages > 1 &&
            filteredOffers.length < totalElements;
    };

    const stylePicker = () => {
        if (filterAppliedWidth < 50 && filterAppliedWidth !== 0) {
            return `${offersFormStyles.scroll_with_filter}`;
        } else if (filterAppliedWidth > 50) {
            return `${offersFormStyles.scroll_with_filter_more}`;
        } else return `${offersFormStyles.scroll}`;
    };

    const [isBasicModalOpen, setIsBasicModalOpen] = useState(false);

    const history = useHistory();

    const goToProfile = async () => {
        history.push('/account/profile');
    };

    useEffect(() => {
        isBasicModalOpen && setShowSubscribeBtn(true);
    }, [isBasicModalOpen]);

    return (
        <div className={offersFormStyles.box}>
            <SortOffers totalElements={totalElements} setSort={setSort} setPage={setPage} />
            <div className={stylePicker()}>
                {filteredOffers.length !== 0 &&
                id === null &&
                offerWithId === null ? (
                        <InfiniteScroll
                            loadMore={fetchItems}
                            loader={
                                <h4>
                                    <ThreeDots
                                        height="80"
                                        width="80"
                                        radius="9"
                                        color={'34a790'}
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        visible={true}
                                    />
                                </h4>}
                        >
                            {filteredOffers.map(item => (
                                <li className={offersFormStyles.flex_item} key={item.id}>
                                    <div
                                        onMouseOver={() =>
                                            item.geolocation !== null
                                            && handleOver(
                                                item.geolocation.latitude,
                                                item.geolocation.longitude,
                                            )
                                        }
                                        className={offersFormStyles.link1}
                                    >
                                        <div className={offersFormStyles.flex_container}>
                                            {item.photos.length !== 0 ? (
                                                <Slider {...settings} className={offersFormStyles.slider}>
                                                    {item.photos
                                                        .filter(photo => photo.photoSize === LARGE)
                                                        .map(photo => (
                                                            <div className={offersFormStyles.slider_item} key={photo.id}>
                                                                <img
                                                                    className={offersFormStyles.slider_img}
                                                                    alt="hata"
                                                                    src={photo.photoLocation}
                                                                />
                                                            </div>
                                                        ))}
                                                </Slider>
                                            ) : (
                                                <Slider {...settings} className={offersFormStyles.slider}>
                                                    <img
                                                        className={offersFormStyles.no_photo}
                                                        alt="haddta"
                                                        src="https://images.all-free-download.com/images/graphicwebp/bird_houses_208582.webp"
                                                    />
                                                </Slider>
                                            )}

                                            <div className={offersFormStyles.flex__item}>
                                                <Popup title={item.title} text={item.title} />
                                                <div className={offersFormStyles.price}>
                                                    {item.price} {item.currency} / {t('offersForm.offer.price')}
                                                </div>

                                                <div className={offersFormStyles.addressContainer}>
                                                    <img src={address} alt="address"
                                                         className={offersFormStyles.address_svg} />
                                                    <span className={offersFormStyles.address}>
                                                        {item.geolocation.address}
                                                    </span>
                                                </div>
                                                <div className={offersFormStyles.rooms}>
                                                    <div
                                                        className={offersFormStyles.size}>{item.rooms} {t('offersForm.offer.room')}</div>
                                                    <span
                                                        className={offersFormStyles.indent}>{item.totalArea} / {item.livingArea} / {item.kitchenArea} {t('offersForm.offer.unit')}</span>
                                                </div>
                                                <div className={offersFormStyles.rooms}>
                                                    <div
                                                        className={offersFormStyles.size}>{t('offersForm.offer.floor')}</div>
                                                    <span className={offersFormStyles.indent}>
                                                        {item.floor} / {item.floors}{' '}
                                                    </span>
                                                </div>

                                                <div className={offersFormStyles.rooms}>
                                                    <span className={offersFormStyles.date}>
                                                        Вільна з
                                                    </span>
                                                    <span className={offersFormStyles.indent}>
                                                        {formattedDate(item.availableFrom, DateFormat.Normal)}
                                                    </span>
                                                </div>

                                                <div className={offersFormStyles.acceptance}>
                                                    <CreateAcceptance
                                                        offerId={item.id}
                                                        offer={item}
                                                    />
                                                </div>

                                                <div className={offersFormStyles.flex_row}>
                                                    <div className={offersFormStyles.posted}>
                                                        {formattedDate(item.createdOn, DateFormat.Normal)}
                                                    </div>
                                                    <Link className={offersFormStyles.link} to={`/offers/${item.id}`}>
                                                        <div className={offersFormStyles.details}>
                                                            <h6>{t('offersForm.offer.details')}</h6>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </InfiniteScroll>
                    ) :
                    (!isLoading && showSubscribeBtn ?
                            <div className={offersFormStyles.subscribe_div}>
                                <p className={offersFormStyles.subscribe_header}>{t('offersForm.subscribeHeader')}</p>
                                <LoaderButtonService
                                    query={subscriptionOperation.subscribe({
                                        city: filter.city,
                                        rooms: filter.rooms,
                                        priceTo: filter.priceTo,
                                        priceFrom: filter.priceFrom,
                                    } as SubscribeType)}
                                    isBasicModalOpen={user && user.description === 'null'}
                                    setIsBasicModalOpen={setIsBasicModalOpen}
                                    color={LoaderColor.White}
                                    buttonContent={t('offersForm.subscribeBtn')}
                                    buttonClassName={'subscription_btn'}
                                    stateCondition={showSubscribeBtn}
                                    setStateCondition={setShowSubscribeBtn}
                                />
                            </div>
                            :
                            <div className={offersFormStyles.centering_form_loader}>
                                {!showSubscribeBtn && !isLoading ?
                                    <p className={offersFormStyles.message}>
                                        {t('offersForm.message')}
                                    </p>
                                    :
                                    <ThreeDots
                                        height="80"
                                        width="80"
                                        radius="9"
                                        color={LoaderColor.Green}
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        visible={true}
                                    />}
                            </div>
                    )}
                <div className={offersFormStyles.more_offers}>
                    {showBtn() ?
                        <LoaderButtonService
                            query={filterOperations.getFilteredOffers(
                                {filter, size: 10, page: page, sort: settingsSort.sort},
                            )}
                            color={LoaderColor.White}
                            page={page}
                            setPage={setPage}
                            buttonContent={t('offersForm.offer.more')}
                            buttonClassName={'btn_more_offers_page'}
                        /> : null
                    }
                </div>
            </div>
            <BasicModal isModalOpen={isBasicModalOpen} toggleModal={setIsBasicModalOpen}
                        header={t('offersForm.basicModal.header')}
                        question={t('offersForm.basicModal.question')}
                        leftButton={t('offersForm.basicModal.leftBtn')}
                        rightButton={t('offersForm.basicModal.rightBtn')}
                        onClick={goToProfile}
            />
        </div>
    );
};

export default OffersForm;