import React from 'react';
import Modal from '../../common/Modal';
import useWindowSize from '../../hooks/useWindowSize';
import LoaderButtonService from '../../common/Button/loaderButtonService/LoaderButtonService';

import closeButton from '../Acceptances/images/closeButton.svg';

import LoaderColor from '../../common/Button/enum/LoaderColor';
import WindowSize from '../../enums/WindowSize';

import modalStyles from '../../styles/modalStyles.module.scss';
import basicModalStyles from './BasicModal.module.scss';

interface Props {
    isModalOpen: boolean;
    toggleModal: (arg0: boolean) => any | void;
    header: string;
    question: string;
    leftButton: string;
    rightButton: string;
    onClick: CallableFunction;
    additionalFunction?: CallableFunction;
}

const BasicModal: React.FC<Props> = ({
                                         isModalOpen,
                                         toggleModal,
                                         header,
                                         question,
                                         leftButton,
                                         rightButton,
                                         onClick,
                                         additionalFunction = undefined,
                                     }) => {

    const {isScreenResolutionMobile} = useWindowSize();

    return (
        <Modal
            height={isScreenResolutionMobile ? '300px' : 'auto'}
            width={isScreenResolutionMobile ? '100%' : '650px'}
            isOpen={isModalOpen}
            toggle={toggleModal}
        >
            <h4 className={modalStyles.header}>
                {header}
            </h4>
            <div
                role="button"
                tabIndex={1}
                onKeyDown={() => toggleModal(!isModalOpen)}
                onClick={() => {
                    toggleModal(!isModalOpen);
                }}
                className={modalStyles.close}
            >
                <p>
                    <img src={closeButton} alt="close" />
                </p>
            </div>
            <div className={modalStyles.line}></div>
            <div className={basicModalStyles.question}>{question}</div>
            <div className={modalStyles.flex_button}>
                <button
                    onClick={() => {
                        toggleModal(!isModalOpen);
                        if (additionalFunction !== undefined) {
                            additionalFunction();
                        }
                    }}
                    className={modalStyles.cancel_btn}
                >
                    {leftButton}
                </button>

                <LoaderButtonService query={onClick} buttonContent={rightButton}
                                     buttonClassName={modalStyles.remove_btn} color={LoaderColor.White} />
            </div>
        </Modal>
    );
};

export default BasicModal;