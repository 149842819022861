import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {FilterType} from '../../../models/generalTypes';
import Modal from '../../../common/Modal/Modal';
import filterSlice from '../../../features/filter/filterSlice';
import filterSelectors from '../../../features/filter/filterSelectors';
import {useForm} from 'react-hook-form';
import useWindowSize from '../../../hooks/useWindowSize';
import geolocationOperations from '../../../features/geolocation/geolocationOperations';

import WindowSize from '../../../enums/WindowSize';

import close from '../../../assets/icons/close.svg';

import './FilterPopUpForm.scss';
import modalStyles from '../../../styles/modalStyles.module.scss';

type DropDownCurrencyPricePropsType = {
    width?: string;
    borderRadius?: string;
};

const FilterPopUpForm: React.FC<DropDownCurrencyPricePropsType> = ({width, borderRadius}) => {

    const {t} = useTranslation();

    const dispatch = useDispatch();

    const filter = useSelector(filterSelectors.getFilter) as FilterType;

    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm({
        defaultValues: {
            rooms: filter.rooms,
            priceFrom: filter.priceFrom,
            priceTo: filter.priceTo,
            floorFrom: filter.floorFrom,
            floorTo: filter.floorTo,
            buildingFloorFrom: filter.buildingFloorFrom,
            buildingFloorTo: filter.buildingFloorTo,
            areaFrom: filter.areaFrom,
            areaTo: filter.areaTo,
            constructionYearFrom: filter.constructionYearFrom,
            constructionYearTo: filter.constructionYearTo,
            animalPermission: filter.animalPermission,
        },
    });

    const ref = React.useRef<HTMLDivElement>(null);

    const [isModalOpen, toggleModal] = useState(false);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);
        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isMenuOpen]);

    const submit = async (values: any) => {
        const rooms = values.rooms;
        if (rooms.length === 0 || rooms === 0) {
            values.rooms = [];
        }
        await dispatch(filterSlice.actions.setFilter(values as FilterType));
        toggleModal(!isModalOpen);
    };

    useEffect(() => {
        reset(filter);
    }, [filter]);

    useEffect(() => {
        dispatch(geolocationOperations.getCities());
    }, []);

    const {isScreenResolutionMobile} = useWindowSize();

    return (
        <div>
            <div
                role="button"
                tabIndex={0}
                style={{
                    width,
                    borderRadius,
                }}
                className="selectors"
                onKeyDown={() => toggleModal(!isModalOpen)}
                onClick={() => toggleModal(!isModalOpen)}
            >
                <span className="">
                  {t('filterModal.otherFilters')}
                </span>
            </div>

            <Modal
                width={isScreenResolutionMobile ? '100%' : '50%'}
                height={isScreenResolutionMobile ? '100%' : '570px'}
                isOpen={isModalOpen}
                toggle={toggleModal}
            >
                <div
                    role="button"
                    onKeyDown={() => toggleModal(!isModalOpen)}
                    onClick={() => {
                        toggleModal(!isModalOpen);
                    }}
                    className={modalStyles.close}
                >
                    <p>
                        <img src={close} alt="close" />
                    </p>
                </div>

                <h4 className={modalStyles.header}>{t('filterModal.otherFilters')}</h4>

                <div className={modalStyles.line}></div>

                <div className="scrollBox">
                    <form onSubmit={handleSubmit(submit)}>
                        <div className="scroll_filter">
                            <div className="flex-row__filter h-0">
                                <p className="mb-1">{t('filterModal.floor')}</p>
                                <div className="flex_row_pop">
                                    <div className="flex-col">
                                        <input
                                            type="number"
                                            className={
                                                errors.floorFrom
                                                    ? ' bl  error-input input-text inputs'
                                                    : 'bl input-text form-control inputs'
                                            }
                                            placeholder={t('filterModal.from')}
                                            {...register('floorFrom', {
                                                min: {
                                                    value: 1,
                                                    message: 'min > 0',
                                                },
                                            })}
                                        />
                                        {errors.floorFrom ? (
                                            <p className="error-message">
                                                {errors.floorFrom.message}
                                            </p>
                                        ) : (
                                            <p></p>
                                        )}
                                    </div>

                                    <div className="flex-col">
                                        <input
                                            type="number"
                                            className={
                                                errors.floorTo
                                                    ? ' bl  error-input input-text inputs'
                                                    : 'bl input-text form-control inputs'
                                            }
                                            placeholder={t('filterModal.to')}
                                            {...register('floorTo', {
                                                min: {
                                                    value: 1,
                                                    message: 'min > 0',
                                                },
                                            })}
                                        />
                                        {errors.floorTo ? (
                                            <p className="error-message">
                                                {errors.floorTo.message}
                                            </p>
                                        ) : (
                                            <p></p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="flex-row__filter h-0">
                                <p className="mb-1">{t('filterModal.building_floor')}</p>
                                <div className="flex_row_pop">
                                    <div className="flex-col">
                                        <input
                                            type="number"
                                            className={
                                                errors.buildingFloorFrom
                                                    ? ' bl  error-input input-text inputs'
                                                    : 'bl input-text form-control inputs'
                                            }
                                            placeholder={t('filterModal.from')}
                                            {...register('buildingFloorFrom', {
                                                min: {
                                                    value: 1,
                                                    message: 'min > 0',
                                                },
                                            })}
                                        />
                                        {errors.buildingFloorFrom ? (
                                            <p className="error-message">
                                                {errors.buildingFloorFrom.message}
                                            </p>
                                        ) : (
                                            <p></p>
                                        )}
                                    </div>

                                    <div className="flex-col">
                                        <input
                                            type="number"
                                            className={
                                                errors.buildingFloorTo
                                                    ? ' bl  error-input input-text inputs'
                                                    : 'bl input-text form-control inputs'
                                            }
                                            placeholder={t('filterModal.to')}
                                            {...register('buildingFloorTo', {
                                                min: {
                                                    value: 1,
                                                    message: 'min > 0',
                                                },
                                            })}
                                        />
                                        {errors.buildingFloorTo ? (
                                            <p className="error-message">
                                                {errors.buildingFloorTo.message}
                                            </p>
                                        ) : (
                                            <p></p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="flex-row__filter h-0">
                                <p className="mb-1">{t('filterModal.total_area')}</p>
                                <div className="flex_row_pop">
                                    <div className="flex-col">
                                        <input
                                            type="number"
                                            className={
                                                errors.areaFrom
                                                    ? ' bl  error-input input-text inputs'
                                                    : 'bl input-text form-control inputs'
                                            }
                                            placeholder={t('filterModal.from')}
                                            {...register('areaFrom', {
                                                min: {
                                                    value: 1,
                                                    message: 'min > 0',
                                                },
                                            })}
                                        />
                                        {errors.areaFrom ? (
                                            <p className="error-message">
                                                {errors.areaFrom.message}
                                            </p>
                                        ) : (
                                            <p></p>
                                        )}
                                    </div>
                                    <div className="flex-col">
                                        <input
                                            type="number"
                                            className={
                                                errors.areaTo
                                                    ? ' bl  error-input input-text inputs'
                                                    : 'bl input-text form-control inputs'
                                            }
                                            placeholder={t('filterModal.to')}
                                            {...register('areaTo', {
                                                min: {
                                                    value: 1,
                                                    message: 'min > 0',
                                                },
                                            })}
                                        />
                                        {errors.areaTo ? (
                                            <p className="error-message">
                                                {errors.areaTo.message}
                                            </p>
                                        ) : (
                                            <p></p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="flex-row__filter h-0">
                                <p className="mb-1">{t('filterModal.construction_year')}</p>
                                <div className="flex_row_pop">
                                    <div className="flex-col">
                                        <input
                                            type="number"
                                            className={
                                                errors.constructionYearFrom
                                                    ? ' bl  error-input input-text inputs'
                                                    : 'bl input-text form-control inputs'
                                            }
                                            placeholder={t('filterModal.from')}
                                            {...register('constructionYearFrom', {
                                                min: {
                                                    value: 1,
                                                    message: 'min > 0',
                                                },
                                            })}
                                        />
                                        {errors.constructionYearFrom ? (
                                            <p className="error-message">
                                                {errors.constructionYearFrom.message}
                                            </p>
                                        ) : (
                                            <p></p>
                                        )}
                                    </div>
                                    <div className="flex-col">
                                        <input
                                            type="number"
                                            className={
                                                errors.constructionYearTo
                                                    ? ' bl  error-input input-text inputs'
                                                    : 'bl input-text form-control inputs'
                                            }
                                            placeholder={t('filterModal.to')}
                                            {...register('constructionYearTo', {
                                                min: {
                                                    value: 1,
                                                    message: 'min > 0',
                                                },
                                            })}
                                        />
                                        {errors.constructionYearTo ? (
                                            <p className="error-message">
                                                {errors.constructionYearTo.message}
                                            </p>
                                        ) : (
                                            <p></p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="flex_row_pop flex-row__checkbox h-0">
                                <p className="animal mb-1 mt-1">
                                    {t('filterModal.animal_permission')}
                                </p>

                                <input
                                    className="checkbox"
                                    type="checkbox"
                                    {...register('animalPermission', {})}
                                />
                            </div>
                        </div>

                        <div className="abs">
                            <div className="line_bottom" />
                            <div className="flex-row__filter flex-row__space">
                                <input
                                    className="clear_btn"
                                    value={t('filterModal.clear').toString()}
                                    type="reset"
                                    onClick={async () => {
                                        reset();
                                        await dispatch(filterSlice.actions.removeFilter(filter));
                                    }}
                                />
                                <button
                                    type="submit"
                                    className="apply_btn"
                                >
                                    {t('filterModal.find')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    );
};

export default FilterPopUpForm;
