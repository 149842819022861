import React from 'react';
import DropDownCurrencyPrice from '../../FilterHomePage/DropDownCurrencyPrice/DropDownCurrencyPrice';
import DropdownLink from '../../FilterHomePage/DropDownRooms/DropdownLink';
import FilterPopUpForm from '../FilterPopUpForm/FilterPopUpForm';
import useWindowSize from '../../../hooks/useWindowSize';
import usePrevScrollSticky from '../../../hooks/usePrevScrollSticky';
import {useHistory} from 'react-router-dom';

import './FilterSmartSearchForm.scss';

const FilterSmartSearchForm: React.FC = () => {

    const isSticky = usePrevScrollSticky();

    const {isScreenResolutionMobile} = useWindowSize();

    const history = useHistory();

    const isSubscriptionsPage = history.location.pathname !== '/subscriptions';

    return (
        <div
            className={`${isSubscriptionsPage ? 'wrapper' : 'wrapper_without_other'} ${isSticky && !isScreenResolutionMobile ? 'sticky' : ''}`}>
            <div className="flex-row__filters">
                <div className="full_length">
                    <DropDownCurrencyPrice />
                </div>
                {isSubscriptionsPage &&
                  <div className="full_length">
                    <DropdownLink />
                  </div>}
                {isSubscriptionsPage &&
                  <div className="full_length">
                    <FilterPopUpForm />
                  </div>}
            </div>
        </div>
    );
};

export default FilterSmartSearchForm;