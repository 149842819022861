import { AppStateType } from '../../redux/store';

// const getAllOffers = (state: AppStateType) => state.offers.allOffers;
//
// const getOfferWithId = (state: AppStateType) => state.offers.offerWithId;

// const uploadedPhotosToUserOffer = (state: AppStateType) =>
// state.offers.uploadedPhotosToUserOffer;

const getAddress = (state: AppStateType) => state.geolocation.address;

export const getFetchedCities = (state: AppStateType) =>
  state.geolocation.allCities;

export const getCityMarkers = (state: AppStateType) =>
  state.geolocation.cityMarkers;

export const getCenterMapCity = (state: AppStateType) =>
  state.geolocation.centerMapCity;

export const getCity = (state: AppStateType) => state.geolocation.cityObj;

export const getHoveredGeo = (state: AppStateType) =>
  state.geolocation.hoveredCoordinates;

const geolocationSelectors = {
  getCity,
  getAddress,
  getFetchedCities,
  getCityMarkers,
  getCenterMapCity,
  getHoveredGeo,
};

export default geolocationSelectors;
