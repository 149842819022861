import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import authSelectors from '../../features/auth/authSelectors';
import AuthModal from '../Authentification/AuthModal/AuthModal';
import paymentSelectors from '../../features/payment/paymentSelectors';
import userSelectors from '../../features/user/userSelectors';
import {ResponseRefreshUserSuccessActionPayloadType} from '../../models/generalTypes';
import BasicModal from '../BasicModal/BasicModal';
// import PriceModal from '../Payment/NavigationBalanceBlock/modal/PriceModal';

import add from '../../assets/icons/createOfferBtn/add.svg';

import './Button.scss';

const CreateOfferButton = () => {

    const isAuth = useSelector(authSelectors.getIsAuthenticated);

    const {t} = useTranslation();

    const history = useHistory();

    const [isModalOpen, toggleModal] = useState(false);

    const [isPriceModalOpen, togglePriceModal] = useState(false);

    type PricesType = {
        serviceName: string,
        price: string | number
    }

    const prices: Array<PricesType> = useSelector(paymentSelectors.getPrices);

    const createOfferPrice = prices.filter(item => item.serviceName === 'createOffer').map(item => item.price)[0];

    const balance = useSelector(paymentSelectors.getBalance);

    const user = useSelector(
        userSelectors.getUserProfile,
    ) as ResponseRefreshUserSuccessActionPayloadType;

    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

    const routeChange = async () => {
        // if (balance < createOfferPrice) {
        //     await togglePriceModal(!isPriceModalOpen);
        if (!user.emailConfirmed || !user.phoneConfirmed) {
            console.log('123123');
            await setIsConfirmationModalOpen(!isConfirmationModalOpen);
        } else if (isAuth) {
            history.push('/create');
        } else toggleModal(!isModalOpen);
    };

    const confirm = () => {
        setIsConfirmationModalOpen(false);
        history.push('/account/profile');
    };

    return (
        <div>
            {/*{isPriceModalOpen &&*/}
            {/*  <PriceModal isModalOpen={isPriceModalOpen} toggleModal={togglePriceModal} lackBalance={true} />}*/}
            <BasicModal isModalOpen={isConfirmationModalOpen}
                        toggleModal={setIsConfirmationModalOpen}
                        header={t('confirmationModal.header')}
                        question={t('confirmationModal.question')}
                        leftButton={t('confirmationModal.btn.cancel')}
                        rightButton={t('confirmationModal.btn.confirm')}
                        onClick={confirm}
            />
            {isAuth && (
                <button
                    className="create_offer"
                    onClick={routeChange}
                >
                    <div className="flex-row_create">
                        <img src={add} alt="add" className="add_svg" />
                        {t('createOffer')}
                    </div>
                </button>)}
            {isModalOpen && <AuthModal isAuthModalOpen={isModalOpen} toggleAuthModal={toggleModal} />}
        </div>
    );
};

export default CreateOfferButton;