import React from "react";
import {Controller} from "react-hook-form";

interface Props {
    fieldName: string,
    inputClassName: string,
    placeHolder?: string
    control: any;
}

const ControlInputNumber: React.FC<Props> = ({fieldName, inputClassName, placeHolder, control}) => {

    const formatNumber = (input: string) => {
        return input.replace(/[^\d]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    const onChangeNumberInput = (value: string) => {
        return formatNumber(value);
    };

    return (
        <Controller
            name={fieldName}
            control={control}
            defaultValue=""
            render={({field}) => (
                <input
                    {...field}
                    type="text"
                    className={inputClassName}
                    placeholder={placeHolder}
                    onChange={(e) => {
                        const formattedValue = onChangeNumberInput(e.target.value);
                        field.onChange(formattedValue);
                    }}
                />
            )}
        />
    )
}

export default ControlInputNumber;