import {AppStateType} from '../../redux/store';
import {
    acceptanceCreatorAdapter,
    acceptancesAdapter,
    offersAcceptancesCountAdapter, updateAcceptanceAdapter,
    userAcceptancesAdapter
} from "./acceptanceSlice";

export const getCreatedAcceptance = (state: AppStateType) =>
    state.acceptance.createdAcceptance;

export const getUpdatedAcceptance = (state: AppStateType) =>
    state.acceptance.updatedAcceptance;

export const getUserAcceptances = userAcceptancesAdapter.getSelectors<AppStateType>(
    (state) => state.acceptance.userAcceptances
);

export const getOfferAcceptances = acceptancesAdapter.getSelectors<AppStateType>(
    (state) => state.acceptance.offerAcceptances
);

export const getTotalPages = (state: AppStateType) =>
    state.acceptance.totalPagesOfferAcceptances;

export const getTotalElementsUserAcceptances = (state: AppStateType) =>
    state.acceptance.totalElementsUserAcceptances;

export const getPageElementsCountUserAcceptances = (state: AppStateType) =>
    state.acceptance.pageElementsCountUserAcceptances;

export const getTotalElementsOfferAcceptances = (state: AppStateType) =>
    state.acceptance.totalElementsOfferAcceptances;

export const getAcceptanceById = (state: AppStateType) =>
    state.acceptance.acceptanceById;

export const getOffersAcceptancesCount = offersAcceptancesCountAdapter.getSelectors<AppStateType>(
    (state) => state.acceptance.offersAcceptancesCount
);

export const getUpdateAcceptance = updateAcceptanceAdapter.getSelectors<AppStateType>(
    (state) => state.acceptance.updatedAcceptance
);

export const updateAcceptanceStatus = (state: AppStateType) =>
    state.acceptance.updatedAcceptanceStatus;

export const getAcceptanceCreator = acceptanceCreatorAdapter.getSelectors<AppStateType>(
    (state) => state.acceptance.acceptanceCreator
);

const acceptanceSelectors = {
    getCreatedAcceptance,
    getUpdatedAcceptance,
    getUserAcceptances,
    getOfferAcceptances,
    getOffersAcceptancesCount,
    updateAcceptanceStatus,
    getAcceptanceCreator,
    getTotalPages,
    getTotalElementsUserAcceptances,
    getTotalElementsOfferAcceptances,
    getUpdateAcceptance,
    getPageElementsCountUserAcceptances,
    getAcceptanceById
};

export default acceptanceSelectors;
