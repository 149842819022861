import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import notificationSelectors from '../../../features/notification/notificationSelectors';
import {Link, useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import authSelectors from '../../../features/auth/authSelectors';
import authSlice from '../../../features/auth/authSlice';
import notificationOperations from '../../../features/notification/notificationOperations';
import CreateOfferButton from '../../Buttons/CreateOfferButton';
import userOperations from '../../../features/user/userOperations';
import userSelectors from '../../../features/user/userSelectors';
import {ResponseRefreshUserSuccessActionPayloadType} from '../../../models/generalTypes';
import AuthModal from '../AuthModal/AuthModal';

import unread from '../../../assets/icons/notification/unread.svg';
import close from '../../../assets/icons/close.svg';
import mobileIcon from '../../../assets/icons/profile/profile-mobile-icon.svg';

import authProfileStyles from './AuthProfileDropdownMobile.module.scss';
import userSlice from '../../../features/user/userSlice';

const key = "filter_checked";

const AuthProfileDropdownMobile = () => {

    const [isDropDownOpen, toggleDropDown] = useState(false);

    const dispatch = useDispatch();

    const history = useHistory();

    const [isModalOpen, toggleModal] = useState(false);

    const {t} = useTranslation();

    const unreadCountNotifications = useSelector(notificationSelectors.getUnreadCountNotifications);

    const acceptancesCount = useSelector(userSelectors.getUserAcceptancesCount).length;

    const isAuthenticated: boolean = useSelector(authSelectors.getIsAuthenticated);

    const user = useSelector(
        userSelectors.getUserProfile,
    ) as ResponseRefreshUserSuccessActionPayloadType;

    const logout = () => {
        dispatch(authSlice.actions.setAuthTokenNull(''));
        dispatch(userSlice.actions.clearUserProfile(''));
        toggleDropDown(false);
        history.replace('/');
    };

    useEffect(() => {
        isAuthenticated && dispatch(userOperations.getAcceptancesCountToUserOffer());
        isAuthenticated && dispatch(notificationOperations.getUnreadCountNotifications());
    }, []);

    useEffect(() => {
        isAuthenticated && dispatch(userOperations.getUserProfile());
    }, [isAuthenticated]);

    useEffect(() => {
        isAuthenticated && isDropDownOpen && dispatch(userOperations.getAcceptancesCountToUserOffer());
        isAuthenticated && isDropDownOpen && dispatch(notificationOperations.getUnreadCountNotifications());
    }, [isDropDownOpen]);

    useEffect(() => {
        if (isDropDownOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isDropDownOpen]);

    const isUnread = () => {
        return unreadCountNotifications > 0 || acceptancesCount > 0;
    };

    return (
        <>
            <div onClick={() => toggleDropDown(!isDropDownOpen)} className={authProfileStyles.btn}>
                {isDropDownOpen ?
                    <img src={close} alt="close" />
                    :
                    <>{user && isUnread() &&
                      <img src={unread} alt="circleCheck" className={authProfileStyles.unread} />}
                        <img src={mobileIcon} alt="mobileIcon"
                             className={isAuthenticated ? `${authProfileStyles.mobile_icon}` : ''} />
                    </>}

            </div>

            {isDropDownOpen &&
                (isAuthenticated ?
                        <div className={authProfileStyles.dropdown_mobile}>
                            <div className={authProfileStyles.balance}>
                                {/*<NavigationBalanceBlockBtn/>*/}
                            </div>
                            <div className={`${authProfileStyles.link_column} mt-3`}>
                                <Link className={authProfileStyles.link_column__item} to="/account/profile"
                                      onClick={() => toggleDropDown(false)}>
                                    {t('auth.profile.account')}
                                </Link>

                                {user && user.hasOffers &&
                                  <Link
                                    className={`${authProfileStyles.link_column__item} ${authProfileStyles.flex_total}`}
                                    to="/account/offers"
                                    onClick={() => {
                                        localStorage.setItem(key, String(true));
                                        toggleDropDown(false);
                                    }}>
                                      {t('auth.profile.offers')}
                                      {acceptancesCount > 0 &&
                                        <img src={unread} alt="circleCheck" />
                                      }
                                  </Link>}

                                {user && user.hasAcc &&
                                  <Link className={authProfileStyles.link_column__item} to="/account/acceptances"
                                        onClick={() => toggleDropDown(false)}>
                                      {t('auth.profile.acceptances')}
                                  </Link>}

                                <Link
                                    className={`${authProfileStyles.link_column__item} ${authProfileStyles.flex_total}`}
                                    to="/account/notifications"
                                    onClick={() => toggleDropDown(false)}>
                                    {t('auth.profile.notifications')}
                                    {unreadCountNotifications > 0 &&
                                      <img src={unread} alt="circleCheck" />
                                    }
                                </Link>

                                <div className={authProfileStyles.link_column__item}
                                     onClick={logout}>
                                    {t('auth.profileMobile.logout')}
                                </div>
                            </div>

                            <div className={authProfileStyles.line}></div>

                            <div className={authProfileStyles.link_column}>
                                <Link className={authProfileStyles.link_column__item} to="/privacy"
                                      onClick={() => toggleDropDown(false)}>
                                    {t('auth.profileMobile.securityPolicy')}
                                </Link>

                                <Link className={authProfileStyles.link_column__item} to="/privacy"
                                      onClick={() => toggleDropDown(false)}>
                                    {t('auth.profileMobile.rules')}
                                </Link>
                            </div>

                            <div className={authProfileStyles.line}></div>

                            {/*<div className={authProfileStyles.localize}>*/}
                            {/*    <LocalizeButtons/>*/}
                            {/*</div>*/}

                            {/*<div className={authProfileStyles.line}></div>*/}

                            <div className={authProfileStyles.create_offer}>
                                <div
                                    className={authProfileStyles.create_offer__btn}
                                    // onClick={() => toggleDropDown(false)}
                                >
                                    <CreateOfferButton />
                                </div>
                            </div>
                        </div>
                        :
                        <div className={authProfileStyles.dropdown_mobile}>
                            <div className={authProfileStyles.balance}>
                                {/*<NavigationBalanceBlockBtn/>*/}
                            </div>
                            <div className={`${authProfileStyles.link_column} mt-3`}>
                                <a className={authProfileStyles.link_column__item} href="#about"
                                   onClick={() => toggleDropDown(false)}>
                                    {t('auth.profileMobile.about')}
                                </a>
                                <div className={authProfileStyles.line}></div>
                                <a className={authProfileStyles.link_column__item} href="#steps"
                                   onClick={() => toggleDropDown(false)}>
                                    {t('auth.profileMobile.steps')}
                                </a>
                                <div className={authProfileStyles.line}></div>
                                <a className={authProfileStyles.link_column__item} href="#advantages"
                                   onClick={() => toggleDropDown(false)}>
                                    {t('auth.profileMobile.advantages')}
                                </a>
                                <div className={authProfileStyles.line}></div>
                            </div>
                            <div className={authProfileStyles.start_div}>
                                <button
                                    onClick={() => toggleModal(!isModalOpen)}
                                    className={authProfileStyles.start_btn}
                                >
                                    {t('auth.profileMobile.btn')}
                                </button>
                            </div>
                            <div className={authProfileStyles.contact}>
                                <p className={authProfileStyles.contact_p}>{t('auth.profileMobile.text')}</p>
                                <a href="mailto:rentbox.acc@gmail.com"
                                   className={authProfileStyles.contact_a}>{t('auth.profileMobile.contact')}</a>
                            </div>
                            <AuthModal isAuthModalOpen={isModalOpen} toggleAuthModal={toggleModal} />
                        </div>
                )
            }
        </>
    );
};

export default AuthProfileDropdownMobile;