import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import geolocationOperations from '../../../features/geolocation/geolocationOperations';
import geolocationSelectors from '../../../features/geolocation/geolocationSelectors';
import {useTranslation} from 'react-i18next';
import authSlice from '../../../features/auth/authSlice';
import userSelectors from '../../../features/user/userSelectors';
import {ResponseRefreshUserSuccessActionPayloadType} from '../../../models/generalTypes';
import authSelectors from '../../../features/auth/authSelectors';
import notificationSelectors from '../../../features/notification/notificationSelectors';
import notificationOperations from '../../../features/notification/notificationOperations';
import useWindowSize from '../../../hooks/useWindowSize';
import userOperations from '../../../features/user/userOperations';
import userSlice from '../../../features/user/userSlice';

import WindowSize from '../../../enums/WindowSize';
import PhotoSize from '../../Acceptances/enums/PhotoSize';

import unread from '../../../assets/icons/notification/unread.svg';
import mobileIcon from '../../../assets/icons/profile/profile-mobile-icon.svg';
import stub from '../../../assets/icons/userProfile/stub.svg';

import authProfileDropDownStyles from './AuthProfileDropdown.module.scss';

const key = 'filter_checked';

const AuthProfileDropdown = () => {

    const getCity = useSelector(geolocationSelectors.getCity);

    const fetchedCities = useSelector(
        geolocationSelectors.getFetchedCities,
    ) as Array<string>;

    const isAuth = useSelector(authSelectors.getIsAuthenticated);

    const unreadCountNotifications = useSelector(notificationSelectors.getUnreadCountNotifications);

    const acceptancesCount = useSelector(userSelectors.getUserAcceptancesCount).length;

    const user = useSelector(
        userSelectors.getUserProfile,
    ) as ResponseRefreshUserSuccessActionPayloadType;

    const dispatch = useDispatch();

    useEffect(() => {
        fetchedCities !== undefined &&
        dispatch(geolocationOperations.getCityMarkers(getCity.code));
    }, [getCity]);

    const history = useHistory();

    useEffect(() => {
        dispatch(geolocationOperations.getCities());
        isAuth && dispatch(userOperations.getUserProfile());
        isAuth && dispatch(userOperations.getAcceptancesCountToUserOffer());
        isAuth && dispatch(notificationOperations.getUnreadCountNotifications());
    }, []);

    const ref = React.useRef<HTMLDivElement>(null);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        isAuth && isMenuOpen && dispatch(userOperations.getAcceptancesCountToUserOffer());
        isAuth && isMenuOpen && dispatch(notificationOperations.getUnreadCountNotifications());
    }, [isMenuOpen]);

    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', checkIfClickedOutside);

        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isMenuOpen]);

    const submit = () => {
        dispatch(authSlice.actions.setAuthTokenNull(''));
        dispatch(userSlice.actions.clearUserProfile(''));
        history.replace('/');
    };

    const {t} = useTranslation();

    const {isScreenResolutionMobile} = useWindowSize();

    const getProfileIcon = () => {
        if (user.photos.length === 0 && !isScreenResolutionMobile) {
            return (
                <img src={stub} alt="stub" />
            );
        } else if (user.photos.length !== 0 && !isScreenResolutionMobile) {
            return (
                user.photos.filter(photo => photo.photoSize === PhotoSize.Large)
                    .reverse()
                    .map(photo => (
                        <div key={photo.id} className={history.location.pathname === '/' ?
                            authProfileDropDownStyles.circle :
                            authProfileDropDownStyles.circle_grey}>
                            <img
                                className={authProfileDropDownStyles.photo}
                                alt="hata"
                                src={photo.photoLocation}
                            />
                        </div>
                    ))[0]
            );
        } else return (
            <img src={mobileIcon} alt="mobileIcon" />
        );
    };

    const isUnread = () => {
        return unreadCountNotifications > 0 || acceptancesCount > 0;
    };

    return (
        <div>
            <div ref={ref}>
                {user &&
                  <div
                    className={authProfileDropDownStyles.selector__cities}
                    onClick={() => setIsMenuOpen(oldState => !oldState)}
                  >
                    <div className={authProfileDropDownStyles.icon}>
                        {isUnread() &&
                            <img src={unread} alt="circleCheck" className={authProfileDropDownStyles.unread_right} />}
                      {getProfileIcon()}
                  </div>
                    </div>}

                {isMenuOpen ? (
                    <form className={`${history.location.pathname === '/' ?
                        authProfileDropDownStyles.dropdown_container__cities :
                        authProfileDropDownStyles.dropdown_container__cities_other}`}
                    >
                        <div className={authProfileDropDownStyles.city_group_flex}>
                            <Link className={authProfileDropDownStyles.item} to="/account/profile"
                                  onClick={() => setIsMenuOpen(false)}>
                                {t('auth.profile.account')}
                            </Link>

                            {user && user.hasOffers &&
                              <Link
                                className={`${authProfileDropDownStyles.item} ${authProfileDropDownStyles.flex_total}`}
                                to="/account/offers"
                                onClick={() => {
                                    localStorage.setItem(key, String(true));
                                    setIsMenuOpen(false);
                                }}>
                                  {t('auth.profile.offers')}
                                  {acceptancesCount > 0 &&
                                    <img src={unread} alt="circleCheck" className={authProfileDropDownStyles.unread} />
                                  }
                              </Link>}

                            {user && user.hasAcc &&
                              <Link className={authProfileDropDownStyles.item} to="/account/acceptances"
                                    onClick={() => setIsMenuOpen(false)}>
                                  {t('auth.profile.acceptances')}
                              </Link>}

                            <Link
                                className={`${authProfileDropDownStyles.item} ${authProfileDropDownStyles.flex_total}`}
                                to="/account/notifications"
                                onClick={() => setIsMenuOpen(false)}>
                                {t('auth.profile.notifications')}
                                {unreadCountNotifications > 0 &&
                                  <img src={unread} alt="circleCheck" className={authProfileDropDownStyles.unread} />
                                }
                            </Link>
                            <div onClick={submit} className={authProfileDropDownStyles.item}>
                                {t('auth.profile.logout')}
                            </div>
                            <div>
                                {/* <NotificationDropDown authenticated={authenticated} /> */}
                            </div>
                        </div>
                    </form>
                ) : null}
            </div>
        </div>
    );
};

export default AuthProfileDropdown;