import React, {useCallback, useEffect, useRef} from 'react';
import Modal from '../../../../common/Modal';
import {useTranslation} from 'react-i18next';
import useWindowSize from '../../../../hooks/useWindowSize';
import copy from 'clipboard-copy';
import {toast} from 'react-toastify';
import {AcceptanceCreatorType, OfferCreatorType} from '../../../../models/generalTypes';

import PhotoSize from '../../enums/PhotoSize';

import closeButton from '../../images/closeButton.svg';
import copyImage from '../../../../assets/icons/copy.svg';

import './AcceptancesContactModal.scss';
import modalStyles from '../../../../styles/modalStyles.module.scss';

interface Props {
    isModalOpen: boolean;
    toggleModal: (arg0: boolean) => any | void;
    creator: Array<AcceptanceCreatorType> | Array<OfferCreatorType>;
    clear: any;
}

const AcceptancesContactModal: React.FC<Props> = ({isModalOpen, toggleModal, creator, clear}) => {

    const {t} = useTranslation();

    const dateFormatChange = (str: string | Date) => {
        const date = new Date(str as string);
        const year = date.getFullYear();

        return ` ${year} `;
    };

    const getLastPhotoAdded = useCallback(
        (item: OfferCreatorType | AcceptanceCreatorType) => {
            return item.photos.filter(photo => photo.photoSize === PhotoSize.Large)[item.photos.filter(photo => photo.photoSize === PhotoSize.Large).length - 1].photoLocation;
        },
        [creator],
    );

    useEffect(() => {
        return () => {
            clear();
        };
    }, []);

    const {isScreenResolutionMobile} = useWindowSize();

    const telephoneRef = useRef<HTMLParagraphElement | null>(null);

    const handleCopyTelephone = () => {
        if (telephoneRef.current) {
            const textToCopy = telephoneRef.current.textContent;
            if (textToCopy) {
                copy(textToCopy)
                    .then(() => {
                        toast.success(t('acceptancesContactModal.toast'));
                    })
                    .catch((error) => {
                        console.error(t('acceptancesContactModal.errorToast'), error);
                    });
            }
        }
    };

    const emailRef = useRef<HTMLParagraphElement | null>(null);

    const handleCopyEmail = () => {
        if (emailRef.current) {
            const textToCopy = emailRef.current.textContent;
            if (textToCopy) {
                copy(textToCopy)
                    .then(() => {
                        toast.success(t('acceptancesContactModal.toast'));
                    })
                    .catch((error) => {
                        console.error(t('acceptancesContactModal.errorToast'), error);
                    });
            }
        }
    };

    return (
        <>
            {creator.map(item => (
                <Modal
                    width={isScreenResolutionMobile ? '100%' : '570px'}
                    height={isScreenResolutionMobile ? '375px' : 'auto'}
                    isOpen={isModalOpen}
                    toggle={toggleModal}
                    key={item.userId}
                >
                    <h4 className={modalStyles.header}>
                        {t('acceptancesModal.btn.item.confirmed')}
                    </h4>
                    <div
                        role="button"
                        tabIndex={1}
                        onKeyDown={() => toggleModal(!isModalOpen)}
                        onClick={async () => {
                            await toggleModal(!isModalOpen);
                            clear();
                        }}
                        className={modalStyles.close}
                    >
                        <p>
                            <img id="animated" src={closeButton} alt="close" />
                        </p>
                    </div>

                    <div className={modalStyles.line}></div>

                    <div className="flex-contact">
                        {item.photos.length !== 0 ?
                            <img
                                className="img"
                                src={getLastPhotoAdded(item)}
                                alt="avatar" />
                            :
                            <img
                                className="img"
                                src="https://klike.net/uploads/posts/2019-03/1551511801_1.jpg"
                                alt="avatar" />}
                        <div className="flex-contact__column-large">
                            <p className="name">{item.name}</p>
                            <p className="date_contact">{t('acceptancesContactModal.date') + dateFormatChange(item.createdOn) + t('acceptancesContactModal.year')}</p>
                            {item.phone !== '' && item.phone !== null &&
                              <div className="copy-flex">
                                <p className="telephone__large" ref={telephoneRef}>{item.phone}</p>
                                <img id="animated" src={copyImage} onClick={handleCopyTelephone} alt="copy"
                                     className="copy"></img>
                              </div>}
                            {item.email !== '' && item.email !== null &&
                              <div className="copy-flex">
                                <p className="email__large" ref={emailRef}>{item.email}</p>
                                <img id="animated" src={copyImage} onClick={handleCopyEmail} alt="copy"
                                     className="copy"></img>
                              </div>}
                            {item.description !== 'null' &&
                              <div>
                                <span>{t('acceptancesContactModal.description')}</span>
                                <p className="creator_description">
                                    {item.description}
                                </p>
                              </div>}
                        </div>
                    </div>
                </Modal>
            ))}
        </>
    );
};

export default AcceptancesContactModal;