import {AppStateType} from '../../redux/store';
import {userOffersAdapter} from './userSlice';

const getUserProfile = (state: AppStateType) => state.user.userProfile;

const getUserOffers = userOffersAdapter.getSelectors<AppStateType>((state: AppStateType) =>
    state.user.userOffers,
);

const getUserAcceptancesCount = (state: AppStateType) => state.user.acceptancesCountToUserOffer;

const getUserOffer = (state: AppStateType) => state.user.myOffer;

const enterCodeModal = (state: AppStateType) => state.user.isCodeModalOpen;

const getPhone = (state: AppStateType) => state.user.phone;

const getTotalUserOffers = (state: AppStateType) => state.user.totalUserOffers;

const getTotalPagesUserOffers = (state: AppStateType) => state.user.totalPagesUserOffers;

const getEmail = (state: AppStateType) => state.user.email;

const isUploadedPhoto = (state: AppStateType) => state.user.userPhoto;

const updatedUserProfile = (state: AppStateType) => state.user.updatedUserProfile;

const userSelectors = {
    updatedUserProfile,
    isUploadedPhoto,
    enterCodeModal,
    getTotalPagesUserOffers,
    getUserProfile,
    getTotalUserOffers,
    getUserOffers,
    getUserAcceptancesCount,
    getUserOffer,
    getPhone,
    getEmail,
};
export default userSelectors;