import React, {
    Dispatch,
    SetStateAction,
} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import OtpInput from 'react-otp-input';
import authSelectors from '../../../features/auth/authSelectors';
import authOperations from '../../../features/auth/authOperations';
import authSlice from '../../../features/auth/authSlice';
import {useTranslation} from "react-i18next";
import LoaderColor from "../../../common/Button/enum/LoaderColor";
import LoaderButtonFormService from "../../../common/Button/loaderButtonFormService/LoaderButtonFormService";
import {useForm} from "react-hook-form";
import useWindowSize from "../../../hooks/useWindowSize";
import WindowSize from "../../../enums/WindowSize";

import './EnterCodeModal.scss';

interface EnterCodeModalProps {
    toggleModal: Dispatch<SetStateAction<boolean>>;
    isModalOpen?: boolean;
    phone?: string;
}

const EnterCodeModal: React.FC<EnterCodeModalProps> = () => {

    const enterEmailCodeModal = useSelector(authSelectors.enterEmailCodeModal);

    const [code, setCode] = React.useState('');

    const [hasErrored, setHasErrored] = React.useState(false);

    const phone = useSelector(authSelectors.getPhone);

    const email = useSelector(authSelectors.getEmail);

    const {handleSubmit} = useForm();

    const dispatch = useDispatch();

    const {t} = useTranslation();

    const isUpdatePassword = useSelector(authSelectors.getIsUpdatePassword);

    const password = useSelector(authSelectors.getPassword);

    const submitCodeHandler = async () => {
        if (code.length === 6 && !isUpdatePassword) {
            setHasErrored(false);
            await dispatch(authOperations.logInSecurityCode({phone, code}));
            dispatch(authOperations.logIn({login: phone, password}))
        } else if (code.length === 6 && isUpdatePassword) {
            await dispatch(authOperations.updatePassword({email, password, securityCode: code}));
            dispatch(authSlice.actions.toggleCodeModal(false));
        } else setHasErrored(true);
    };

    const {isScreenResolutionMobile} = useWindowSize();

    const inputStyle = {
        margin: '0 auto',
        width: '45px',
        height: '55px',
        border: '1px solid #BDBDBD',
        boxSizing: 'border-box',
        borderRadius: '6px',
        marginRight: isScreenResolutionMobile ? '10px' : '12px',
        marginTop: '10px',
    };

    const focusStyle = {
        border: '2px solid #34a790',
        outline: 'none'
    }

    const errorStyle = {
        border: '2px solid #eb5757'
    }

    return (
        <div className="auth_form">
            <form>
                <div className="form-group">
                    <h4 className="auth-tittle">{t('codeModal.Hello')}</h4>
                    <p className="auth-subtitle auth-label__code ">
                        {!enterEmailCodeModal ? t('codeModal.SMS') : t('codeModal.email')}
                    </p>

                    <p className="auth-label auth-labe__green">{t('codeModal.EnterCode')}*</p>
                    <OtpInput
                        focusStyle={focusStyle}
                        hasErrored={hasErrored}
                        errorStyle={hasErrored && errorStyle}
                        isInputNum
                        inputStyle={inputStyle}
                        numInputs={6}
                        value={code}
                        onChange={(inputCode: any) => {
                            setHasErrored(false);
                            if (inputCode.length === 6) {
                                setHasErrored(false);
                            }
                            setCode(inputCode);
                        }}
                        shouldAutoFocus
                        separator={<span> </span>}
                    />
                    {hasErrored ? (
                        <p className="error-message">{t('codeModal.SixChar')}</p>
                    ) : (
                        <></>
                    )}
                </div>
            </form>
            <div className="form-group1">
                <LoaderButtonFormService buttonContent={t('codeModal.submit')}
                                         buttonClassName={code.length === 6 ? "enter_code_modal_submit" : "enter_code_modal_submit_not_active"}
                                         submit={handleSubmit(submitCodeHandler)}
                                         color={LoaderColor.White}
                                         isActive={code.length === 6}
                />
                <div
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                        dispatch(authSlice.actions.toggleCodeModal(false));
                    }}
                >
                    <p className="under-tittle">{t('codeModal.BACK')}</p>
                </div>
            </div>
        </div>
    );
};

export default EnterCodeModal;