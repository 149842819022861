import axios from "axios";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {baseURL} from "../base";

axios.defaults.baseURL = 'http://api.rentbox.com.ua';

const getBalance = createAsyncThunk(
    'payment/balance',
    async () => {
        try {
            const {data} = await axios.get(`${baseURL}/payment-profile`);
            return data;
        } catch (error) {
            console.log('error', error);
        }
    }
);

const getPrices = createAsyncThunk(
    'payment/prices',
    async () => {
        try {
            const {data} = await axios.get(`${baseURL}/payments/prices`);
            return data;
        } catch (error) {
            console.log('error', error);
        }
    }
);

const operation = {
    getBalance,
    getPrices
};

export default operation;