import React, {Dispatch, SetStateAction} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import OtpInput from 'react-otp-input';
import {useTranslation} from 'react-i18next';
import LoaderButtonFormService from '../../../common/Button/loaderButtonFormService/LoaderButtonFormService';
import userOperations from '../../../features/user/userOperations';
import userSelectors from '../../../features/user/userSelectors';
import userSlice from '../../../features/user/userSlice';

import LoaderColor from '../../../common/Button/enum/LoaderColor';

import './CodeForChanger.scss';

interface CodeForChangerProps {
    label: string;
    toggleModal: Dispatch<SetStateAction<boolean>>;
    isModalOpen?: boolean;
    email?: string;
}

const CodeForChanger: React.FC<CodeForChangerProps> = ({label, toggleModal, isModalOpen}) => {

    const {t} = useTranslation();

    const [code, setCode] = React.useState('');

    const [hasErrored, setHasErrored] = React.useState(false);

    const phone = useSelector(userSelectors.getPhone);

    const email = useSelector(userSelectors.getEmail);

    const dispatch = useDispatch();

    const correctCodeLength = 6;

    const submitCodeHandler = async () => {
        if (code.length === correctCodeLength) {
            setHasErrored(false);

            {
                label === 'email'
                    ?
                    await dispatch(userOperations.codeToChangeEmail({email, code})) &&
                    toggleModal(!isModalOpen)
                    :
                    await dispatch(userOperations.codeToChangePhone({phone, code})) &&
                    toggleModal(!isModalOpen);
            }
        } else {
            setHasErrored(true);
        }

        dispatch(userSlice.actions.toggleCodeModal(false));
    };

    const inputStyle = {
        margin: '0 auto',
        width: '45px',
        height: '55px',
        border: '1px solid #BDBDBD',
        boxSizing: 'border-box',
        borderRadius: '6px',
        marginRight: '12px',
        marginTop: '10px',
    };

    const focusStyle = {
        border: '2px solid #34a790',
        outline: 'none',
    };

    const errorStyle = {
        border: '2px solid #eb5757',
    };

    const emailLabel = 'email';

    return (
        <div className="auth_form">
            <form>
                <div className="form-group">
                    <h1 className="auth-tittle"> {t('codeModal.Hello')}!</h1>
                    {label === emailLabel ?
                        <p className="auth-subtitle auth-label__code">
                            {t('codeModal.email')}
                        </p>
                        :
                        <p className="auth-subtitle auth-label__code">
                            {t('codeModal.SMS')}
                        </p>}

                    <div>
                        <p className="auth-label auth-labe__green ">
                            {t('codeModal.EnterCode')}*
                        </p>
                        <OtpInput
                            hasErrored={hasErrored}
                            isInputNum
                            errorStyle={hasErrored && errorStyle}
                            inputStyle={inputStyle}
                            focusStyle={focusStyle}
                            numInputs={6}
                            value={code}
                            onChange={(inputCode: any) => {
                                setHasErrored(false);
                                if (inputCode.length === 6) {
                                    setHasErrored(false);
                                }
                                setCode(inputCode);
                            }}
                            shouldAutoFocus
                            separator={<span> </span>}
                        />
                        {hasErrored ? (
                            <p className="error-message">
                                {t('codeModal.SixChar')}
                            </p>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </form>
            <div className="form-group1">
                <LoaderButtonFormService
                    buttonContent={t('codeModal.Submit')}
                    buttonClassName={'code_modal_btn'}
                    submit={submitCodeHandler}
                    color={LoaderColor.White}
                />

                <div
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                        dispatch(userSlice.actions.toggleCodeModal(false));
                    }}
                >
                    <p className="under-tittle"> {t('codeModal.BACK')}</p>
                </div>
            </div>
        </div>
    );
};

export default CodeForChanger;