const useDateFormatting = () => {

    return (isoString: string | Date, format: string) => {

        const date = isoString instanceof Date ? isoString : new Date(isoString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const dt = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        return format
            .replace('YYYY', String(year))
            .replace('MM', String(month))
            .replace('DD', String(dt))
            .replace('HH', String(hours))
            .replace('mm', String(minutes));
    };
};

export default useDateFormatting;