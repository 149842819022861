import {createSlice} from "@reduxjs/toolkit";
import paymentOperation from "./paymentOperation";

interface InitialState {
    balance: any,
    prices: any
}

const initialState: InitialState = {
    balance: null,
    prices: []
}

const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(
            paymentOperation.getBalance.fulfilled,
            (state, action) => {
                state.balance = action.payload.coinsBalance;
            }
        );
        builder.addCase(paymentOperation.getPrices.fulfilled,
            (state, action) => {
                state.prices = action.payload;
            }
        )
    }
})

export default paymentSlice;